/*
 * Elements / Header / Slider
 */

.elements-header-slider

	.swiper-container

		.swiper-slide
			background-size: cover
			background-position: center
			display: flex
			flex-direction: column
			justify-content: center
