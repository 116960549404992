/*
 * Elements / Header / Image
 */

.elements-header-image

	background: transparent url("../img/header-xs.jpg") no-repeat center center
	background-size: cover
	height: 30vh

	@for $i from 2 through length($grid-breakpoints)
		$key: nth(map_keys($grid-breakpoints), $i)
		@media screen and (min-width: #{map_get($grid-breakpoints, $key)})
			background-image: url("../img/header-#{$key}.jpg")

	.overlay
		background-color: rgba(theme-color('primary'), .5)
		display: flex
		align-items: center
		height: 100%
		color: white
