/*
 * Elements / Content / Team
 */

.elements-content-team

	.card

		display: inline-block
		position: relative
		margin-bottom: 30px

		img

			width: 100%
			height: auto

	// Grid
	.team-grid

		justify-content: center


	// Slider
	.team-slider

		text-align: center

		.swiper-wrapper

			justify-content: center

			.card

				margin: 10px
