/*!
 * Navbar
 */

.navbar-brand img
	//approximate navbar height
	max-height: 70px

	+media-breakpoint-only('xs')
		max-width: 200px

.navbar-light

	.navbar-toggler
		border-color: rgba(theme-color('primary'), .3)
