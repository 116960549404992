/*
 * Elements / Form / Sign Up
 */

.elements-form-signup

	flex-basis: 400px
	margin: 0 15px

	@media screen and (min-width: #{map_get($grid-breakpoints, sm)})
		margin: 70px 30px

	@media screen and (min-width: #{map_get($grid-breakpoints, md)})
		margin: 100px 50px

