/*
 * Elements / Header / Video
 */

.elements-header-video

	position: relative
	overflow: hidden

	img
		width: 100%

	video
		width: 100%
		position: absolute
		top: 50%
		margin-top: calc(-100vw * 9/16 / 2) // - [ video width ] * [ inverse of video ratio ] / 2

	.video-overlay
		position: absolute
		top: 0
		width: 100%
		height: 100%
		display: flex
		align-items: center
		z-index: 9
		background-color: rgba(white, .7)
