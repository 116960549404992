/*!
 * Alert
 */


.browserupgrade

	background-color: theme-color('danger')
	color: mix(theme-color('danger'), white, 15%)
	padding: .5em
	text-align: center

	a

		color: white
		font-weight: bold
		text-decoration: none

		&:hover
			color: white
			text-decoration: underline
