/*!
 * Button
 */

//Overriding BS default with golf
a.btn, button.btn
	white-space: normal

.btn
	cursor: pointer
	//All buttons are uppercase...
	text-transform: uppercase

.navbar .btn, footer .btn
	//...except this one
	text-transform: none

	//also, border throws off spacing
	border: none


.navbar .dropdown-toggle-split
	&:hover, &:active
		color: rgba(255, 255, 255, 0.75)

//Blue buttons don't need a border
//a.btn-outline-info, a.btn-info, button.btn-outline-info, button.btn-info
	border: 0

//a.btn-outline-info, button.btn-outline-info
	//Make blue outline buttons transparent blue instead of transparent
	background-color: transparentize(theme-color('info'), 0.4)
	color: #ffffff

	//Change hover background color of blue outline buttons
	&:hover
		background-color: theme-color('info')

	//Aw darn, we still need this style in some places
	&.btn-transparent
		background-color: transparent
		border: $btn-border-width solid theme-color('info')
		color: theme-color('info')
		&:hover
			background-color: darken(theme-color('info'), 7.5%)
			color: #ffffff

