/*!
 * Type
 */

//==================================================
//                     IMPORT
//==================================================

@import url('https://fonts.googleapis.com/css?family=Kalam:400,700|Montserrat:300,400,500,600,700,800,900|Raleway:300i')

//==================================================
//                   VARIABLES
//==================================================

// Headings
$headings-font-family: 'Montserrat', 'Helvetica Neue', 'Helvetica', sans-serif
$headings-font-weight: 700

// Body
$font-family-base: 'Montserrat', 'Helvetica Neue', 'Helvetica', sans-serif

//==================================================
//                     STYLES
//==================================================

p
	//font-size: 1em
	text-align: justify
	hyphens: auto

	@media screen and (min-width: #{map_get($grid-breakpoints, sm)})
		//font-size: 1.05em

		&.columns
			column-gap: 40px
			column-rule: 1px solid $gray-300
		&.two-columns
			column-count: 2
		&.three-columns
			column-count: 3
		&.four-columns
			column-count: 4
		&.five-columns
			column-count: 5
		&.six-columns
			column-count: 6

	//@media screen and (min-width: #{map_get($grid-breakpoints, md)})
		font-size: 1.1em

	//@media screen and (min-width: #{map_get($grid-breakpoints, lg)})
		font-size: 1.15em

	//@media screen and (min-width: #{map_get($grid-breakpoints, xl)})
		font-size: 1.2em

