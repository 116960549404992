
@import '../../components/variables'
@import '../../components/mixins'

.section-multi-promo
	position: relative

	.swiper-slide
		background-repeat: no-repeat
		background-position: center
		background-size: cover

	+media-breakpoint-up('md')

		//Fix height when there are less than 3 items
		.multi-promo-pagination__list
			min-height: 301px

		.swiper-wrapper
			position: absolute
			top: 0
			bottom: 0
			left: 0
			right: 0
			z-index: -1

	.elements-promotion-testimonial
		color: #000000

	+media-breakpoint-down('md')
		.container
			max-width: none

.promo-bullet
	color: #ffffff
	display: block
	position: relative
	cursor: pointer
	&::before
		content: ''
		position: absolute
		width: 5px
		height: 100%
		top: 0
		left: -#{$grid-gutter-width / 2}

.promo-bullet-active
	color: theme-color('primary')
	&::before
		background-color: theme-color('primary')

.px-md-4 .promo-bullet::before
	+media-breakpoint-up('md')
		left: -#{map-get($spacers, 4)}

//testimonial needs clickable button
.multi-promo-pagination
	pointer-events: none
	.col
		pointer-events: all

