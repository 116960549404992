/*
 * Elements / Promotion / Header
 */

.elements-promotion-header
	.overlay
		position: relative
		display: flex
		align-items: center
		z-index: 2
		color: white

	//If nav menu is inside header, make sure video is below it
	.elements-navigation-menu
		//dropdown menus are 1
		z-index: 1001

	// Image
	&.tpl-1

		background: transparent no-repeat center center
		background-size: cover
		min-height: 30vh

		//@for $i from 2 through length($grid-breakpoints)
		//	$key: nth(map_keys($grid-breakpoints), $i)
		//	@media screen and (min-width: #{map_get($grid-breakpoints, $key)})
		//		$index: attr(data-index)
		//		&:before
		//			//content: attr(data-index)
		//			content:  url('../img/#{$index}-#{$key}/header-#{$key}.jpg')
		//		//background-image: url("../img/header-#{$key}.jpg")


	// Video
	&.tpl-2

		position: relative
		overflow: hidden

		video
			width: 100%
			position: absolute
			top: 50%
			margin-top: calc(-100vw * 9/16 / 2) // - [ video width ] * [ inverse of video ratio ] / 2

			// Video is currently in the way of the top nav
			z-index: 0


	// Slider
	&.tpl-3

		.swiper-slide
			background-size: cover
			background-position: center
			display: flex
			flex-direction: column
			justify-content: center
			height: 30vh

	// Map
	&.tpl-4

		height: 30vh
		position: relative

		#map
			height: 100%

	//Got extra stuff in the header? Add .d-flex and .flex-column to it.
	&.d-flex.flex-column
		.overlay
			flex-grow: 1

