/*!
 * Forms
 */

form .custom-radio
	.custom-control-label::before
		background-color: #ffffff
	.custom-control-input:active ~ .custom-control-label::before
		background-color: #ffffff
	.custom-control-input:focus ~ .custom-control-label::before
		//box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem transparentize(theme-color('info'), 0.25)
		box-shadow: none
	.custom-control-input:checked ~ .custom-control-label
		&::before
			background-color: #ffffff
		&::after
			background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%2300bbce'/%3E%3C/svg%3E")
			background-size: 75% 75%

.custom-select
	font-weight: 500

label, .label
	font-weight: bold
	font-size: $font-size-base

label .error
	color: theme-color('danger')
