/*
 * Elements / Header / Map
 */

.elements-header-map

	height: 30vh
	position: relative

	.overlay
		background-color: rgba(white, .5)
		height: 100%
		width: 100%
		position: absolute
		display: flex
		align-items: center
		z-index: 9

	#map
		height: 100%