
$package__tagline-font-size: 2.3rem

.package
	//Limits height of package icon
	.flex-xl-nowrap img
		height: calc(#{$line-height-base + ($package__tagline-font-size * 2)})

	//Fixes alignment of buttons
	+media-breakpoint-only('md')
		& > .col-12 + .col-12
			padding-left: calc(25% + #{$grid-gutter-width / 2})


.package__name
	margin-bottom: 0.25rem
	white-space: nowrap

.package__tagline
	font-size: $package__tagline-font-size
	line-height: 1
	strong
		font-weight: 900
	//Force breakpoint
	+media-breakpoint-up('md')
		max-width: 11rem

//Make package blend into background
.package--translucent
	//Icons are black black, so they need less opacity than...
	img
		opacity: 0.4
	//The font, which is a lighter shade of black in Bootstrap by default
	.package__name, .package__tagline
		opacity: 0.5
		-webkit-text-stroke: 1px rgba(0,0,0,0.8)
		color: $body-color
