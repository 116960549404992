/*
 * Elements / Promotion / Banner
 */

.elements-promotion-banner

	background-color: rgba(theme-color('primary'), .05)
	padding: 40px

	position: relative

	.cta-box

		margin-top: 30px
		padding-top: 30px
		border-top: 1px solid rgba(theme-color('primary'), .1)

		small
			font-size: 1em
			font-weight: bold
			text-transform: uppercase
			letter-spacing: .1em

		strong

			margin-top: 0
			font-size: calc(12vw - 13px)
			white-space: nowrap
			display: block
			margin-bottom: .2em

			@media screen and (min-width: #{map_get($grid-breakpoints, sm)})
				font-size: 3.25em


	+media-breakpoint-up(md)

		+fake-row()
		align-items: center

		.pitch-box

			//+fake-col(7)

			p
				padding-right: 30px

				&:last-of-type
					margin-bottom: 0

		.cta-box

			//+fake-col(5)
			margin-top: 0
			padding-top: 0
			border-top: none

			small
				font-size: .8em

			strong
				font-size: 1.8em
				margin-bottom: .5em


	@media screen and (min-width: #{map_get($grid-breakpoints, lg)})

		.pitch-box

			+fake-col(8)


		.cta-box

			+fake-col(4)

			strong
				font-size: 1.98em


	@media screen and (min-width: #{map_get($grid-breakpoints, xl)})

		.cta-box

			strong
				font-size: 2.42em
