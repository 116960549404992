/*!
 * Variables
 */

// Custom
$blue:    #00819a
$indigo:  #6610f2
$purple:  #6f42c1
$pink:    #e83e8c
$red:     #ff1b00
$orange:  #fd7e14
$yellow:  #fec200
$green:   #34e16d
$teal:    #20c997
$cyan:    #00bbce
$maroon:  #420e00

$white:     #fff
$gray-100:  #f8f9fa
$gray-200:  #e9ecef
$gray-300:  #dee2e6
$gray-400:  #ced4da
$gray-500:  #adb5bd
$gray-600:  #868e96
$gray-700:  #495057
$gray-800:  #343a40
$gray-900:  #212529
$black:     #000

$colors: ("blue": $blue, "indigo": $indigo, "purple": $purple, "pink": $pink, "red": $red, "orange": $orange, "yellow": $yellow, "green": $green, "teal": $teal, "cyan": $cyan, "white": $white, "gray": $gray-600, "gray-dark": $gray-800)

$theme-colors: ("primary": $red, "secondary": $yellow, "success": $green, "info": $cyan, "warning": $yellow, "danger": $red, "light": $gray-100, "dark": $gray-800)


// Typography

$raleway: 'Raleway', sans-serif
$kalam: 'Kalam', cursive


// Bootstrap
@import "../../../node_modules/bootstrap/scss/variables"

$border-radius:                     0
$border-radius-sm:                  0
$border-radius-lg:                  0

$btn-border-radius:                 0
$btn-border-radius-sm:              0
$btn-border-radius-lg:              0

$btn-border-width:                  2px
$btn-padding-y:                     4px

$enable-rounded:					false

$caret-down:                        str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 2'%3E%3Cpath fill='#{$custom-select-indicator-color}' d='M 2,2 0,0 h 4 z'/%3E%3C/svg%3E"), "#", "%23")
$carets-up-down:                    str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='#{$custom-select-indicator-color}' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E"), "#", "%23")
$caret-down-primary:                str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 2'%3E%3Cpath fill='#{theme-color('primary')}' d='M 2,2 0,0 h 4 z'/%3E%3C/svg%3E"), "#", "%23")
$caret-down-white:                  str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 2'%3E%3Cpath fill='#ffffff' d='M 2,2 0,0 h 4 z'/%3E%3C/svg%3E"), "#", "%23")
$custom-select-indicator:           $caret-down

$btn-font-weight:                   bold

$navbar-dark-color:                 #ffffff
$navbar-light-color:                theme-color('primary')
$navbar-light-toggler-border-color: theme-color('primary')
$navbar-dark-active-color:          theme-color('info')

//Increase default button padding
$input-btn-padding-y:               $input-btn-padding-y-lg
$input-btn-padding-x:               $input-btn-padding-x-lg

$link-hover-decoration:             none

$modal-inner-padding:               1rem map_get($spacers, 5) map_get($spacers, 5)
$modal-header-padding:              map_get($spacers, 5) map_get($spacers, 5) 1rem
$modal-header-border-width:         0

$dropdown-min-width:                100%
