/*
 * Elements / Promotion / Features
 */

.elements-promotion-features

	ul
		li:before
			+icon-fa($fa-var-check)
			margin-right: .5em

	.cta-box
		strong
			font-size: 2em
			white-space: nowrap
			display: block

