/*!
 * Utilities
 */

// Bootstrap
@import ../../../node_modules/bootstrap/scss/utilities

// Custom

//used with function showDev
.drive-dev
	display: none

//Makes classes like .link-primary and .link-info, which fit with default Bootstrap theme
@each $color in map-keys($theme-colors)
	.link-#{$color}
		&:link, &:visited
			color: theme-color($color)
		&:hover, &:active
			color: darken(theme-color($color), 15%)
	.btn-link.link-#{$color}
		color: theme-color($color)
		&:hover
			color: darken(theme-color($color), 15%)
