/*
 * Elements / Navigation / Language
 */

.elements-navigation-language.tpl-1, .elements-navigation-language.tpl-2

	white-space: nowrap

	> a

		&.active
			color: inherit
			pointer-events: none
			opacity: .3

		+ a

			margin-left: .2em
			padding-left: .5em
			border-left: 1px solid $gray-300


	@media screen and (min-width: #{map_get($grid-breakpoints, sm)})


	@media screen and (min-width: #{map_get($grid-breakpoints, md)})


	@media screen and (min-width: #{map_get($grid-breakpoints, lg)})


	@media screen and (min-width: #{map_get($grid-breakpoints, xl)})


//Custom for this project only
.elements-navigation-language.tpl-navbar
	.nav-link

		//don't mess with line seperator
		line-height: 1
		//line seperator
		&:not(:first-child)
			border-left: 1px solid #ffffff

		//selected language
		&.active
			font-weight: bold




