/*
 * Elements / Promotion / Testimonial
 */

.elements-promotion-testimonial

	text-align: center

	img
		width: 150px
		border-radius: 50%
		margin: 2rem auto

	blockquote

		//padding: 0 3rem
		+clearfix()
		&:before, &:after
			display: none

		//Using absolutele-position quotes? Add some space for the top one
		//padding-top: $spacer * 4

		p
			display: inline-block
			font-style: italic
			max-width: 600px
			position: relative
			font-family: $font-family-base
			text-align: center
			//font-size: 1.2em

			@media screen and (min-width: #{map_get($grid-breakpoints, md)})
				//&:before, &:after
					position: absolute
					font-size: 4rem !important
					color: rgba($gray-200, .5)
					z-index: -1
				//&:before
					+icon-fa($fa-var-quote-left)
					left: -3rem
					top: -3rem
				//&:after
					+icon-fa($fa-var-quote-right)
					right: -3rem
					bottom: -3rem

		footer

			small
				display: block

			&[class^="stars"]:after
				color: $yellow

			&.stars:after
				display: block
				letter-spacing: .2em
				margin: .5em 0

			&.star-1:after
				+icon-fa($fa-var-star $fa-var-star-o $fa-var-star-o $fa-var-star-o $fa-var-star-o)

			&.star-2:after
				+icon-fa($fa-var-star $fa-var-star $fa-var-star-o $fa-var-star-o $fa-var-star-o)

			&.star-3:after
				+icon-fa($fa-var-star $fa-var-star $fa-var-star $fa-var-star-o $fa-var-star-o)

			&.star-4:after
				+icon-fa($fa-var-star $fa-var-star $fa-var-star $fa-var-star $fa-var-star-o)

			&.star-5:after
				+icon-fa($fa-var-star $fa-var-star $fa-var-star $fa-var-star $fa-var-star)
