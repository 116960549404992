@charset "UTF-8";
/* Main (used through the whole project) */
/*!
 * Functions
 */
/*!
 * Variables
 */
/* Mixins */
/* Animations ($type = bounce, etc.) */
@import url("https://fonts.googleapis.com/css?family=Kalam:400,700|Montserrat:300,400,500,600,700,800,900|Raleway:300i");
@keyframes bounce { 0% { transform: scale(0); }
  90% { transform: scale(1.3); }
  100% { transform: scale(1); } }
/* Icons (Font-Awesome, etc.) */
/* Grid */
/*!
 * Print
 */
/* Reboot */
* { -webkit-font-smoothing: antialiased; }
*::selection { background-color: #d9d9d9; }
*:focus { outline: none !important; }

html, body { height: 100%; width: 100%; }

body > section { margin: 40px 0; }
@media screen and (min-width: 576px) { body > section { margin: 50px 0; } }
@media screen and (min-width: 768px) { body > section { margin: 70px 0; } }
@media screen and (min-width: 992px) { body > section { margin: 90px 0; } }
@media screen and (min-width: 1200px) { body > section { margin: 110px 0; } }

blockquote:before { content: open-quote; }

blockquote:after { content: close-quote; }

blockquote, q { quotes: "“" "”" "‘" "’"; }

html:lang(fr) blockquote, html:lang(fr) q { quotes: "« " " »" "‘" "’"; }

html:lang(es) blockquote, html:lang(es) q, html:lang(pt) blockquote, html:lang(pt) q, html:lang(no) blockquote, html:lang(no) q { quotes: "«" "»" "‘" "’"; }

html:lang(de) blockquote, html:lang(de) q, html:lang(da) blockquote, html:lang(da) q, html:lang(bg) blockquote, html:lang(bg) q, html:lang(hr) blockquote, html:lang(hr) q { quotes: "»" "«" "‘" "’"; }

html:lang(ru) blockquote, html:lang(ru) q, html:lang(uk) blockquote, html:lang(uk) q, html:lang(be) blockquote, html:lang(be) q { quotes: "«" "»" "„" "”"; }

html:lang(pl) blockquote, html:lang(pl) q, html:lang(hu) blockquote, html:lang(hu) q { quotes: "„" "”" "‘" "’"; }

html:lang(fi) blockquote, html:lang(fi) q, html:lang(sv) blockquote, html:lang(sv) q, html:lang(be) blockquote, html:lang(be) q { quotes: "”" "”" "’" "’"; }

html:lang(it) blockquote, html:lang(it) q { quotes: "»" "«" "“" "”"; }

/*!
 * Type
 */
p { text-align: justify; hyphens: auto; }
@media screen and (min-width: 576px) { p.columns { column-gap: 40px; column-rule: 1px solid #dee2e6; }
  p.two-columns { column-count: 2; }
  p.three-columns { column-count: 3; }
  p.four-columns { column-count: 4; }
  p.five-columns { column-count: 5; }
  p.six-columns { column-count: 6; } }

/*!
 * Images
 */
figure { position: relative; }
figure img { max-width: 100%; }

/*!
 * Code
 */
/*!
 * Table
 */
/*!
 * Forms
 */
form .custom-radio .custom-control-label::before { background-color: #ffffff; }
form .custom-radio .custom-control-input:active ~ .custom-control-label::before { background-color: #ffffff; }
form .custom-radio .custom-control-input:focus ~ .custom-control-label::before { box-shadow: none; }
form .custom-radio .custom-control-input:checked ~ .custom-control-label::before { background-color: #ffffff; }
form .custom-radio .custom-control-input:checked ~ .custom-control-label::after { background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%2300bbce'/%3E%3C/svg%3E"); background-size: 75% 75%; }

.custom-select { font-weight: 500; }

label, .label { font-weight: bold; font-size: 1rem; }

label .error { color: #ff1b00; }

/*!
 * Button
 */
a.btn, button.btn { white-space: normal; }

.btn { cursor: pointer; text-transform: uppercase; }

.navbar .btn, footer .btn { text-transform: none; border: none; }

.navbar .dropdown-toggle-split:hover, .navbar .dropdown-toggle-split:active { color: rgba(255, 255, 255, 0.75); }

/*!
 * Transitions
 */
/*!
 * Dropdown
 */
/*!
 * Button Group
 */
/*!
 * Input Groups
 */
/*!
 * Custom Forms
 */
/*!
 * Nav
 */
/*!
 * Navbar
 */
.navbar-brand img { max-height: 70px; }
@media (max-width: 575.98px) { .navbar-brand img { max-width: 200px; } }

.navbar-light .navbar-toggler { border-color: rgba(255, 27, 0, 0.3); }

/*!
 * Card
 */
/*!
 * Alert
 */
/*!
 * Pagination
 */
/*!
 * Badge
 */
/*!
 * Jumbotron
 */
/*!
 * Alert
 */
.browserupgrade { background-color: #ff1b00; color: #ffddd9; padding: 0.5em; text-align: center; }
.browserupgrade a { color: white; font-weight: bold; text-decoration: none; }
.browserupgrade a:hover { color: white; text-decoration: underline; }

/*!
 * Progress
 */
/*!
 * Media
 */
/*!
 * List Group
 */
ol li.list-group-item { list-style: decimal inside; display: list-item; }

/*!
 * Close
 */
/*!
 * Modal
 */
/*!
 * Tooltip
 */
/*!
 * Popover
 */
/*!
 * Carousel
 */
/*!
 * Bootstrap v4.0.0 (https://getbootstrap.com)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root { --blue: #00819a; --indigo: #6610f2; --purple: #6f42c1; --pink: #e83e8c; --red: #ff1b00; --orange: #fd7e14; --yellow: #fec200; --green: #34e16d; --teal: #20c997; --cyan: #00bbce; --white: #fff; --gray: #868e96; --gray-dark: #343a40; --primary: #ff1b00; --secondary: #fec200; --success: #34e16d; --info: #00bbce; --warning: #fec200; --danger: #ff1b00; --light: #f8f9fa; --dark: #343a40; --breakpoint-xs: 0; --breakpoint-sm: 576px; --breakpoint-md: 768px; --breakpoint-lg: 992px; --breakpoint-xl: 1200px; --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*, *::before, *::after { box-sizing: border-box; }

html { font-family: sans-serif; line-height: 1.15; -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; -ms-overflow-style: scrollbar; -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

@-ms-viewport { width: device-width; }
article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section { display: block; }

body { margin: 0; font-family: "Montserrat", "Helvetica Neue", "Helvetica", sans-serif; font-size: 1rem; font-weight: 400; line-height: 1.5; color: #212529; text-align: left; background-color: #fff; }

[tabindex="-1"]:focus { outline: 0 !important; }

hr { box-sizing: content-box; height: 0; overflow: visible; }

h1, h2, h3, h4, h5, h6 { margin-top: 0; margin-bottom: 0.5rem; }

p { margin-top: 0; margin-bottom: 1rem; }

abbr[title], abbr[data-original-title] { text-decoration: underline; text-decoration: underline dotted; cursor: help; border-bottom: 0; }

address { margin-bottom: 1rem; font-style: normal; line-height: inherit; }

ol, ul, dl { margin-top: 0; margin-bottom: 1rem; }

ol ol, ul ul, ol ul, ul ol { margin-bottom: 0; }

dt { font-weight: 700; }

dd { margin-bottom: .5rem; margin-left: 0; }

blockquote { margin: 0 0 1rem; }

dfn { font-style: italic; }

b, strong { font-weight: bolder; }

small { font-size: 80%; }

sub, sup { position: relative; font-size: 75%; line-height: 0; vertical-align: baseline; }

sub { bottom: -.25em; }

sup { top: -.5em; }

a { color: #ff1b00; text-decoration: none; background-color: transparent; -webkit-text-decoration-skip: objects; }
a:hover { color: #b31300; text-decoration: none; }

a:not([href]):not([tabindex]) { color: inherit; text-decoration: none; }
a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus { color: inherit; text-decoration: none; }
a:not([href]):not([tabindex]):focus { outline: 0; }

pre, code, kbd, samp { font-family: monospace, monospace; font-size: 1em; }

pre { margin-top: 0; margin-bottom: 1rem; overflow: auto; -ms-overflow-style: scrollbar; }

figure { margin: 0 0 1rem; }

img { vertical-align: middle; border-style: none; }

svg:not(:root) { overflow: hidden; }

table { border-collapse: collapse; }

caption { padding-top: 0.75rem; padding-bottom: 0.75rem; color: #868e96; text-align: left; caption-side: bottom; }

th { text-align: inherit; }

label { display: inline-block; margin-bottom: .5rem; }

button { border-radius: 0; }

button:focus { outline: 1px dotted; outline: 5px auto -webkit-focus-ring-color; }

input, button, select, optgroup, textarea { margin: 0; font-family: inherit; font-size: inherit; line-height: inherit; }

button, input { overflow: visible; }

button, select { text-transform: none; }

button, html [type="button"], [type="reset"], [type="submit"] { -webkit-appearance: button; }

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner { padding: 0; border-style: none; }

input[type="radio"], input[type="checkbox"] { box-sizing: border-box; padding: 0; }

input[type="date"], input[type="time"], input[type="datetime-local"], input[type="month"] { -webkit-appearance: listbox; }

textarea { overflow: auto; resize: vertical; }

fieldset { min-width: 0; padding: 0; margin: 0; border: 0; }

legend { display: block; width: 100%; max-width: 100%; padding: 0; margin-bottom: .5rem; font-size: 1.5rem; line-height: inherit; color: inherit; white-space: normal; }

progress { vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button { height: auto; }

[type="search"] { outline-offset: -2px; -webkit-appearance: none; }

[type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration { -webkit-appearance: none; }

::-webkit-file-upload-button { font: inherit; -webkit-appearance: button; }

output { display: inline-block; }

summary { display: list-item; cursor: pointer; }

template { display: none; }

[hidden] { display: none !important; }

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 { margin-bottom: 0.5rem; font-family: "Montserrat", "Helvetica Neue", "Helvetica", sans-serif; font-weight: 700; line-height: 1.2; color: inherit; }

h1, .h1 { font-size: 2.5rem; }

h2, .h2 { font-size: 2rem; }

h3, .h3 { font-size: 1.75rem; }

h4, .h4 { font-size: 1.5rem; }

h5, .h5 { font-size: 1.25rem; }

h6, .h6 { font-size: 1rem; }

.lead { font-size: 1.25rem; font-weight: 300; }

.display-1 { font-size: 6rem; font-weight: 300; line-height: 1.2; }

.display-2 { font-size: 5.5rem; font-weight: 300; line-height: 1.2; }

.display-3 { font-size: 4.5rem; font-weight: 300; line-height: 1.2; }

.display-4 { font-size: 3.5rem; font-weight: 300; line-height: 1.2; }

hr { margin-top: 1rem; margin-bottom: 1rem; border: 0; border-top: 1px solid rgba(0, 0, 0, 0.1); }

small, .small { font-size: 80%; font-weight: 400; }

mark, .mark { padding: 0.2em; background-color: #fcf8e3; }

.list-unstyled { padding-left: 0; list-style: none; }

.list-inline { padding-left: 0; list-style: none; }

.list-inline-item { display: inline-block; }
.list-inline-item:not(:last-child) { margin-right: 0.5rem; }

.initialism { font-size: 90%; text-transform: uppercase; }

.blockquote { margin-bottom: 1rem; font-size: 1.25rem; }

.blockquote-footer { display: block; font-size: 80%; color: #868e96; }
.blockquote-footer::before { content: "\2014 \00A0"; }

.img-fluid { max-width: 100%; height: auto; }

.img-thumbnail { padding: 0.25rem; background-color: #fff; border: 1px solid #dee2e6; max-width: 100%; height: auto; }

.figure { display: inline-block; }

.figure-img { margin-bottom: 0.5rem; line-height: 1; }

.figure-caption { font-size: 90%; color: #868e96; }

code, kbd, pre, samp { font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

code { font-size: 87.5%; color: #e83e8c; word-break: break-word; }
a > code { color: inherit; }

kbd { padding: 0.2rem 0.4rem; font-size: 87.5%; color: #fff; background-color: #212529; }
kbd kbd { padding: 0; font-size: 100%; font-weight: 700; }

pre { display: block; font-size: 87.5%; color: #212529; }
pre code { font-size: inherit; color: inherit; word-break: normal; }

.pre-scrollable { max-height: 340px; overflow-y: scroll; }

.container { width: 100%; padding-right: 15px; padding-left: 15px; margin-right: auto; margin-left: auto; }
@media (min-width: 576px) { .container { max-width: 540px; } }
@media (min-width: 768px) { .container { max-width: 720px; } }
@media (min-width: 992px) { .container { max-width: 960px; } }
@media (min-width: 1200px) { .container { max-width: 1140px; } }

.container-fluid { width: 100%; padding-right: 15px; padding-left: 15px; margin-right: auto; margin-left: auto; }

.row { display: flex; flex-wrap: wrap; margin-right: -15px; margin-left: -15px; }

.no-gutters { margin-right: 0; margin-left: 0; }
.no-gutters > .col, .no-gutters > [class*="col-"] { padding-right: 0; padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto { position: relative; width: 100%; min-height: 1px; padding-right: 15px; padding-left: 15px; }

.col { flex-basis: 0; flex-grow: 1; max-width: 100%; }

.col-auto { flex: 0 0 auto; width: auto; max-width: none; }

.col-1 { flex: 0 0 8.3333333333%; max-width: 8.3333333333%; }

.col-2 { flex: 0 0 16.6666666667%; max-width: 16.6666666667%; }

.col-3 { flex: 0 0 25%; max-width: 25%; }

.col-4 { flex: 0 0 33.3333333333%; max-width: 33.3333333333%; }

.col-5 { flex: 0 0 41.6666666667%; max-width: 41.6666666667%; }

.col-6 { flex: 0 0 50%; max-width: 50%; }

.col-7 { flex: 0 0 58.3333333333%; max-width: 58.3333333333%; }

.col-8 { flex: 0 0 66.6666666667%; max-width: 66.6666666667%; }

.col-9 { flex: 0 0 75%; max-width: 75%; }

.col-10 { flex: 0 0 83.3333333333%; max-width: 83.3333333333%; }

.col-11 { flex: 0 0 91.6666666667%; max-width: 91.6666666667%; }

.col-12 { flex: 0 0 100%; max-width: 100%; }

.order-first { order: -1; }

.order-last { order: 13; }

.order-0 { order: 0; }

.order-1 { order: 1; }

.order-2 { order: 2; }

.order-3 { order: 3; }

.order-4 { order: 4; }

.order-5 { order: 5; }

.order-6 { order: 6; }

.order-7 { order: 7; }

.order-8 { order: 8; }

.order-9 { order: 9; }

.order-10 { order: 10; }

.order-11 { order: 11; }

.order-12 { order: 12; }

.offset-1 { margin-left: 8.3333333333%; }

.offset-2 { margin-left: 16.6666666667%; }

.offset-3 { margin-left: 25%; }

.offset-4 { margin-left: 33.3333333333%; }

.offset-5 { margin-left: 41.6666666667%; }

.offset-6 { margin-left: 50%; }

.offset-7 { margin-left: 58.3333333333%; }

.offset-8 { margin-left: 66.6666666667%; }

.offset-9 { margin-left: 75%; }

.offset-10 { margin-left: 83.3333333333%; }

.offset-11 { margin-left: 91.6666666667%; }

@media (min-width: 576px) { .col-sm { flex-basis: 0; flex-grow: 1; max-width: 100%; }
  .col-sm-auto { flex: 0 0 auto; width: auto; max-width: none; }
  .col-sm-1 { flex: 0 0 8.3333333333%; max-width: 8.3333333333%; }
  .col-sm-2 { flex: 0 0 16.6666666667%; max-width: 16.6666666667%; }
  .col-sm-3 { flex: 0 0 25%; max-width: 25%; }
  .col-sm-4 { flex: 0 0 33.3333333333%; max-width: 33.3333333333%; }
  .col-sm-5 { flex: 0 0 41.6666666667%; max-width: 41.6666666667%; }
  .col-sm-6 { flex: 0 0 50%; max-width: 50%; }
  .col-sm-7 { flex: 0 0 58.3333333333%; max-width: 58.3333333333%; }
  .col-sm-8 { flex: 0 0 66.6666666667%; max-width: 66.6666666667%; }
  .col-sm-9 { flex: 0 0 75%; max-width: 75%; }
  .col-sm-10 { flex: 0 0 83.3333333333%; max-width: 83.3333333333%; }
  .col-sm-11 { flex: 0 0 91.6666666667%; max-width: 91.6666666667%; }
  .col-sm-12 { flex: 0 0 100%; max-width: 100%; }
  .order-sm-first { order: -1; }
  .order-sm-last { order: 13; }
  .order-sm-0 { order: 0; }
  .order-sm-1 { order: 1; }
  .order-sm-2 { order: 2; }
  .order-sm-3 { order: 3; }
  .order-sm-4 { order: 4; }
  .order-sm-5 { order: 5; }
  .order-sm-6 { order: 6; }
  .order-sm-7 { order: 7; }
  .order-sm-8 { order: 8; }
  .order-sm-9 { order: 9; }
  .order-sm-10 { order: 10; }
  .order-sm-11 { order: 11; }
  .order-sm-12 { order: 12; }
  .offset-sm-0 { margin-left: 0; }
  .offset-sm-1 { margin-left: 8.3333333333%; }
  .offset-sm-2 { margin-left: 16.6666666667%; }
  .offset-sm-3 { margin-left: 25%; }
  .offset-sm-4 { margin-left: 33.3333333333%; }
  .offset-sm-5 { margin-left: 41.6666666667%; }
  .offset-sm-6 { margin-left: 50%; }
  .offset-sm-7 { margin-left: 58.3333333333%; }
  .offset-sm-8 { margin-left: 66.6666666667%; }
  .offset-sm-9 { margin-left: 75%; }
  .offset-sm-10 { margin-left: 83.3333333333%; }
  .offset-sm-11 { margin-left: 91.6666666667%; } }
@media (min-width: 768px) { .col-md { flex-basis: 0; flex-grow: 1; max-width: 100%; }
  .col-md-auto { flex: 0 0 auto; width: auto; max-width: none; }
  .col-md-1 { flex: 0 0 8.3333333333%; max-width: 8.3333333333%; }
  .col-md-2 { flex: 0 0 16.6666666667%; max-width: 16.6666666667%; }
  .col-md-3 { flex: 0 0 25%; max-width: 25%; }
  .col-md-4 { flex: 0 0 33.3333333333%; max-width: 33.3333333333%; }
  .col-md-5 { flex: 0 0 41.6666666667%; max-width: 41.6666666667%; }
  .col-md-6 { flex: 0 0 50%; max-width: 50%; }
  .col-md-7 { flex: 0 0 58.3333333333%; max-width: 58.3333333333%; }
  .col-md-8 { flex: 0 0 66.6666666667%; max-width: 66.6666666667%; }
  .col-md-9 { flex: 0 0 75%; max-width: 75%; }
  .col-md-10 { flex: 0 0 83.3333333333%; max-width: 83.3333333333%; }
  .col-md-11 { flex: 0 0 91.6666666667%; max-width: 91.6666666667%; }
  .col-md-12 { flex: 0 0 100%; max-width: 100%; }
  .order-md-first { order: -1; }
  .order-md-last { order: 13; }
  .order-md-0 { order: 0; }
  .order-md-1 { order: 1; }
  .order-md-2 { order: 2; }
  .order-md-3 { order: 3; }
  .order-md-4 { order: 4; }
  .order-md-5 { order: 5; }
  .order-md-6 { order: 6; }
  .order-md-7 { order: 7; }
  .order-md-8 { order: 8; }
  .order-md-9 { order: 9; }
  .order-md-10 { order: 10; }
  .order-md-11 { order: 11; }
  .order-md-12 { order: 12; }
  .offset-md-0 { margin-left: 0; }
  .offset-md-1 { margin-left: 8.3333333333%; }
  .offset-md-2 { margin-left: 16.6666666667%; }
  .offset-md-3 { margin-left: 25%; }
  .offset-md-4 { margin-left: 33.3333333333%; }
  .offset-md-5 { margin-left: 41.6666666667%; }
  .offset-md-6 { margin-left: 50%; }
  .offset-md-7 { margin-left: 58.3333333333%; }
  .offset-md-8 { margin-left: 66.6666666667%; }
  .offset-md-9 { margin-left: 75%; }
  .offset-md-10 { margin-left: 83.3333333333%; }
  .offset-md-11 { margin-left: 91.6666666667%; } }
@media (min-width: 992px) { .col-lg { flex-basis: 0; flex-grow: 1; max-width: 100%; }
  .col-lg-auto { flex: 0 0 auto; width: auto; max-width: none; }
  .col-lg-1 { flex: 0 0 8.3333333333%; max-width: 8.3333333333%; }
  .col-lg-2 { flex: 0 0 16.6666666667%; max-width: 16.6666666667%; }
  .col-lg-3 { flex: 0 0 25%; max-width: 25%; }
  .col-lg-4 { flex: 0 0 33.3333333333%; max-width: 33.3333333333%; }
  .col-lg-5 { flex: 0 0 41.6666666667%; max-width: 41.6666666667%; }
  .col-lg-6 { flex: 0 0 50%; max-width: 50%; }
  .col-lg-7 { flex: 0 0 58.3333333333%; max-width: 58.3333333333%; }
  .col-lg-8 { flex: 0 0 66.6666666667%; max-width: 66.6666666667%; }
  .col-lg-9 { flex: 0 0 75%; max-width: 75%; }
  .col-lg-10 { flex: 0 0 83.3333333333%; max-width: 83.3333333333%; }
  .col-lg-11 { flex: 0 0 91.6666666667%; max-width: 91.6666666667%; }
  .col-lg-12 { flex: 0 0 100%; max-width: 100%; }
  .order-lg-first { order: -1; }
  .order-lg-last { order: 13; }
  .order-lg-0 { order: 0; }
  .order-lg-1 { order: 1; }
  .order-lg-2 { order: 2; }
  .order-lg-3 { order: 3; }
  .order-lg-4 { order: 4; }
  .order-lg-5 { order: 5; }
  .order-lg-6 { order: 6; }
  .order-lg-7 { order: 7; }
  .order-lg-8 { order: 8; }
  .order-lg-9 { order: 9; }
  .order-lg-10 { order: 10; }
  .order-lg-11 { order: 11; }
  .order-lg-12 { order: 12; }
  .offset-lg-0 { margin-left: 0; }
  .offset-lg-1 { margin-left: 8.3333333333%; }
  .offset-lg-2 { margin-left: 16.6666666667%; }
  .offset-lg-3 { margin-left: 25%; }
  .offset-lg-4 { margin-left: 33.3333333333%; }
  .offset-lg-5 { margin-left: 41.6666666667%; }
  .offset-lg-6 { margin-left: 50%; }
  .offset-lg-7 { margin-left: 58.3333333333%; }
  .offset-lg-8 { margin-left: 66.6666666667%; }
  .offset-lg-9 { margin-left: 75%; }
  .offset-lg-10 { margin-left: 83.3333333333%; }
  .offset-lg-11 { margin-left: 91.6666666667%; } }
@media (min-width: 1200px) { .col-xl { flex-basis: 0; flex-grow: 1; max-width: 100%; }
  .col-xl-auto { flex: 0 0 auto; width: auto; max-width: none; }
  .col-xl-1 { flex: 0 0 8.3333333333%; max-width: 8.3333333333%; }
  .col-xl-2 { flex: 0 0 16.6666666667%; max-width: 16.6666666667%; }
  .col-xl-3 { flex: 0 0 25%; max-width: 25%; }
  .col-xl-4 { flex: 0 0 33.3333333333%; max-width: 33.3333333333%; }
  .col-xl-5 { flex: 0 0 41.6666666667%; max-width: 41.6666666667%; }
  .col-xl-6 { flex: 0 0 50%; max-width: 50%; }
  .col-xl-7 { flex: 0 0 58.3333333333%; max-width: 58.3333333333%; }
  .col-xl-8 { flex: 0 0 66.6666666667%; max-width: 66.6666666667%; }
  .col-xl-9 { flex: 0 0 75%; max-width: 75%; }
  .col-xl-10 { flex: 0 0 83.3333333333%; max-width: 83.3333333333%; }
  .col-xl-11 { flex: 0 0 91.6666666667%; max-width: 91.6666666667%; }
  .col-xl-12 { flex: 0 0 100%; max-width: 100%; }
  .order-xl-first { order: -1; }
  .order-xl-last { order: 13; }
  .order-xl-0 { order: 0; }
  .order-xl-1 { order: 1; }
  .order-xl-2 { order: 2; }
  .order-xl-3 { order: 3; }
  .order-xl-4 { order: 4; }
  .order-xl-5 { order: 5; }
  .order-xl-6 { order: 6; }
  .order-xl-7 { order: 7; }
  .order-xl-8 { order: 8; }
  .order-xl-9 { order: 9; }
  .order-xl-10 { order: 10; }
  .order-xl-11 { order: 11; }
  .order-xl-12 { order: 12; }
  .offset-xl-0 { margin-left: 0; }
  .offset-xl-1 { margin-left: 8.3333333333%; }
  .offset-xl-2 { margin-left: 16.6666666667%; }
  .offset-xl-3 { margin-left: 25%; }
  .offset-xl-4 { margin-left: 33.3333333333%; }
  .offset-xl-5 { margin-left: 41.6666666667%; }
  .offset-xl-6 { margin-left: 50%; }
  .offset-xl-7 { margin-left: 58.3333333333%; }
  .offset-xl-8 { margin-left: 66.6666666667%; }
  .offset-xl-9 { margin-left: 75%; }
  .offset-xl-10 { margin-left: 83.3333333333%; }
  .offset-xl-11 { margin-left: 91.6666666667%; } }
.table { width: 100%; max-width: 100%; margin-bottom: 1rem; background-color: transparent; }
.table th, .table td { padding: 0.75rem; vertical-align: top; border-top: 1px solid #dee2e6; }
.table thead th { vertical-align: bottom; border-bottom: 2px solid #dee2e6; }
.table tbody + tbody { border-top: 2px solid #dee2e6; }
.table .table { background-color: #fff; }

.table-sm th, .table-sm td { padding: 0.3rem; }

.table-bordered { border: 1px solid #dee2e6; }
.table-bordered th, .table-bordered td { border: 1px solid #dee2e6; }
.table-bordered thead th, .table-bordered thead td { border-bottom-width: 2px; }

.table-striped tbody tr:nth-of-type(odd) { background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover { background-color: rgba(0, 0, 0, 0.075); }

.table-primary, .table-primary > th, .table-primary > td { background-color: #ffbfb8; }

.table-hover .table-primary:hover { background-color: #ffa89f; }
.table-hover .table-primary:hover > td, .table-hover .table-primary:hover > th { background-color: #ffa89f; }

.table-secondary, .table-secondary > th, .table-secondary > td { background-color: #ffeeb8; }

.table-hover .table-secondary:hover { background-color: #ffe89f; }
.table-hover .table-secondary:hover > td, .table-hover .table-secondary:hover > th { background-color: #ffe89f; }

.table-success, .table-success > th, .table-success > td { background-color: #c6f7d6; }

.table-hover .table-success:hover { background-color: #b0f4c6; }
.table-hover .table-success:hover > td, .table-hover .table-success:hover > th { background-color: #b0f4c6; }

.table-info, .table-info > th, .table-info > td { background-color: #b8ecf1; }

.table-hover .table-info:hover { background-color: #a3e6ed; }
.table-hover .table-info:hover > td, .table-hover .table-info:hover > th { background-color: #a3e6ed; }

.table-warning, .table-warning > th, .table-warning > td { background-color: #ffeeb8; }

.table-hover .table-warning:hover { background-color: #ffe89f; }
.table-hover .table-warning:hover > td, .table-hover .table-warning:hover > th { background-color: #ffe89f; }

.table-danger, .table-danger > th, .table-danger > td { background-color: #ffbfb8; }

.table-hover .table-danger:hover { background-color: #ffa89f; }
.table-hover .table-danger:hover > td, .table-hover .table-danger:hover > th { background-color: #ffa89f; }

.table-light, .table-light > th, .table-light > td { background-color: #fdfdfe; }

.table-hover .table-light:hover { background-color: #ececf6; }
.table-hover .table-light:hover > td, .table-hover .table-light:hover > th { background-color: #ececf6; }

.table-dark, .table-dark > th, .table-dark > td { background-color: #c6c8ca; }

.table-hover .table-dark:hover { background-color: #b9bbbe; }
.table-hover .table-dark:hover > td, .table-hover .table-dark:hover > th { background-color: #b9bbbe; }

.table-active, .table-active > th, .table-active > td { background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover { background-color: rgba(0, 0, 0, 0.075); }
.table-hover .table-active:hover > td, .table-hover .table-active:hover > th { background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th { color: #fff; background-color: #212529; border-color: #32383e; }
.table .thead-light th { color: #495057; background-color: #e9ecef; border-color: #dee2e6; }

.table-dark { color: #fff; background-color: #212529; }
.table-dark th, .table-dark td, .table-dark thead th { border-color: #32383e; }
.table-dark.table-bordered { border: 0; }
.table-dark.table-striped tbody tr:nth-of-type(odd) { background-color: rgba(255, 255, 255, 0.05); }
.table-dark.table-hover tbody tr:hover { background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) { .table-responsive-sm { display: block; width: 100%; overflow-x: auto; -webkit-overflow-scrolling: touch; -ms-overflow-style: -ms-autohiding-scrollbar; }
  .table-responsive-sm > .table-bordered { border: 0; } }
@media (max-width: 767.98px) { .table-responsive-md { display: block; width: 100%; overflow-x: auto; -webkit-overflow-scrolling: touch; -ms-overflow-style: -ms-autohiding-scrollbar; }
  .table-responsive-md > .table-bordered { border: 0; } }
@media (max-width: 991.98px) { .table-responsive-lg { display: block; width: 100%; overflow-x: auto; -webkit-overflow-scrolling: touch; -ms-overflow-style: -ms-autohiding-scrollbar; }
  .table-responsive-lg > .table-bordered { border: 0; } }
@media (max-width: 1199.98px) { .table-responsive-xl { display: block; width: 100%; overflow-x: auto; -webkit-overflow-scrolling: touch; -ms-overflow-style: -ms-autohiding-scrollbar; }
  .table-responsive-xl > .table-bordered { border: 0; } }
.table-responsive { display: block; width: 100%; overflow-x: auto; -webkit-overflow-scrolling: touch; -ms-overflow-style: -ms-autohiding-scrollbar; }
.table-responsive > .table-bordered { border: 0; }

.form-control { display: block; width: 100%; padding: 0.375rem 0.75rem; font-size: 1rem; line-height: 1.5; color: #495057; background-color: #fff; background-clip: padding-box; border: 1px solid #ced4da; border-radius: 0; transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
.form-control::-ms-expand { background-color: transparent; border: 0; }
.form-control:focus { color: #495057; background-color: #fff; border-color: #ff8d80; outline: 0; box-shadow: 0 0 0 0.2rem rgba(255, 27, 0, 0.25); }
.form-control::placeholder { color: #868e96; opacity: 1; }
.form-control:disabled, .form-control[readonly] { background-color: #e9ecef; opacity: 1; }

select.form-control:not([size]):not([multiple]) { height: calc(2.25rem + 2px); }
select.form-control:focus::-ms-value { color: #495057; background-color: #fff; }

.form-control-file, .form-control-range { display: block; width: 100%; }

.col-form-label { padding-top: calc(0.375rem + 1px); padding-bottom: calc(0.375rem + 1px); margin-bottom: 0; font-size: inherit; line-height: 1.5; }

.col-form-label-lg { padding-top: calc(0.5rem + 1px); padding-bottom: calc(0.5rem + 1px); font-size: 1.25rem; line-height: 1.5; }

.col-form-label-sm { padding-top: calc(0.25rem + 1px); padding-bottom: calc(0.25rem + 1px); font-size: 0.875rem; line-height: 1.5; }

.form-control-plaintext { display: block; width: 100%; padding-top: 0.375rem; padding-bottom: 0.375rem; margin-bottom: 0; line-height: 1.5; background-color: transparent; border: solid transparent; border-width: 1px 0; }
.form-control-plaintext.form-control-sm, .input-group-sm > .form-control-plaintext.form-control, .input-group-sm > .input-group-prepend > .form-control-plaintext.input-group-text, .input-group-sm > .input-group-append > .form-control-plaintext.input-group-text, .input-group-sm > .input-group-prepend > .form-control-plaintext.btn, .input-group-sm > .input-group-append > .form-control-plaintext.btn, .form-control-plaintext.form-control-lg, .input-group-lg > .form-control-plaintext.form-control, .input-group-lg > .input-group-prepend > .form-control-plaintext.input-group-text, .input-group-lg > .input-group-append > .form-control-plaintext.input-group-text, .input-group-lg > .input-group-prepend > .form-control-plaintext.btn, .input-group-lg > .input-group-append > .form-control-plaintext.btn { padding-right: 0; padding-left: 0; }

.form-control-sm, .input-group-sm > .form-control, .input-group-sm > .input-group-prepend > .input-group-text, .input-group-sm > .input-group-append > .input-group-text, .input-group-sm > .input-group-prepend > .btn, .input-group-sm > .input-group-append > .btn { padding: 0.25rem 0.5rem; font-size: 0.875rem; line-height: 1.5; }

select.form-control-sm:not([size]):not([multiple]), .input-group-sm > select.form-control:not([size]):not([multiple]), .input-group-sm > .input-group-prepend > select.input-group-text:not([size]):not([multiple]), .input-group-sm > .input-group-append > select.input-group-text:not([size]):not([multiple]), .input-group-sm > .input-group-prepend > select.btn:not([size]):not([multiple]), .input-group-sm > .input-group-append > select.btn:not([size]):not([multiple]) { height: calc(1.8125rem + 2px); }

.form-control-lg, .input-group-lg > .form-control, .input-group-lg > .input-group-prepend > .input-group-text, .input-group-lg > .input-group-append > .input-group-text, .input-group-lg > .input-group-prepend > .btn, .input-group-lg > .input-group-append > .btn { padding: 0.5rem 1rem; font-size: 1.25rem; line-height: 1.5; }

select.form-control-lg:not([size]):not([multiple]), .input-group-lg > select.form-control:not([size]):not([multiple]), .input-group-lg > .input-group-prepend > select.input-group-text:not([size]):not([multiple]), .input-group-lg > .input-group-append > select.input-group-text:not([size]):not([multiple]), .input-group-lg > .input-group-prepend > select.btn:not([size]):not([multiple]), .input-group-lg > .input-group-append > select.btn:not([size]):not([multiple]) { height: calc(2.875rem + 2px); }

.form-group { margin-bottom: 1rem; }

.form-text { display: block; margin-top: 0.25rem; }

.form-row { display: flex; flex-wrap: wrap; margin-right: -5px; margin-left: -5px; }
.form-row > .col, .form-row > [class*="col-"] { padding-right: 5px; padding-left: 5px; }

.form-check { position: relative; display: block; padding-left: 1.25rem; }

.form-check-input { position: absolute; margin-top: 0.3rem; margin-left: -1.25rem; }
.form-check-input:disabled ~ .form-check-label { color: #868e96; }

.form-check-label { margin-bottom: 0; }

.form-check-inline { display: inline-flex; align-items: center; padding-left: 0; margin-right: 0.75rem; }
.form-check-inline .form-check-input { position: static; margin-top: 0; margin-right: 0.3125rem; margin-left: 0; }

.valid-feedback { display: none; width: 100%; margin-top: 0.25rem; font-size: 80%; color: #34e16d; }

.valid-tooltip { position: absolute; top: 100%; z-index: 5; display: none; max-width: 100%; padding: .5rem; margin-top: .1rem; font-size: .875rem; line-height: 1; color: #fff; background-color: rgba(52, 225, 109, 0.8); border-radius: .2rem; }

.was-validated .form-control:valid, .form-control.is-valid, .was-validated .custom-select:valid, .custom-select.is-valid { border-color: #34e16d; }
.was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus { border-color: #34e16d; box-shadow: 0 0 0 0.2rem rgba(52, 225, 109, 0.25); }
.was-validated .form-control:valid ~ .valid-feedback, .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback, .form-control.is-valid ~ .valid-tooltip, .was-validated .custom-select:valid ~ .valid-feedback, .was-validated .custom-select:valid ~ .valid-tooltip, .custom-select.is-valid ~ .valid-feedback, .custom-select.is-valid ~ .valid-tooltip { display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label { color: #34e16d; }
.was-validated .form-check-input:valid ~ .valid-feedback, .was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback, .form-check-input.is-valid ~ .valid-tooltip { display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label { color: #34e16d; }
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before { background-color: #a3f1bd; }
.was-validated .custom-control-input:valid ~ .valid-feedback, .was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback, .custom-control-input.is-valid ~ .valid-tooltip { display: block; }
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before { background-color: #60e88d; }
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before { box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(52, 225, 109, 0.25); }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label { border-color: #34e16d; }
.was-validated .custom-file-input:valid ~ .custom-file-label::before, .custom-file-input.is-valid ~ .custom-file-label::before { border-color: inherit; }
.was-validated .custom-file-input:valid ~ .valid-feedback, .was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback, .custom-file-input.is-valid ~ .valid-tooltip { display: block; }
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label { box-shadow: 0 0 0 0.2rem rgba(52, 225, 109, 0.25); }

.invalid-feedback { display: none; width: 100%; margin-top: 0.25rem; font-size: 80%; color: #ff1b00; }

.invalid-tooltip { position: absolute; top: 100%; z-index: 5; display: none; max-width: 100%; padding: .5rem; margin-top: .1rem; font-size: .875rem; line-height: 1; color: #fff; background-color: rgba(255, 27, 0, 0.8); border-radius: .2rem; }

.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated .custom-select:invalid, .custom-select.is-invalid { border-color: #ff1b00; }
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus { border-color: #ff1b00; box-shadow: 0 0 0 0.2rem rgba(255, 27, 0, 0.25); }
.was-validated .form-control:invalid ~ .invalid-feedback, .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback, .form-control.is-invalid ~ .invalid-tooltip, .was-validated .custom-select:invalid ~ .invalid-feedback, .was-validated .custom-select:invalid ~ .invalid-tooltip, .custom-select.is-invalid ~ .invalid-feedback, .custom-select.is-invalid ~ .invalid-tooltip { display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label { color: #ff1b00; }
.was-validated .form-check-input:invalid ~ .invalid-feedback, .was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback, .form-check-input.is-invalid ~ .invalid-tooltip { display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label { color: #ff1b00; }
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before { background-color: #ff8d80; }
.was-validated .custom-control-input:invalid ~ .invalid-feedback, .was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback, .custom-control-input.is-invalid ~ .invalid-tooltip { display: block; }
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before { background-color: #ff4933; }
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before { box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(255, 27, 0, 0.25); }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label { border-color: #ff1b00; }
.was-validated .custom-file-input:invalid ~ .custom-file-label::before, .custom-file-input.is-invalid ~ .custom-file-label::before { border-color: inherit; }
.was-validated .custom-file-input:invalid ~ .invalid-feedback, .was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback, .custom-file-input.is-invalid ~ .invalid-tooltip { display: block; }
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label { box-shadow: 0 0 0 0.2rem rgba(255, 27, 0, 0.25); }

.form-inline { display: flex; flex-flow: row wrap; align-items: center; }
.form-inline .form-check { width: 100%; }
@media (min-width: 576px) { .form-inline label { display: flex; align-items: center; justify-content: center; margin-bottom: 0; }
  .form-inline .form-group { display: flex; flex: 0 0 auto; flex-flow: row wrap; align-items: center; margin-bottom: 0; }
  .form-inline .form-control { display: inline-block; width: auto; vertical-align: middle; }
  .form-inline .form-control-plaintext { display: inline-block; }
  .form-inline .input-group { width: auto; }
  .form-inline .form-check { display: flex; align-items: center; justify-content: center; width: auto; padding-left: 0; }
  .form-inline .form-check-input { position: relative; margin-top: 0; margin-right: 0.25rem; margin-left: 0; }
  .form-inline .custom-control { align-items: center; justify-content: center; }
  .form-inline .custom-control-label { margin-bottom: 0; } }

.btn { display: inline-block; font-weight: bold; text-align: center; white-space: nowrap; vertical-align: middle; user-select: none; border: 2px solid transparent; padding: 4px 0.75rem; font-size: 1rem; line-height: 1.5; border-radius: 0; transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
.btn:hover, .btn:focus { text-decoration: none; }
.btn:focus, .btn.focus { outline: 0; box-shadow: 0 0 0 0.2rem rgba(255, 27, 0, 0.25); }
.btn.disabled, .btn:disabled { opacity: 0.65; }
.btn:not(:disabled):not(.disabled) { cursor: pointer; }
.btn:not(:disabled):not(.disabled):active, .btn:not(:disabled):not(.disabled).active { background-image: none; }

a.btn.disabled, fieldset:disabled a.btn { pointer-events: none; }

.btn-primary { color: #fff; background-color: #ff1b00; border-color: #ff1b00; }
.btn-primary:hover { color: #fff; background-color: #d91700; border-color: #cc1600; }
.btn-primary:focus, .btn-primary.focus { box-shadow: 0 0 0 0.2rem rgba(255, 27, 0, 0.5); }
.btn-primary.disabled, .btn-primary:disabled { color: #fff; background-color: #ff1b00; border-color: #ff1b00; }
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle { color: #fff; background-color: #cc1600; border-color: #bf1400; }
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus { box-shadow: 0 0 0 0.2rem rgba(255, 27, 0, 0.5); }

.btn-secondary { color: #212529; background-color: #fec200; border-color: #fec200; }
.btn-secondary:hover { color: #212529; background-color: #d8a500; border-color: #cb9b00; }
.btn-secondary:focus, .btn-secondary.focus { box-shadow: 0 0 0 0.2rem rgba(254, 194, 0, 0.5); }
.btn-secondary.disabled, .btn-secondary:disabled { color: #212529; background-color: #fec200; border-color: #fec200; }
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle { color: #212529; background-color: #cb9b00; border-color: #be9100; }
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus { box-shadow: 0 0 0 0.2rem rgba(254, 194, 0, 0.5); }

.btn-success { color: #212529; background-color: #34e16d; border-color: #34e16d; }
.btn-success:hover { color: #fff; background-color: #1fd059; border-color: #1dc554; }
.btn-success:focus, .btn-success.focus { box-shadow: 0 0 0 0.2rem rgba(52, 225, 109, 0.5); }
.btn-success.disabled, .btn-success:disabled { color: #212529; background-color: #34e16d; border-color: #34e16d; }
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle { color: #fff; background-color: #1dc554; border-color: #1bba50; }
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus { box-shadow: 0 0 0 0.2rem rgba(52, 225, 109, 0.5); }

.btn-info { color: #fff; background-color: #00bbce; border-color: #00bbce; }
.btn-info:hover { color: #fff; background-color: #0098a8; border-color: #008d9b; }
.btn-info:focus, .btn-info.focus { box-shadow: 0 0 0 0.2rem rgba(0, 187, 206, 0.5); }
.btn-info.disabled, .btn-info:disabled { color: #fff; background-color: #00bbce; border-color: #00bbce; }
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle { color: #fff; background-color: #008d9b; border-color: #00818e; }
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus { box-shadow: 0 0 0 0.2rem rgba(0, 187, 206, 0.5); }

.btn-warning { color: #212529; background-color: #fec200; border-color: #fec200; }
.btn-warning:hover { color: #212529; background-color: #d8a500; border-color: #cb9b00; }
.btn-warning:focus, .btn-warning.focus { box-shadow: 0 0 0 0.2rem rgba(254, 194, 0, 0.5); }
.btn-warning.disabled, .btn-warning:disabled { color: #212529; background-color: #fec200; border-color: #fec200; }
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle { color: #212529; background-color: #cb9b00; border-color: #be9100; }
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus { box-shadow: 0 0 0 0.2rem rgba(254, 194, 0, 0.5); }

.btn-danger { color: #fff; background-color: #ff1b00; border-color: #ff1b00; }
.btn-danger:hover { color: #fff; background-color: #d91700; border-color: #cc1600; }
.btn-danger:focus, .btn-danger.focus { box-shadow: 0 0 0 0.2rem rgba(255, 27, 0, 0.5); }
.btn-danger.disabled, .btn-danger:disabled { color: #fff; background-color: #ff1b00; border-color: #ff1b00; }
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle { color: #fff; background-color: #cc1600; border-color: #bf1400; }
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus { box-shadow: 0 0 0 0.2rem rgba(255, 27, 0, 0.5); }

.btn-light { color: #212529; background-color: #f8f9fa; border-color: #f8f9fa; }
.btn-light:hover { color: #212529; background-color: #e2e6ea; border-color: #dae0e5; }
.btn-light:focus, .btn-light.focus { box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
.btn-light.disabled, .btn-light:disabled { color: #212529; background-color: #f8f9fa; border-color: #f8f9fa; }
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle { color: #212529; background-color: #dae0e5; border-color: #d3d9df; }
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus { box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-dark { color: #fff; background-color: #343a40; border-color: #343a40; }
.btn-dark:hover { color: #fff; background-color: #23272b; border-color: #1d2124; }
.btn-dark:focus, .btn-dark.focus { box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
.btn-dark.disabled, .btn-dark:disabled { color: #fff; background-color: #343a40; border-color: #343a40; }
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle { color: #fff; background-color: #1d2124; border-color: #171a1d; }
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus { box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-outline-primary { color: #ff1b00; background-color: transparent; background-image: none; border-color: #ff1b00; }
.btn-outline-primary:hover { color: #fff; background-color: #ff1b00; border-color: #ff1b00; }
.btn-outline-primary:focus, .btn-outline-primary.focus { box-shadow: 0 0 0 0.2rem rgba(255, 27, 0, 0.5); }
.btn-outline-primary.disabled, .btn-outline-primary:disabled { color: #ff1b00; background-color: transparent; }
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle { color: #fff; background-color: #ff1b00; border-color: #ff1b00; }
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus { box-shadow: 0 0 0 0.2rem rgba(255, 27, 0, 0.5); }

.btn-outline-secondary { color: #fec200; background-color: transparent; background-image: none; border-color: #fec200; }
.btn-outline-secondary:hover { color: #212529; background-color: #fec200; border-color: #fec200; }
.btn-outline-secondary:focus, .btn-outline-secondary.focus { box-shadow: 0 0 0 0.2rem rgba(254, 194, 0, 0.5); }
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled { color: #fec200; background-color: transparent; }
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle { color: #212529; background-color: #fec200; border-color: #fec200; }
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus { box-shadow: 0 0 0 0.2rem rgba(254, 194, 0, 0.5); }

.btn-outline-success { color: #34e16d; background-color: transparent; background-image: none; border-color: #34e16d; }
.btn-outline-success:hover { color: #212529; background-color: #34e16d; border-color: #34e16d; }
.btn-outline-success:focus, .btn-outline-success.focus { box-shadow: 0 0 0 0.2rem rgba(52, 225, 109, 0.5); }
.btn-outline-success.disabled, .btn-outline-success:disabled { color: #34e16d; background-color: transparent; }
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle { color: #212529; background-color: #34e16d; border-color: #34e16d; }
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus { box-shadow: 0 0 0 0.2rem rgba(52, 225, 109, 0.5); }

.btn-outline-info { color: #00bbce; background-color: transparent; background-image: none; border-color: #00bbce; }
.btn-outline-info:hover { color: #fff; background-color: #00bbce; border-color: #00bbce; }
.btn-outline-info:focus, .btn-outline-info.focus { box-shadow: 0 0 0 0.2rem rgba(0, 187, 206, 0.5); }
.btn-outline-info.disabled, .btn-outline-info:disabled { color: #00bbce; background-color: transparent; }
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle { color: #fff; background-color: #00bbce; border-color: #00bbce; }
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus { box-shadow: 0 0 0 0.2rem rgba(0, 187, 206, 0.5); }

.btn-outline-warning { color: #fec200; background-color: transparent; background-image: none; border-color: #fec200; }
.btn-outline-warning:hover { color: #212529; background-color: #fec200; border-color: #fec200; }
.btn-outline-warning:focus, .btn-outline-warning.focus { box-shadow: 0 0 0 0.2rem rgba(254, 194, 0, 0.5); }
.btn-outline-warning.disabled, .btn-outline-warning:disabled { color: #fec200; background-color: transparent; }
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle { color: #212529; background-color: #fec200; border-color: #fec200; }
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus { box-shadow: 0 0 0 0.2rem rgba(254, 194, 0, 0.5); }

.btn-outline-danger { color: #ff1b00; background-color: transparent; background-image: none; border-color: #ff1b00; }
.btn-outline-danger:hover { color: #fff; background-color: #ff1b00; border-color: #ff1b00; }
.btn-outline-danger:focus, .btn-outline-danger.focus { box-shadow: 0 0 0 0.2rem rgba(255, 27, 0, 0.5); }
.btn-outline-danger.disabled, .btn-outline-danger:disabled { color: #ff1b00; background-color: transparent; }
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle { color: #fff; background-color: #ff1b00; border-color: #ff1b00; }
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus { box-shadow: 0 0 0 0.2rem rgba(255, 27, 0, 0.5); }

.btn-outline-light { color: #f8f9fa; background-color: transparent; background-image: none; border-color: #f8f9fa; }
.btn-outline-light:hover { color: #212529; background-color: #f8f9fa; border-color: #f8f9fa; }
.btn-outline-light:focus, .btn-outline-light.focus { box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
.btn-outline-light.disabled, .btn-outline-light:disabled { color: #f8f9fa; background-color: transparent; }
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle { color: #212529; background-color: #f8f9fa; border-color: #f8f9fa; }
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus { box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark { color: #343a40; background-color: transparent; background-image: none; border-color: #343a40; }
.btn-outline-dark:hover { color: #fff; background-color: #343a40; border-color: #343a40; }
.btn-outline-dark:focus, .btn-outline-dark.focus { box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
.btn-outline-dark.disabled, .btn-outline-dark:disabled { color: #343a40; background-color: transparent; }
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle { color: #fff; background-color: #343a40; border-color: #343a40; }
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus { box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-link { font-weight: 400; color: #ff1b00; background-color: transparent; }
.btn-link:hover { color: #b31300; text-decoration: none; background-color: transparent; border-color: transparent; }
.btn-link:focus, .btn-link.focus { text-decoration: none; border-color: transparent; box-shadow: none; }
.btn-link:disabled, .btn-link.disabled { color: #868e96; }

.btn-lg, .btn-group-lg > .btn { padding: 0.5rem 1rem; font-size: 1.25rem; line-height: 1.5; border-radius: 0; }

.btn-sm, .btn-group-sm > .btn { padding: 0.25rem 0.5rem; font-size: 0.875rem; line-height: 1.5; border-radius: 0; }

.btn-block { display: block; width: 100%; }
.btn-block + .btn-block { margin-top: 0.5rem; }

input[type="submit"].btn-block, input[type="reset"].btn-block, input[type="button"].btn-block { width: 100%; }

.fade { opacity: 0; transition: opacity 0.15s linear; }
.fade.show { opacity: 1; }

.collapse { display: none; }
.collapse.show { display: block; }

tr.collapse.show { display: table-row; }

tbody.collapse.show { display: table-row-group; }

.collapsing { position: relative; height: 0; overflow: hidden; transition: height 0.35s ease; }

.dropup, .dropdown { position: relative; }

.dropdown-toggle::after { display: inline-block; width: 0; height: 0; margin-left: 0.255em; vertical-align: 0.255em; content: ""; border-top: 0.3em solid; border-right: 0.3em solid transparent; border-bottom: 0; border-left: 0.3em solid transparent; }
.dropdown-toggle:empty::after { margin-left: 0; }

.dropdown-menu { position: absolute; top: 100%; left: 0; z-index: 1000; display: none; float: left; min-width: 100%; padding: 0.5rem 0; margin: 0.125rem 0 0; font-size: 1rem; color: #212529; text-align: left; list-style: none; background-color: #fff; background-clip: padding-box; border: 1px solid rgba(0, 0, 0, 0.15); }

.dropup .dropdown-menu { margin-top: 0; margin-bottom: 0.125rem; }
.dropup .dropdown-toggle::after { display: inline-block; width: 0; height: 0; margin-left: 0.255em; vertical-align: 0.255em; content: ""; border-top: 0; border-right: 0.3em solid transparent; border-bottom: 0.3em solid; border-left: 0.3em solid transparent; }
.dropup .dropdown-toggle:empty::after { margin-left: 0; }

.dropright .dropdown-menu { margin-top: 0; margin-left: 0.125rem; }
.dropright .dropdown-toggle::after { display: inline-block; width: 0; height: 0; margin-left: 0.255em; vertical-align: 0.255em; content: ""; border-top: 0.3em solid transparent; border-bottom: 0.3em solid transparent; border-left: 0.3em solid; }
.dropright .dropdown-toggle:empty::after { margin-left: 0; }
.dropright .dropdown-toggle::after { vertical-align: 0; }

.dropleft .dropdown-menu { margin-top: 0; margin-right: 0.125rem; }
.dropleft .dropdown-toggle::after { display: inline-block; width: 0; height: 0; margin-left: 0.255em; vertical-align: 0.255em; content: ""; }
.dropleft .dropdown-toggle::after { display: none; }
.dropleft .dropdown-toggle::before { display: inline-block; width: 0; height: 0; margin-right: 0.255em; vertical-align: 0.255em; content: ""; border-top: 0.3em solid transparent; border-right: 0.3em solid; border-bottom: 0.3em solid transparent; }
.dropleft .dropdown-toggle:empty::after { margin-left: 0; }
.dropleft .dropdown-toggle::before { vertical-align: 0; }

.dropdown-divider { height: 0; margin: 0.5rem 0; overflow: hidden; border-top: 1px solid #e9ecef; }

.dropdown-item { display: block; width: 100%; padding: 0.25rem 1.5rem; clear: both; font-weight: 400; color: #212529; text-align: inherit; white-space: nowrap; background-color: transparent; border: 0; }
.dropdown-item:hover, .dropdown-item:focus { color: #16181b; text-decoration: none; background-color: #f8f9fa; }
.dropdown-item.active, .dropdown-item:active { color: #fff; text-decoration: none; background-color: #ff1b00; }
.dropdown-item.disabled, .dropdown-item:disabled { color: #868e96; background-color: transparent; }

.dropdown-menu.show { display: block; }

.dropdown-header { display: block; padding: 0.5rem 1.5rem; margin-bottom: 0; font-size: 0.875rem; color: #868e96; white-space: nowrap; }

.btn-group, .btn-group-vertical { position: relative; display: inline-flex; vertical-align: middle; }
.btn-group > .btn, .btn-group-vertical > .btn { position: relative; flex: 0 1 auto; }
.btn-group > .btn:hover, .btn-group-vertical > .btn:hover { z-index: 1; }
.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active, .btn-group-vertical > .btn:focus, .btn-group-vertical > .btn:active, .btn-group-vertical > .btn.active { z-index: 1; }
.btn-group .btn + .btn, .btn-group .btn + .btn-group, .btn-group .btn-group + .btn, .btn-group .btn-group + .btn-group, .btn-group-vertical .btn + .btn, .btn-group-vertical .btn + .btn-group, .btn-group-vertical .btn-group + .btn, .btn-group-vertical .btn-group + .btn-group { margin-left: -2px; }

.btn-toolbar { display: flex; flex-wrap: wrap; justify-content: flex-start; }
.btn-toolbar .input-group { width: auto; }

.btn-group > .btn:first-child { margin-left: 0; }

.dropdown-toggle-split { padding-right: 0.5625rem; padding-left: 0.5625rem; }
.dropdown-toggle-split::after { margin-left: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split { padding-right: 0.375rem; padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split { padding-right: 0.75rem; padding-left: 0.75rem; }

.btn-group-vertical { flex-direction: column; align-items: flex-start; justify-content: center; }
.btn-group-vertical .btn, .btn-group-vertical .btn-group { width: 100%; }
.btn-group-vertical > .btn + .btn, .btn-group-vertical > .btn + .btn-group, .btn-group-vertical > .btn-group + .btn, .btn-group-vertical > .btn-group + .btn-group { margin-top: -2px; margin-left: 0; }

.btn-group-toggle > .btn, .btn-group-toggle > .btn-group > .btn { margin-bottom: 0; }
.btn-group-toggle > .btn input[type="radio"], .btn-group-toggle > .btn input[type="checkbox"], .btn-group-toggle > .btn-group > .btn input[type="radio"], .btn-group-toggle > .btn-group > .btn input[type="checkbox"] { position: absolute; clip: rect(0, 0, 0, 0); pointer-events: none; }

.input-group { position: relative; display: flex; flex-wrap: wrap; align-items: stretch; width: 100%; }
.input-group > .form-control, .input-group > .custom-select, .input-group > .custom-file { position: relative; flex: 1 1 auto; width: 1%; margin-bottom: 0; }
.input-group > .form-control:focus, .input-group > .custom-select:focus, .input-group > .custom-file:focus { z-index: 3; }
.input-group > .form-control + .form-control, .input-group > .form-control + .custom-select, .input-group > .form-control + .custom-file, .input-group > .custom-select + .form-control, .input-group > .custom-select + .custom-select, .input-group > .custom-select + .custom-file, .input-group > .custom-file + .form-control, .input-group > .custom-file + .custom-select, .input-group > .custom-file + .custom-file { margin-left: -1px; }
.input-group > .custom-file { display: flex; align-items: center; }

.input-group-prepend, .input-group-append { display: flex; }
.input-group-prepend .btn, .input-group-append .btn { position: relative; z-index: 2; }
.input-group-prepend .btn + .btn, .input-group-prepend .btn + .input-group-text, .input-group-prepend .input-group-text + .input-group-text, .input-group-prepend .input-group-text + .btn, .input-group-append .btn + .btn, .input-group-append .btn + .input-group-text, .input-group-append .input-group-text + .input-group-text, .input-group-append .input-group-text + .btn { margin-left: -1px; }

.input-group-prepend { margin-right: -1px; }

.input-group-append { margin-left: -1px; }

.input-group-text { display: flex; align-items: center; padding: 0.375rem 0.75rem; margin-bottom: 0; font-size: 1rem; font-weight: 400; line-height: 1.5; color: #495057; text-align: center; white-space: nowrap; background-color: #e9ecef; border: 1px solid #ced4da; }
.input-group-text input[type="radio"], .input-group-text input[type="checkbox"] { margin-top: 0; }

.custom-control { position: relative; display: block; min-height: 1.5rem; padding-left: 1.5rem; }

.custom-control-inline { display: inline-flex; margin-right: 1rem; }

.custom-control-input { position: absolute; z-index: -1; opacity: 0; }
.custom-control-input:checked ~ .custom-control-label::before { color: #fff; background-color: #ff1b00; }
.custom-control-input:focus ~ .custom-control-label::before { box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(255, 27, 0, 0.25); }
.custom-control-input:active ~ .custom-control-label::before { color: #fff; background-color: #ffbbb3; }
.custom-control-input:disabled ~ .custom-control-label { color: #868e96; }
.custom-control-input:disabled ~ .custom-control-label::before { background-color: #e9ecef; }

.custom-control-label { margin-bottom: 0; }
.custom-control-label::before { position: absolute; top: 0.25rem; left: 0; display: block; width: 1rem; height: 1rem; pointer-events: none; content: ""; user-select: none; background-color: #dee2e6; }
.custom-control-label::after { position: absolute; top: 0.25rem; left: 0; display: block; width: 1rem; height: 1rem; content: ""; background-repeat: no-repeat; background-position: center center; background-size: 50% 50%; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before { background-color: #ff1b00; }
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after { background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"); }
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before { background-color: #ff1b00; }
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after { background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E"); }
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before { background-color: rgba(255, 27, 0, 0.5); }
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before { background-color: rgba(255, 27, 0, 0.5); }

.custom-radio .custom-control-label::before { border-radius: 50%; }
.custom-radio .custom-control-input:checked ~ .custom-control-label::before { background-color: #ff1b00; }
.custom-radio .custom-control-input:checked ~ .custom-control-label::after { background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E"); }
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before { background-color: rgba(255, 27, 0, 0.5); }

.custom-select { display: inline-block; width: 100%; height: calc(2.25rem + 2px); padding: 0.375rem 1.75rem 0.375rem 0.75rem; line-height: 1.5; color: #495057; vertical-align: middle; background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 2'%3E%3Cpath fill='%23343a40' d='M 2,2 0,0 h 4 z'/%3E%3C/svg%3E") no-repeat right 0.75rem center; background-size: 8px 10px; border: 1px solid #ced4da; border-radius: 0; appearance: none; }
.custom-select:focus { border-color: #ff8d80; outline: 0; box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 5px rgba(255, 141, 128, 0.5); }
.custom-select:focus::-ms-value { color: #495057; background-color: #fff; }
.custom-select[multiple], .custom-select[size]:not([size="1"]) { height: auto; padding-right: 0.75rem; background-image: none; }
.custom-select:disabled { color: #868e96; background-color: #e9ecef; }
.custom-select::-ms-expand { opacity: 0; }

.custom-select-sm { height: calc(1.8125rem + 2px); padding-top: 0.375rem; padding-bottom: 0.375rem; font-size: 75%; }

.custom-select-lg { height: calc(2.875rem + 2px); padding-top: 0.375rem; padding-bottom: 0.375rem; font-size: 125%; }

.custom-file { position: relative; display: inline-block; width: 100%; height: calc(2.25rem + 2px); margin-bottom: 0; }

.custom-file-input { position: relative; z-index: 2; width: 100%; height: calc(2.25rem + 2px); margin: 0; opacity: 0; }
.custom-file-input:focus ~ .custom-file-control { border-color: #ff8d80; box-shadow: 0 0 0 0.2rem rgba(255, 27, 0, 0.25); }
.custom-file-input:focus ~ .custom-file-control::before { border-color: #ff8d80; }
.custom-file-input:lang(en) ~ .custom-file-label::after { content: "Browse"; }

.custom-file-label { position: absolute; top: 0; right: 0; left: 0; z-index: 1; height: calc(2.25rem + 2px); padding: 0.375rem 0.75rem; line-height: 1.5; color: #495057; background-color: #fff; border: 1px solid #ced4da; }
.custom-file-label::after { position: absolute; top: 0; right: 0; bottom: 0; z-index: 3; display: block; height: calc(calc(2.25rem + 2px) - 1px * 2); padding: 0.375rem 0.75rem; line-height: 1.5; color: #495057; content: "Browse"; background-color: #e9ecef; border-left: 1px solid #ced4da; }

.nav { display: flex; flex-wrap: wrap; padding-left: 0; margin-bottom: 0; list-style: none; }

.nav-link { display: block; padding: 0.5rem 1rem; }
.nav-link:hover, .nav-link:focus { text-decoration: none; }
.nav-link.disabled { color: #868e96; }

.nav-tabs { border-bottom: 1px solid #dee2e6; }
.nav-tabs .nav-item { margin-bottom: -1px; }
.nav-tabs .nav-link { border: 1px solid transparent; }
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus { border-color: #e9ecef #e9ecef #dee2e6; }
.nav-tabs .nav-link.disabled { color: #868e96; background-color: transparent; border-color: transparent; }
.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link { color: #495057; background-color: #fff; border-color: #dee2e6 #dee2e6 #fff; }
.nav-tabs .dropdown-menu { margin-top: -1px; }

.nav-pills .nav-link.active, .nav-pills .show > .nav-link { color: #fff; background-color: #ff1b00; }

.nav-fill .nav-item { flex: 1 1 auto; text-align: center; }

.nav-justified .nav-item { flex-basis: 0; flex-grow: 1; text-align: center; }

.tab-content > .tab-pane { display: none; }
.tab-content > .active { display: block; }

.navbar { position: relative; display: flex; flex-wrap: wrap; align-items: center; justify-content: space-between; padding: 0.5rem 1rem; }
.navbar > .container, .navbar > .container-fluid { display: flex; flex-wrap: wrap; align-items: center; justify-content: space-between; }

.navbar-brand { display: inline-block; padding-top: 0.3125rem; padding-bottom: 0.3125rem; margin-right: 1rem; font-size: 1.25rem; line-height: inherit; white-space: nowrap; }
.navbar-brand:hover, .navbar-brand:focus { text-decoration: none; }

.navbar-nav { display: flex; flex-direction: column; padding-left: 0; margin-bottom: 0; list-style: none; }
.navbar-nav .nav-link { padding-right: 0; padding-left: 0; }
.navbar-nav .dropdown-menu { position: static; float: none; }

.navbar-text { display: inline-block; padding-top: 0.5rem; padding-bottom: 0.5rem; }

.navbar-collapse { flex-basis: 100%; flex-grow: 1; align-items: center; }

.navbar-toggler { padding: 0.25rem 0.75rem; font-size: 1.25rem; line-height: 1; background-color: transparent; border: 1px solid transparent; }
.navbar-toggler:hover, .navbar-toggler:focus { text-decoration: none; }
.navbar-toggler:not(:disabled):not(.disabled) { cursor: pointer; }

.navbar-toggler-icon { display: inline-block; width: 1.5em; height: 1.5em; vertical-align: middle; content: ""; background: no-repeat center center; background-size: 100% 100%; }

@media (max-width: 575.98px) { .navbar-expand-sm > .container, .navbar-expand-sm > .container-fluid { padding-right: 0; padding-left: 0; } }
@media (min-width: 576px) { .navbar-expand-sm { flex-flow: row nowrap; justify-content: flex-start; }
  .navbar-expand-sm .navbar-nav { flex-direction: row; }
  .navbar-expand-sm .navbar-nav .dropdown-menu { position: absolute; }
  .navbar-expand-sm .navbar-nav .dropdown-menu-right { right: 0; left: auto; }
  .navbar-expand-sm .navbar-nav .nav-link { padding-right: 0.5rem; padding-left: 0.5rem; }
  .navbar-expand-sm > .container, .navbar-expand-sm > .container-fluid { flex-wrap: nowrap; }
  .navbar-expand-sm .navbar-collapse { display: flex !important; flex-basis: auto; }
  .navbar-expand-sm .navbar-toggler { display: none; }
  .navbar-expand-sm .dropup .dropdown-menu { top: auto; bottom: 100%; } }
@media (max-width: 767.98px) { .navbar-expand-md > .container, .navbar-expand-md > .container-fluid { padding-right: 0; padding-left: 0; } }
@media (min-width: 768px) { .navbar-expand-md { flex-flow: row nowrap; justify-content: flex-start; }
  .navbar-expand-md .navbar-nav { flex-direction: row; }
  .navbar-expand-md .navbar-nav .dropdown-menu { position: absolute; }
  .navbar-expand-md .navbar-nav .dropdown-menu-right { right: 0; left: auto; }
  .navbar-expand-md .navbar-nav .nav-link { padding-right: 0.5rem; padding-left: 0.5rem; }
  .navbar-expand-md > .container, .navbar-expand-md > .container-fluid { flex-wrap: nowrap; }
  .navbar-expand-md .navbar-collapse { display: flex !important; flex-basis: auto; }
  .navbar-expand-md .navbar-toggler { display: none; }
  .navbar-expand-md .dropup .dropdown-menu { top: auto; bottom: 100%; } }
@media (max-width: 991.98px) { .navbar-expand-lg > .container, .navbar-expand-lg > .container-fluid { padding-right: 0; padding-left: 0; } }
@media (min-width: 992px) { .navbar-expand-lg { flex-flow: row nowrap; justify-content: flex-start; }
  .navbar-expand-lg .navbar-nav { flex-direction: row; }
  .navbar-expand-lg .navbar-nav .dropdown-menu { position: absolute; }
  .navbar-expand-lg .navbar-nav .dropdown-menu-right { right: 0; left: auto; }
  .navbar-expand-lg .navbar-nav .nav-link { padding-right: 0.5rem; padding-left: 0.5rem; }
  .navbar-expand-lg > .container, .navbar-expand-lg > .container-fluid { flex-wrap: nowrap; }
  .navbar-expand-lg .navbar-collapse { display: flex !important; flex-basis: auto; }
  .navbar-expand-lg .navbar-toggler { display: none; }
  .navbar-expand-lg .dropup .dropdown-menu { top: auto; bottom: 100%; } }
@media (max-width: 1199.98px) { .navbar-expand-xl > .container, .navbar-expand-xl > .container-fluid { padding-right: 0; padding-left: 0; } }
@media (min-width: 1200px) { .navbar-expand-xl { flex-flow: row nowrap; justify-content: flex-start; }
  .navbar-expand-xl .navbar-nav { flex-direction: row; }
  .navbar-expand-xl .navbar-nav .dropdown-menu { position: absolute; }
  .navbar-expand-xl .navbar-nav .dropdown-menu-right { right: 0; left: auto; }
  .navbar-expand-xl .navbar-nav .nav-link { padding-right: 0.5rem; padding-left: 0.5rem; }
  .navbar-expand-xl > .container, .navbar-expand-xl > .container-fluid { flex-wrap: nowrap; }
  .navbar-expand-xl .navbar-collapse { display: flex !important; flex-basis: auto; }
  .navbar-expand-xl .navbar-toggler { display: none; }
  .navbar-expand-xl .dropup .dropdown-menu { top: auto; bottom: 100%; } }
.navbar-expand { flex-flow: row nowrap; justify-content: flex-start; }
.navbar-expand > .container, .navbar-expand > .container-fluid { padding-right: 0; padding-left: 0; }
.navbar-expand .navbar-nav { flex-direction: row; }
.navbar-expand .navbar-nav .dropdown-menu { position: absolute; }
.navbar-expand .navbar-nav .dropdown-menu-right { right: 0; left: auto; }
.navbar-expand .navbar-nav .nav-link { padding-right: 0.5rem; padding-left: 0.5rem; }
.navbar-expand > .container, .navbar-expand > .container-fluid { flex-wrap: nowrap; }
.navbar-expand .navbar-collapse { display: flex !important; flex-basis: auto; }
.navbar-expand .navbar-toggler { display: none; }
.navbar-expand .dropup .dropdown-menu { top: auto; bottom: 100%; }

.navbar-light .navbar-brand { color: rgba(0, 0, 0, 0.9); }
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus { color: rgba(0, 0, 0, 0.9); }
.navbar-light .navbar-nav .nav-link { color: #ff1b00; }
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus { color: rgba(0, 0, 0, 0.7); }
.navbar-light .navbar-nav .nav-link.disabled { color: rgba(0, 0, 0, 0.3); }
.navbar-light .navbar-nav .show > .nav-link, .navbar-light .navbar-nav .active > .nav-link, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .nav-link.active { color: rgba(0, 0, 0, 0.9); }
.navbar-light .navbar-toggler { color: #ff1b00; border-color: #ff1b00; }
.navbar-light .navbar-toggler-icon { background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }
.navbar-light .navbar-text { color: #ff1b00; }
.navbar-light .navbar-text a { color: rgba(0, 0, 0, 0.9); }
.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus { color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand { color: #00bbce; }
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus { color: #00bbce; }
.navbar-dark .navbar-nav .nav-link { color: #ffffff; }
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus { color: rgba(255, 255, 255, 0.75); }
.navbar-dark .navbar-nav .nav-link.disabled { color: rgba(255, 255, 255, 0.25); }
.navbar-dark .navbar-nav .show > .nav-link, .navbar-dark .navbar-nav .active > .nav-link, .navbar-dark .navbar-nav .nav-link.show, .navbar-dark .navbar-nav .nav-link.active { color: #00bbce; }
.navbar-dark .navbar-toggler { color: #ffffff; border-color: rgba(255, 255, 255, 0.1); }
.navbar-dark .navbar-toggler-icon { background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }
.navbar-dark .navbar-text { color: #ffffff; }
.navbar-dark .navbar-text a { color: #00bbce; }
.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus { color: #00bbce; }

.card { position: relative; display: flex; flex-direction: column; min-width: 0; word-wrap: break-word; background-color: #fff; background-clip: border-box; border: 1px solid rgba(0, 0, 0, 0.125); }
.card > hr { margin-right: 0; margin-left: 0; }

.card-body { flex: 1 1 auto; padding: 1.25rem; }

.card-title { margin-bottom: 0.75rem; }

.card-subtitle { margin-top: -0.375rem; margin-bottom: 0; }

.card-text:last-child { margin-bottom: 0; }

.card-link:hover { text-decoration: none; }
.card-link + .card-link { margin-left: 1.25rem; }

.card-header { padding: 0.75rem 1.25rem; margin-bottom: 0; background-color: rgba(0, 0, 0, 0.03); border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
.card-header + .list-group .list-group-item:first-child { border-top: 0; }

.card-footer { padding: 0.75rem 1.25rem; background-color: rgba(0, 0, 0, 0.03); border-top: 1px solid rgba(0, 0, 0, 0.125); }

.card-header-tabs { margin-right: -0.625rem; margin-bottom: -0.75rem; margin-left: -0.625rem; border-bottom: 0; }

.card-header-pills { margin-right: -0.625rem; margin-left: -0.625rem; }

.card-img-overlay { position: absolute; top: 0; right: 0; bottom: 0; left: 0; padding: 1.25rem; }

.card-img { width: 100%; }

.card-img-top { width: 100%; }

.card-img-bottom { width: 100%; }

.card-deck { display: flex; flex-direction: column; }
.card-deck .card { margin-bottom: 15px; }
@media (min-width: 576px) { .card-deck { flex-flow: row wrap; margin-right: -15px; margin-left: -15px; }
  .card-deck .card { display: flex; flex: 1 0 0%; flex-direction: column; margin-right: 15px; margin-bottom: 0; margin-left: 15px; } }

.card-group { display: flex; flex-direction: column; }
.card-group > .card { margin-bottom: 15px; }
@media (min-width: 576px) { .card-group { flex-flow: row wrap; }
  .card-group > .card { flex: 1 0 0%; margin-bottom: 0; }
  .card-group > .card + .card { margin-left: 0; border-left: 0; } }

.card-columns .card { margin-bottom: 0.75rem; }
@media (min-width: 576px) { .card-columns { column-count: 3; column-gap: 1.25rem; }
  .card-columns .card { display: inline-block; width: 100%; } }

.breadcrumb { display: flex; flex-wrap: wrap; padding: 0.75rem 1rem; margin-bottom: 1rem; list-style: none; background-color: #e9ecef; }

.breadcrumb-item + .breadcrumb-item::before { display: inline-block; padding-right: 0.5rem; padding-left: 0.5rem; color: #868e96; content: "/"; }
.breadcrumb-item + .breadcrumb-item:hover::before { text-decoration: underline; }
.breadcrumb-item + .breadcrumb-item:hover::before { text-decoration: none; }
.breadcrumb-item.active { color: #868e96; }

.pagination { display: flex; padding-left: 0; list-style: none; }

.page-link { position: relative; display: block; padding: 0.5rem 0.75rem; margin-left: -1px; line-height: 1.25; color: #ff1b00; background-color: #fff; border: 1px solid #dee2e6; }
.page-link:hover { color: #b31300; text-decoration: none; background-color: #e9ecef; border-color: #dee2e6; }
.page-link:focus { z-index: 2; outline: 0; box-shadow: 0 0 0 0.2rem rgba(255, 27, 0, 0.25); }
.page-link:not(:disabled):not(.disabled) { cursor: pointer; }

.page-item:first-child .page-link { margin-left: 0; }
.page-item.active .page-link { z-index: 1; color: #fff; background-color: #ff1b00; border-color: #ff1b00; }
.page-item.disabled .page-link { color: #868e96; pointer-events: none; cursor: auto; background-color: #fff; border-color: #dee2e6; }

.pagination-lg .page-link { padding: 0.75rem 1.5rem; font-size: 1.25rem; line-height: 1.5; }

.pagination-sm .page-link { padding: 0.25rem 0.5rem; font-size: 0.875rem; line-height: 1.5; }

.badge { display: inline-block; padding: 0.25em 0.4em; font-size: 75%; font-weight: 700; line-height: 1; text-align: center; white-space: nowrap; vertical-align: baseline; }
.badge:empty { display: none; }

.btn .badge { position: relative; top: -1px; }

.badge-pill { padding-right: 0.6em; padding-left: 0.6em; }

.badge-primary { color: #fff; background-color: #ff1b00; }
.badge-primary[href]:hover, .badge-primary[href]:focus { color: #fff; text-decoration: none; background-color: #cc1600; }

.badge-secondary { color: #212529; background-color: #fec200; }
.badge-secondary[href]:hover, .badge-secondary[href]:focus { color: #212529; text-decoration: none; background-color: #cb9b00; }

.badge-success { color: #212529; background-color: #34e16d; }
.badge-success[href]:hover, .badge-success[href]:focus { color: #212529; text-decoration: none; background-color: #1dc554; }

.badge-info { color: #fff; background-color: #00bbce; }
.badge-info[href]:hover, .badge-info[href]:focus { color: #fff; text-decoration: none; background-color: #008d9b; }

.badge-warning { color: #212529; background-color: #fec200; }
.badge-warning[href]:hover, .badge-warning[href]:focus { color: #212529; text-decoration: none; background-color: #cb9b00; }

.badge-danger { color: #fff; background-color: #ff1b00; }
.badge-danger[href]:hover, .badge-danger[href]:focus { color: #fff; text-decoration: none; background-color: #cc1600; }

.badge-light { color: #212529; background-color: #f8f9fa; }
.badge-light[href]:hover, .badge-light[href]:focus { color: #212529; text-decoration: none; background-color: #dae0e5; }

.badge-dark { color: #fff; background-color: #343a40; }
.badge-dark[href]:hover, .badge-dark[href]:focus { color: #fff; text-decoration: none; background-color: #1d2124; }

.jumbotron { padding: 2rem 1rem; margin-bottom: 2rem; background-color: #e9ecef; }
@media (min-width: 576px) { .jumbotron { padding: 4rem 2rem; } }

.jumbotron-fluid { padding-right: 0; padding-left: 0; }

.alert { position: relative; padding: 0.75rem 1.25rem; margin-bottom: 1rem; border: 1px solid transparent; }

.alert-heading { color: inherit; }

.alert-link { font-weight: 700; }

.alert-dismissible { padding-right: 4rem; }
.alert-dismissible .close { position: absolute; top: 0; right: 0; padding: 0.75rem 1.25rem; color: inherit; }

.alert-primary { color: #850e00; background-color: #ffd1cc; border-color: #ffbfb8; }
.alert-primary hr { border-top-color: #ffa89f; }
.alert-primary .alert-link { color: #520900; }

.alert-secondary { color: #846500; background-color: #fff3cc; border-color: #ffeeb8; }
.alert-secondary hr { border-top-color: #ffe89f; }
.alert-secondary .alert-link { color: #513e00; }

.alert-success { color: #1b7539; background-color: #d6f9e2; border-color: #c6f7d6; }
.alert-success hr { border-top-color: #b0f4c6; }
.alert-success .alert-link { color: #114c25; }

.alert-info { color: #00616b; background-color: #ccf1f5; border-color: #b8ecf1; }
.alert-info hr { border-top-color: #a3e6ed; }
.alert-info .alert-link { color: #003338; }

.alert-warning { color: #846500; background-color: #fff3cc; border-color: #ffeeb8; }
.alert-warning hr { border-top-color: #ffe89f; }
.alert-warning .alert-link { color: #513e00; }

.alert-danger { color: #850e00; background-color: #ffd1cc; border-color: #ffbfb8; }
.alert-danger hr { border-top-color: #ffa89f; }
.alert-danger .alert-link { color: #520900; }

.alert-light { color: #818182; background-color: #fefefe; border-color: #fdfdfe; }
.alert-light hr { border-top-color: #ececf6; }
.alert-light .alert-link { color: #686868; }

.alert-dark { color: #1b1e21; background-color: #d6d8d9; border-color: #c6c8ca; }
.alert-dark hr { border-top-color: #b9bbbe; }
.alert-dark .alert-link { color: #040505; }

@keyframes progress-bar-stripes { from { background-position: 1rem 0; }
  to { background-position: 0 0; } }
.progress { display: flex; height: 1rem; overflow: hidden; font-size: 0.75rem; background-color: #e9ecef; }

.progress-bar { display: flex; flex-direction: column; justify-content: center; color: #fff; text-align: center; background-color: #ff1b00; transition: width 0.6s ease; }

.progress-bar-striped { background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent); background-size: 1rem 1rem; }

.progress-bar-animated { animation: progress-bar-stripes 1s linear infinite; }

.media { display: flex; align-items: flex-start; }

.media-body { flex: 1; }

.list-group { display: flex; flex-direction: column; padding-left: 0; margin-bottom: 0; }

.list-group-item-action { width: 100%; color: #495057; text-align: inherit; }
.list-group-item-action:hover, .list-group-item-action:focus { color: #495057; text-decoration: none; background-color: #f8f9fa; }
.list-group-item-action:active { color: #212529; background-color: #e9ecef; }

.list-group-item { position: relative; display: block; padding: 0.75rem 1.25rem; margin-bottom: -1px; background-color: #fff; border: 1px solid rgba(0, 0, 0, 0.125); }
.list-group-item:last-child { margin-bottom: 0; }
.list-group-item:hover, .list-group-item:focus { z-index: 1; text-decoration: none; }
.list-group-item.disabled, .list-group-item:disabled { color: #868e96; background-color: #fff; }
.list-group-item.active { z-index: 2; color: #fff; background-color: #ff1b00; border-color: #ff1b00; }

.list-group-flush .list-group-item { border-right: 0; border-left: 0; }
.list-group-flush:first-child .list-group-item:first-child { border-top: 0; }
.list-group-flush:last-child .list-group-item:last-child { border-bottom: 0; }

.list-group-item-primary { color: #850e00; background-color: #ffbfb8; }
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus { color: #850e00; background-color: #ffa89f; }
.list-group-item-primary.list-group-item-action.active { color: #fff; background-color: #850e00; border-color: #850e00; }

.list-group-item-secondary { color: #846500; background-color: #ffeeb8; }
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus { color: #846500; background-color: #ffe89f; }
.list-group-item-secondary.list-group-item-action.active { color: #fff; background-color: #846500; border-color: #846500; }

.list-group-item-success { color: #1b7539; background-color: #c6f7d6; }
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus { color: #1b7539; background-color: #b0f4c6; }
.list-group-item-success.list-group-item-action.active { color: #fff; background-color: #1b7539; border-color: #1b7539; }

.list-group-item-info { color: #00616b; background-color: #b8ecf1; }
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus { color: #00616b; background-color: #a3e6ed; }
.list-group-item-info.list-group-item-action.active { color: #fff; background-color: #00616b; border-color: #00616b; }

.list-group-item-warning { color: #846500; background-color: #ffeeb8; }
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus { color: #846500; background-color: #ffe89f; }
.list-group-item-warning.list-group-item-action.active { color: #fff; background-color: #846500; border-color: #846500; }

.list-group-item-danger { color: #850e00; background-color: #ffbfb8; }
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus { color: #850e00; background-color: #ffa89f; }
.list-group-item-danger.list-group-item-action.active { color: #fff; background-color: #850e00; border-color: #850e00; }

.list-group-item-light { color: #818182; background-color: #fdfdfe; }
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus { color: #818182; background-color: #ececf6; }
.list-group-item-light.list-group-item-action.active { color: #fff; background-color: #818182; border-color: #818182; }

.list-group-item-dark { color: #1b1e21; background-color: #c6c8ca; }
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus { color: #1b1e21; background-color: #b9bbbe; }
.list-group-item-dark.list-group-item-action.active { color: #fff; background-color: #1b1e21; border-color: #1b1e21; }

.close { float: right; font-size: 1.5rem; font-weight: 700; line-height: 1; color: #000; text-shadow: 0 1px 0 #fff; opacity: .5; }
.close:hover, .close:focus { color: #000; text-decoration: none; opacity: .75; }
.close:not(:disabled):not(.disabled) { cursor: pointer; }

button.close { padding: 0; background-color: transparent; border: 0; -webkit-appearance: none; }

.modal-open { overflow: hidden; }

.modal { position: fixed; top: 0; right: 0; bottom: 0; left: 0; z-index: 1050; display: none; overflow: hidden; outline: 0; }
.modal-open .modal { overflow-x: hidden; overflow-y: auto; }

.modal-dialog { position: relative; width: auto; margin: 0.5rem; pointer-events: none; }
.modal.fade .modal-dialog { transition: transform 0.3s ease-out; transform: translate(0, -25%); }
.modal.show .modal-dialog { transform: translate(0, 0); }

.modal-dialog-centered { display: flex; align-items: center; min-height: calc(100% - (0.5rem * 2)); }

.modal-content { position: relative; display: flex; flex-direction: column; width: 100%; pointer-events: auto; background-color: #fff; background-clip: padding-box; border: 1px solid rgba(0, 0, 0, 0.2); outline: 0; }

.modal-backdrop { position: fixed; top: 0; right: 0; bottom: 0; left: 0; z-index: 1040; background-color: #000; }
.modal-backdrop.fade { opacity: 0; }
.modal-backdrop.show { opacity: 0.5; }

.modal-header { display: flex; align-items: flex-start; justify-content: space-between; padding: 3rem 3rem 1rem; border-bottom: 0 solid #e9ecef; }
.modal-header .close { padding: 3rem 3rem 1rem; margin: -3rem 3rem 1rem -3rem 3rem 1rem -3rem 3rem 1rem auto; }

.modal-title { margin-bottom: 0; line-height: 1.5; }

.modal-body { position: relative; flex: 1 1 auto; padding: 1rem 3rem 3rem; }

.modal-footer { display: flex; align-items: center; justify-content: flex-end; padding: 1rem 3rem 3rem; border-top: 1px solid #e9ecef; }
.modal-footer > :not(:first-child) { margin-left: .25rem; }
.modal-footer > :not(:last-child) { margin-right: .25rem; }

.modal-scrollbar-measure { position: absolute; top: -9999px; width: 50px; height: 50px; overflow: scroll; }

@media (min-width: 576px) { .modal-dialog { max-width: 500px; margin: 1.75rem auto; }
  .modal-dialog-centered { min-height: calc(100% - (1.75rem * 2)); }
  .modal-sm { max-width: 300px; } }
@media (min-width: 992px) { .modal-lg { max-width: 800px; } }
.tooltip { position: absolute; z-index: 1070; display: block; margin: 0; font-family: "Montserrat", "Helvetica Neue", "Helvetica", sans-serif; font-style: normal; font-weight: 400; line-height: 1.5; text-align: left; text-align: start; text-decoration: none; text-shadow: none; text-transform: none; letter-spacing: normal; word-break: normal; word-spacing: normal; white-space: normal; line-break: auto; font-size: 0.875rem; word-wrap: break-word; opacity: 0; }
.tooltip.show { opacity: 0.9; }
.tooltip .arrow { position: absolute; display: block; width: 0.8rem; height: 0.4rem; }
.tooltip .arrow::before { position: absolute; content: ""; border-color: transparent; border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] { padding: 0.4rem 0; }
.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow { bottom: 0; }
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before { top: 0; border-width: 0.4rem 0.4rem 0; border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] { padding: 0 0.4rem; }
.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow { left: 0; width: 0.4rem; height: 0.8rem; }
.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before { right: 0; border-width: 0.4rem 0.4rem 0.4rem 0; border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] { padding: 0.4rem 0; }
.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow { top: 0; }
.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before { bottom: 0; border-width: 0 0.4rem 0.4rem; border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] { padding: 0 0.4rem; }
.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow { right: 0; width: 0.4rem; height: 0.8rem; }
.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before { left: 0; border-width: 0.4rem 0 0.4rem 0.4rem; border-left-color: #000; }

.tooltip-inner { max-width: 200px; padding: 0.25rem 0.5rem; color: #fff; text-align: center; background-color: #000; }

.popover { position: absolute; top: 0; left: 0; z-index: 1060; display: block; max-width: 276px; font-family: "Montserrat", "Helvetica Neue", "Helvetica", sans-serif; font-style: normal; font-weight: 400; line-height: 1.5; text-align: left; text-align: start; text-decoration: none; text-shadow: none; text-transform: none; letter-spacing: normal; word-break: normal; word-spacing: normal; white-space: normal; line-break: auto; font-size: 0.875rem; word-wrap: break-word; background-color: #fff; background-clip: padding-box; border: 1px solid rgba(0, 0, 0, 0.2); }
.popover .arrow { position: absolute; display: block; width: 1rem; height: 0.5rem; margin: 0 0; }
.popover .arrow::before, .popover .arrow::after { position: absolute; display: block; content: ""; border-color: transparent; border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] { margin-bottom: 0.5rem; }
.bs-popover-top .arrow, .bs-popover-auto[x-placement^="top"] .arrow { bottom: calc((0.5rem + 1px) * -1); }
.bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before, .bs-popover-top .arrow::after, .bs-popover-auto[x-placement^="top"] .arrow::after { border-width: 0.5rem 0.5rem 0; }
.bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before { bottom: 0; border-top-color: rgba(0, 0, 0, 0.25); }
.bs-popover-top .arrow::after, .bs-popover-auto[x-placement^="top"] .arrow::after { bottom: 1px; border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] { margin-left: 0.5rem; }
.bs-popover-right .arrow, .bs-popover-auto[x-placement^="right"] .arrow { left: calc((0.5rem + 1px) * -1); width: 0.5rem; height: 1rem; margin: 0 0; }
.bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before, .bs-popover-right .arrow::after, .bs-popover-auto[x-placement^="right"] .arrow::after { border-width: 0.5rem 0.5rem 0.5rem 0; }
.bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before { left: 0; border-right-color: rgba(0, 0, 0, 0.25); }
.bs-popover-right .arrow::after, .bs-popover-auto[x-placement^="right"] .arrow::after { left: 1px; border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] { margin-top: 0.5rem; }
.bs-popover-bottom .arrow, .bs-popover-auto[x-placement^="bottom"] .arrow { top: calc((0.5rem + 1px) * -1); }
.bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before, .bs-popover-bottom .arrow::after, .bs-popover-auto[x-placement^="bottom"] .arrow::after { border-width: 0 0.5rem 0.5rem 0.5rem; }
.bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before { top: 0; border-bottom-color: rgba(0, 0, 0, 0.25); }
.bs-popover-bottom .arrow::after, .bs-popover-auto[x-placement^="bottom"] .arrow::after { top: 1px; border-bottom-color: #fff; }
.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before { position: absolute; top: 0; left: 50%; display: block; width: 1rem; margin-left: -0.5rem; content: ""; border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] { margin-right: 0.5rem; }
.bs-popover-left .arrow, .bs-popover-auto[x-placement^="left"] .arrow { right: calc((0.5rem + 1px) * -1); width: 0.5rem; height: 1rem; margin: 0 0; }
.bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before, .bs-popover-left .arrow::after, .bs-popover-auto[x-placement^="left"] .arrow::after { border-width: 0.5rem 0 0.5rem 0.5rem; }
.bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before { right: 0; border-left-color: rgba(0, 0, 0, 0.25); }
.bs-popover-left .arrow::after, .bs-popover-auto[x-placement^="left"] .arrow::after { right: 1px; border-left-color: #fff; }

.popover-header { padding: 0.5rem 0.75rem; margin-bottom: 0; font-size: 1rem; color: inherit; background-color: #f7f7f7; border-bottom: 1px solid #ebebeb; }
.popover-header:empty { display: none; }

.popover-body { padding: 0.5rem 0.75rem; color: #212529; }

.carousel { position: relative; }

.carousel-inner { position: relative; width: 100%; overflow: hidden; }

.carousel-item { position: relative; display: none; align-items: center; width: 100%; transition: transform 0.6s ease; backface-visibility: hidden; perspective: 1000px; }

.carousel-item.active, .carousel-item-next, .carousel-item-prev { display: block; }

.carousel-item-next, .carousel-item-prev { position: absolute; top: 0; }

.carousel-item-next.carousel-item-left, .carousel-item-prev.carousel-item-right { transform: translateX(0); }
@supports (transform-style: preserve-3d) { .carousel-item-next.carousel-item-left, .carousel-item-prev.carousel-item-right { transform: translate3d(0, 0, 0); } }

.carousel-item-next, .active.carousel-item-right { transform: translateX(100%); }
@supports (transform-style: preserve-3d) { .carousel-item-next, .active.carousel-item-right { transform: translate3d(100%, 0, 0); } }

.carousel-item-prev, .active.carousel-item-left { transform: translateX(-100%); }
@supports (transform-style: preserve-3d) { .carousel-item-prev, .active.carousel-item-left { transform: translate3d(-100%, 0, 0); } }

.carousel-control-prev, .carousel-control-next { position: absolute; top: 0; bottom: 0; display: flex; align-items: center; justify-content: center; width: 15%; color: #fff; text-align: center; opacity: 0.5; }
.carousel-control-prev:hover, .carousel-control-prev:focus, .carousel-control-next:hover, .carousel-control-next:focus { color: #fff; text-decoration: none; outline: 0; opacity: .9; }

.carousel-control-prev { left: 0; }

.carousel-control-next { right: 0; }

.carousel-control-prev-icon, .carousel-control-next-icon { display: inline-block; width: 20px; height: 20px; background: transparent no-repeat center center; background-size: 100% 100%; }

.carousel-control-prev-icon { background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E"); }

.carousel-control-next-icon { background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E"); }

.carousel-indicators { position: absolute; right: 0; bottom: 10px; left: 0; z-index: 15; display: flex; justify-content: center; padding-left: 0; margin-right: 15%; margin-left: 15%; list-style: none; }
.carousel-indicators li { position: relative; flex: 0 1 auto; width: 30px; height: 3px; margin-right: 3px; margin-left: 3px; text-indent: -999px; background-color: rgba(255, 255, 255, 0.5); }
.carousel-indicators li::before { position: absolute; top: -10px; left: 0; display: inline-block; width: 100%; height: 10px; content: ""; }
.carousel-indicators li::after { position: absolute; bottom: -10px; left: 0; display: inline-block; width: 100%; height: 10px; content: ""; }
.carousel-indicators .active { background-color: #fff; }

.carousel-caption { position: absolute; right: 15%; bottom: 20px; left: 15%; z-index: 10; padding-top: 20px; padding-bottom: 20px; color: #fff; text-align: center; }

.align-baseline { vertical-align: baseline !important; }

.align-top { vertical-align: top !important; }

.align-middle { vertical-align: middle !important; }

.align-bottom { vertical-align: bottom !important; }

.align-text-bottom { vertical-align: text-bottom !important; }

.align-text-top { vertical-align: text-top !important; }

.bg-primary { background-color: #ff1b00 !important; }

a.bg-primary:hover, a.bg-primary:focus, button.bg-primary:hover, button.bg-primary:focus { background-color: #cc1600 !important; }

.bg-secondary { background-color: #fec200 !important; }

a.bg-secondary:hover, a.bg-secondary:focus, button.bg-secondary:hover, button.bg-secondary:focus { background-color: #cb9b00 !important; }

.bg-success { background-color: #34e16d !important; }

a.bg-success:hover, a.bg-success:focus, button.bg-success:hover, button.bg-success:focus { background-color: #1dc554 !important; }

.bg-info { background-color: #00bbce !important; }

a.bg-info:hover, a.bg-info:focus, button.bg-info:hover, button.bg-info:focus { background-color: #008d9b !important; }

.bg-warning { background-color: #fec200 !important; }

a.bg-warning:hover, a.bg-warning:focus, button.bg-warning:hover, button.bg-warning:focus { background-color: #cb9b00 !important; }

.bg-danger { background-color: #ff1b00 !important; }

a.bg-danger:hover, a.bg-danger:focus, button.bg-danger:hover, button.bg-danger:focus { background-color: #cc1600 !important; }

.bg-light { background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus, button.bg-light:hover, button.bg-light:focus { background-color: #dae0e5 !important; }

.bg-dark { background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus, button.bg-dark:hover, button.bg-dark:focus { background-color: #1d2124 !important; }

.bg-white { background-color: #fff !important; }

.bg-transparent { background-color: transparent !important; }

.border { border: 1px solid #dee2e6 !important; }

.border-top { border-top: 1px solid #dee2e6 !important; }

.border-right { border-right: 1px solid #dee2e6 !important; }

.border-bottom { border-bottom: 1px solid #dee2e6 !important; }

.border-left { border-left: 1px solid #dee2e6 !important; }

.border-0 { border: 0 !important; }

.border-top-0 { border-top: 0 !important; }

.border-right-0 { border-right: 0 !important; }

.border-bottom-0 { border-bottom: 0 !important; }

.border-left-0 { border-left: 0 !important; }

.border-primary { border-color: #ff1b00 !important; }

.border-secondary { border-color: #fec200 !important; }

.border-success { border-color: #34e16d !important; }

.border-info { border-color: #00bbce !important; }

.border-warning { border-color: #fec200 !important; }

.border-danger { border-color: #ff1b00 !important; }

.border-light { border-color: #f8f9fa !important; }

.border-dark { border-color: #343a40 !important; }

.border-white { border-color: #fff !important; }

.rounded { border-radius: 0 !important; }

.rounded-top { border-top-left-radius: 0 !important; border-top-right-radius: 0 !important; }

.rounded-right { border-top-right-radius: 0 !important; border-bottom-right-radius: 0 !important; }

.rounded-bottom { border-bottom-right-radius: 0 !important; border-bottom-left-radius: 0 !important; }

.rounded-left { border-top-left-radius: 0 !important; border-bottom-left-radius: 0 !important; }

.rounded-circle, .elements-promotion-introduction figure img { border-radius: 50% !important; }

.rounded-0 { border-radius: 0 !important; }

.clearfix::after { display: block; clear: both; content: ""; }

.d-none { display: none !important; }

.d-inline { display: inline !important; }

.d-inline-block { display: inline-block !important; }

.d-block { display: block !important; }

.d-table { display: table !important; }

.d-table-row { display: table-row !important; }

.d-table-cell { display: table-cell !important; }

.d-flex { display: flex !important; }

.d-inline-flex { display: inline-flex !important; }

@media (min-width: 576px) { .d-sm-none { display: none !important; }
  .d-sm-inline { display: inline !important; }
  .d-sm-inline-block { display: inline-block !important; }
  .d-sm-block { display: block !important; }
  .d-sm-table { display: table !important; }
  .d-sm-table-row { display: table-row !important; }
  .d-sm-table-cell { display: table-cell !important; }
  .d-sm-flex { display: flex !important; }
  .d-sm-inline-flex { display: inline-flex !important; } }
@media (min-width: 768px) { .d-md-none { display: none !important; }
  .d-md-inline { display: inline !important; }
  .d-md-inline-block { display: inline-block !important; }
  .d-md-block { display: block !important; }
  .d-md-table { display: table !important; }
  .d-md-table-row { display: table-row !important; }
  .d-md-table-cell { display: table-cell !important; }
  .d-md-flex { display: flex !important; }
  .d-md-inline-flex { display: inline-flex !important; } }
@media (min-width: 992px) { .d-lg-none { display: none !important; }
  .d-lg-inline { display: inline !important; }
  .d-lg-inline-block { display: inline-block !important; }
  .d-lg-block { display: block !important; }
  .d-lg-table { display: table !important; }
  .d-lg-table-row { display: table-row !important; }
  .d-lg-table-cell { display: table-cell !important; }
  .d-lg-flex { display: flex !important; }
  .d-lg-inline-flex { display: inline-flex !important; } }
@media (min-width: 1200px) { .d-xl-none { display: none !important; }
  .d-xl-inline { display: inline !important; }
  .d-xl-inline-block { display: inline-block !important; }
  .d-xl-block { display: block !important; }
  .d-xl-table { display: table !important; }
  .d-xl-table-row { display: table-row !important; }
  .d-xl-table-cell { display: table-cell !important; }
  .d-xl-flex { display: flex !important; }
  .d-xl-inline-flex { display: inline-flex !important; } }
@media print { .d-print-none { display: none !important; }
  .d-print-inline { display: inline !important; }
  .d-print-inline-block { display: inline-block !important; }
  .d-print-block { display: block !important; }
  .d-print-table { display: table !important; }
  .d-print-table-row { display: table-row !important; }
  .d-print-table-cell { display: table-cell !important; }
  .d-print-flex { display: flex !important; }
  .d-print-inline-flex { display: inline-flex !important; } }
.embed-responsive { position: relative; display: block; width: 100%; padding: 0; overflow: hidden; }
.embed-responsive::before { display: block; content: ""; }
.embed-responsive .embed-responsive-item, .embed-responsive iframe, .embed-responsive embed, .embed-responsive object, .embed-responsive video { position: absolute; top: 0; bottom: 0; left: 0; width: 100%; height: 100%; border: 0; }

.embed-responsive-21by9::before { padding-top: 42.8571428571%; }

.embed-responsive-16by9::before { padding-top: 56.25%; }

.embed-responsive-4by3::before { padding-top: 75%; }

.embed-responsive-1by1::before { padding-top: 100%; }

.flex-row { flex-direction: row !important; }

.flex-column { flex-direction: column !important; }

.flex-row-reverse { flex-direction: row-reverse !important; }

.flex-column-reverse { flex-direction: column-reverse !important; }

.flex-wrap { flex-wrap: wrap !important; }

.flex-nowrap { flex-wrap: nowrap !important; }

.flex-wrap-reverse { flex-wrap: wrap-reverse !important; }

.justify-content-start { justify-content: flex-start !important; }

.justify-content-end { justify-content: flex-end !important; }

.justify-content-center { justify-content: center !important; }

.justify-content-between { justify-content: space-between !important; }

.justify-content-around { justify-content: space-around !important; }

.align-items-start { align-items: flex-start !important; }

.align-items-end { align-items: flex-end !important; }

.align-items-center { align-items: center !important; }

.align-items-baseline { align-items: baseline !important; }

.align-items-stretch { align-items: stretch !important; }

.align-content-start { align-content: flex-start !important; }

.align-content-end { align-content: flex-end !important; }

.align-content-center { align-content: center !important; }

.align-content-between { align-content: space-between !important; }

.align-content-around { align-content: space-around !important; }

.align-content-stretch { align-content: stretch !important; }

.align-self-auto { align-self: auto !important; }

.align-self-start { align-self: flex-start !important; }

.align-self-end { align-self: flex-end !important; }

.align-self-center { align-self: center !important; }

.align-self-baseline { align-self: baseline !important; }

.align-self-stretch { align-self: stretch !important; }

@media (min-width: 576px) { .flex-sm-row { flex-direction: row !important; }
  .flex-sm-column { flex-direction: column !important; }
  .flex-sm-row-reverse { flex-direction: row-reverse !important; }
  .flex-sm-column-reverse { flex-direction: column-reverse !important; }
  .flex-sm-wrap { flex-wrap: wrap !important; }
  .flex-sm-nowrap { flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse { flex-wrap: wrap-reverse !important; }
  .justify-content-sm-start { justify-content: flex-start !important; }
  .justify-content-sm-end { justify-content: flex-end !important; }
  .justify-content-sm-center { justify-content: center !important; }
  .justify-content-sm-between { justify-content: space-between !important; }
  .justify-content-sm-around { justify-content: space-around !important; }
  .align-items-sm-start { align-items: flex-start !important; }
  .align-items-sm-end { align-items: flex-end !important; }
  .align-items-sm-center { align-items: center !important; }
  .align-items-sm-baseline { align-items: baseline !important; }
  .align-items-sm-stretch { align-items: stretch !important; }
  .align-content-sm-start { align-content: flex-start !important; }
  .align-content-sm-end { align-content: flex-end !important; }
  .align-content-sm-center { align-content: center !important; }
  .align-content-sm-between { align-content: space-between !important; }
  .align-content-sm-around { align-content: space-around !important; }
  .align-content-sm-stretch { align-content: stretch !important; }
  .align-self-sm-auto { align-self: auto !important; }
  .align-self-sm-start { align-self: flex-start !important; }
  .align-self-sm-end { align-self: flex-end !important; }
  .align-self-sm-center { align-self: center !important; }
  .align-self-sm-baseline { align-self: baseline !important; }
  .align-self-sm-stretch { align-self: stretch !important; } }
@media (min-width: 768px) { .flex-md-row { flex-direction: row !important; }
  .flex-md-column { flex-direction: column !important; }
  .flex-md-row-reverse { flex-direction: row-reverse !important; }
  .flex-md-column-reverse { flex-direction: column-reverse !important; }
  .flex-md-wrap { flex-wrap: wrap !important; }
  .flex-md-nowrap { flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse { flex-wrap: wrap-reverse !important; }
  .justify-content-md-start { justify-content: flex-start !important; }
  .justify-content-md-end { justify-content: flex-end !important; }
  .justify-content-md-center { justify-content: center !important; }
  .justify-content-md-between { justify-content: space-between !important; }
  .justify-content-md-around { justify-content: space-around !important; }
  .align-items-md-start { align-items: flex-start !important; }
  .align-items-md-end { align-items: flex-end !important; }
  .align-items-md-center { align-items: center !important; }
  .align-items-md-baseline { align-items: baseline !important; }
  .align-items-md-stretch { align-items: stretch !important; }
  .align-content-md-start { align-content: flex-start !important; }
  .align-content-md-end { align-content: flex-end !important; }
  .align-content-md-center { align-content: center !important; }
  .align-content-md-between { align-content: space-between !important; }
  .align-content-md-around { align-content: space-around !important; }
  .align-content-md-stretch { align-content: stretch !important; }
  .align-self-md-auto { align-self: auto !important; }
  .align-self-md-start { align-self: flex-start !important; }
  .align-self-md-end { align-self: flex-end !important; }
  .align-self-md-center { align-self: center !important; }
  .align-self-md-baseline { align-self: baseline !important; }
  .align-self-md-stretch { align-self: stretch !important; } }
@media (min-width: 992px) { .flex-lg-row { flex-direction: row !important; }
  .flex-lg-column { flex-direction: column !important; }
  .flex-lg-row-reverse { flex-direction: row-reverse !important; }
  .flex-lg-column-reverse { flex-direction: column-reverse !important; }
  .flex-lg-wrap { flex-wrap: wrap !important; }
  .flex-lg-nowrap { flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse { flex-wrap: wrap-reverse !important; }
  .justify-content-lg-start { justify-content: flex-start !important; }
  .justify-content-lg-end { justify-content: flex-end !important; }
  .justify-content-lg-center { justify-content: center !important; }
  .justify-content-lg-between { justify-content: space-between !important; }
  .justify-content-lg-around { justify-content: space-around !important; }
  .align-items-lg-start { align-items: flex-start !important; }
  .align-items-lg-end { align-items: flex-end !important; }
  .align-items-lg-center { align-items: center !important; }
  .align-items-lg-baseline { align-items: baseline !important; }
  .align-items-lg-stretch { align-items: stretch !important; }
  .align-content-lg-start { align-content: flex-start !important; }
  .align-content-lg-end { align-content: flex-end !important; }
  .align-content-lg-center { align-content: center !important; }
  .align-content-lg-between { align-content: space-between !important; }
  .align-content-lg-around { align-content: space-around !important; }
  .align-content-lg-stretch { align-content: stretch !important; }
  .align-self-lg-auto { align-self: auto !important; }
  .align-self-lg-start { align-self: flex-start !important; }
  .align-self-lg-end { align-self: flex-end !important; }
  .align-self-lg-center { align-self: center !important; }
  .align-self-lg-baseline { align-self: baseline !important; }
  .align-self-lg-stretch { align-self: stretch !important; } }
@media (min-width: 1200px) { .flex-xl-row { flex-direction: row !important; }
  .flex-xl-column { flex-direction: column !important; }
  .flex-xl-row-reverse { flex-direction: row-reverse !important; }
  .flex-xl-column-reverse { flex-direction: column-reverse !important; }
  .flex-xl-wrap { flex-wrap: wrap !important; }
  .flex-xl-nowrap { flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse { flex-wrap: wrap-reverse !important; }
  .justify-content-xl-start { justify-content: flex-start !important; }
  .justify-content-xl-end { justify-content: flex-end !important; }
  .justify-content-xl-center { justify-content: center !important; }
  .justify-content-xl-between { justify-content: space-between !important; }
  .justify-content-xl-around { justify-content: space-around !important; }
  .align-items-xl-start { align-items: flex-start !important; }
  .align-items-xl-end { align-items: flex-end !important; }
  .align-items-xl-center { align-items: center !important; }
  .align-items-xl-baseline { align-items: baseline !important; }
  .align-items-xl-stretch { align-items: stretch !important; }
  .align-content-xl-start { align-content: flex-start !important; }
  .align-content-xl-end { align-content: flex-end !important; }
  .align-content-xl-center { align-content: center !important; }
  .align-content-xl-between { align-content: space-between !important; }
  .align-content-xl-around { align-content: space-around !important; }
  .align-content-xl-stretch { align-content: stretch !important; }
  .align-self-xl-auto { align-self: auto !important; }
  .align-self-xl-start { align-self: flex-start !important; }
  .align-self-xl-end { align-self: flex-end !important; }
  .align-self-xl-center { align-self: center !important; }
  .align-self-xl-baseline { align-self: baseline !important; }
  .align-self-xl-stretch { align-self: stretch !important; } }
.float-left { float: left !important; }

.float-right { float: right !important; }

.float-none { float: none !important; }

@media (min-width: 576px) { .float-sm-left { float: left !important; }
  .float-sm-right { float: right !important; }
  .float-sm-none { float: none !important; } }
@media (min-width: 768px) { .float-md-left { float: left !important; }
  .float-md-right { float: right !important; }
  .float-md-none { float: none !important; } }
@media (min-width: 992px) { .float-lg-left { float: left !important; }
  .float-lg-right { float: right !important; }
  .float-lg-none { float: none !important; } }
@media (min-width: 1200px) { .float-xl-left { float: left !important; }
  .float-xl-right { float: right !important; }
  .float-xl-none { float: none !important; } }
.position-static { position: static !important; }

.position-relative { position: relative !important; }

.position-absolute { position: absolute !important; }

.position-fixed { position: fixed !important; }

.position-sticky { position: sticky !important; }

.fixed-top { position: fixed; top: 0; right: 0; left: 0; z-index: 1030; }

.fixed-bottom { position: fixed; right: 0; bottom: 0; left: 0; z-index: 1030; }

@supports (position: sticky) { .sticky-top { position: sticky; top: 0; z-index: 1020; } }

.sr-only { position: absolute; width: 1px; height: 1px; padding: 0; overflow: hidden; clip: rect(0, 0, 0, 0); white-space: nowrap; clip-path: inset(50%); border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus { position: static; width: auto; height: auto; overflow: visible; clip: auto; white-space: normal; clip-path: none; }

.w-25 { width: 25% !important; }

.w-50 { width: 50% !important; }

.w-75 { width: 75% !important; }

.w-100 { width: 100% !important; }

.h-25 { height: 25% !important; }

.h-50 { height: 50% !important; }

.h-75 { height: 75% !important; }

.h-100 { height: 100% !important; }

.mw-100 { max-width: 100% !important; }

.mh-100 { max-height: 100% !important; }

.m-0 { margin: 0 !important; }

.mt-0, .my-0 { margin-top: 0 !important; }

.mr-0, .mx-0 { margin-right: 0 !important; }

.mb-0, .my-0 { margin-bottom: 0 !important; }

.ml-0, .mx-0 { margin-left: 0 !important; }

.m-1 { margin: 0.25rem !important; }

.mt-1, .my-1 { margin-top: 0.25rem !important; }

.mr-1, .mx-1 { margin-right: 0.25rem !important; }

.mb-1, .my-1 { margin-bottom: 0.25rem !important; }

.ml-1, .mx-1 { margin-left: 0.25rem !important; }

.m-2 { margin: 0.5rem !important; }

.mt-2, .my-2 { margin-top: 0.5rem !important; }

.mr-2, .mx-2 { margin-right: 0.5rem !important; }

.mb-2, .my-2 { margin-bottom: 0.5rem !important; }

.ml-2, .mx-2 { margin-left: 0.5rem !important; }

.m-3 { margin: 1rem !important; }

.mt-3, .my-3 { margin-top: 1rem !important; }

.mr-3, .mx-3 { margin-right: 1rem !important; }

.mb-3, .my-3 { margin-bottom: 1rem !important; }

.ml-3, .mx-3 { margin-left: 1rem !important; }

.m-4 { margin: 1.5rem !important; }

.mt-4, .my-4 { margin-top: 1.5rem !important; }

.mr-4, .mx-4 { margin-right: 1.5rem !important; }

.mb-4, .my-4 { margin-bottom: 1.5rem !important; }

.ml-4, .mx-4 { margin-left: 1.5rem !important; }

.m-5 { margin: 3rem !important; }

.mt-5, .my-5 { margin-top: 3rem !important; }

.mr-5, .mx-5 { margin-right: 3rem !important; }

.mb-5, .my-5 { margin-bottom: 3rem !important; }

.ml-5, .mx-5 { margin-left: 3rem !important; }

.p-0 { padding: 0 !important; }

.pt-0, .py-0 { padding-top: 0 !important; }

.pr-0, .px-0 { padding-right: 0 !important; }

.pb-0, .py-0 { padding-bottom: 0 !important; }

.pl-0, .px-0 { padding-left: 0 !important; }

.p-1 { padding: 0.25rem !important; }

.pt-1, .py-1 { padding-top: 0.25rem !important; }

.pr-1, .px-1 { padding-right: 0.25rem !important; }

.pb-1, .py-1 { padding-bottom: 0.25rem !important; }

.pl-1, .px-1 { padding-left: 0.25rem !important; }

.p-2 { padding: 0.5rem !important; }

.pt-2, .py-2 { padding-top: 0.5rem !important; }

.pr-2, .px-2 { padding-right: 0.5rem !important; }

.pb-2, .py-2 { padding-bottom: 0.5rem !important; }

.pl-2, .px-2 { padding-left: 0.5rem !important; }

.p-3 { padding: 1rem !important; }

.pt-3, .py-3 { padding-top: 1rem !important; }

.pr-3, .px-3 { padding-right: 1rem !important; }

.pb-3, .py-3 { padding-bottom: 1rem !important; }

.pl-3, .px-3 { padding-left: 1rem !important; }

.p-4 { padding: 1.5rem !important; }

.pt-4, .py-4 { padding-top: 1.5rem !important; }

.pr-4, .px-4 { padding-right: 1.5rem !important; }

.pb-4, .py-4 { padding-bottom: 1.5rem !important; }

.pl-4, .px-4 { padding-left: 1.5rem !important; }

.p-5 { padding: 3rem !important; }

.pt-5, .py-5 { padding-top: 3rem !important; }

.pr-5, .px-5 { padding-right: 3rem !important; }

.pb-5, .py-5 { padding-bottom: 3rem !important; }

.pl-5, .px-5 { padding-left: 3rem !important; }

.m-auto { margin: auto !important; }

.mt-auto, .my-auto { margin-top: auto !important; }

.mr-auto, .mx-auto { margin-right: auto !important; }

.mb-auto, .my-auto { margin-bottom: auto !important; }

.ml-auto, .mx-auto { margin-left: auto !important; }

@media (min-width: 576px) { .m-sm-0 { margin: 0 !important; }
  .mt-sm-0, .my-sm-0 { margin-top: 0 !important; }
  .mr-sm-0, .mx-sm-0 { margin-right: 0 !important; }
  .mb-sm-0, .my-sm-0 { margin-bottom: 0 !important; }
  .ml-sm-0, .mx-sm-0 { margin-left: 0 !important; }
  .m-sm-1 { margin: 0.25rem !important; }
  .mt-sm-1, .my-sm-1 { margin-top: 0.25rem !important; }
  .mr-sm-1, .mx-sm-1 { margin-right: 0.25rem !important; }
  .mb-sm-1, .my-sm-1 { margin-bottom: 0.25rem !important; }
  .ml-sm-1, .mx-sm-1 { margin-left: 0.25rem !important; }
  .m-sm-2 { margin: 0.5rem !important; }
  .mt-sm-2, .my-sm-2 { margin-top: 0.5rem !important; }
  .mr-sm-2, .mx-sm-2 { margin-right: 0.5rem !important; }
  .mb-sm-2, .my-sm-2 { margin-bottom: 0.5rem !important; }
  .ml-sm-2, .mx-sm-2 { margin-left: 0.5rem !important; }
  .m-sm-3 { margin: 1rem !important; }
  .mt-sm-3, .my-sm-3 { margin-top: 1rem !important; }
  .mr-sm-3, .mx-sm-3 { margin-right: 1rem !important; }
  .mb-sm-3, .my-sm-3 { margin-bottom: 1rem !important; }
  .ml-sm-3, .mx-sm-3 { margin-left: 1rem !important; }
  .m-sm-4 { margin: 1.5rem !important; }
  .mt-sm-4, .my-sm-4 { margin-top: 1.5rem !important; }
  .mr-sm-4, .mx-sm-4 { margin-right: 1.5rem !important; }
  .mb-sm-4, .my-sm-4 { margin-bottom: 1.5rem !important; }
  .ml-sm-4, .mx-sm-4 { margin-left: 1.5rem !important; }
  .m-sm-5 { margin: 3rem !important; }
  .mt-sm-5, .my-sm-5 { margin-top: 3rem !important; }
  .mr-sm-5, .mx-sm-5 { margin-right: 3rem !important; }
  .mb-sm-5, .my-sm-5 { margin-bottom: 3rem !important; }
  .ml-sm-5, .mx-sm-5 { margin-left: 3rem !important; }
  .p-sm-0 { padding: 0 !important; }
  .pt-sm-0, .py-sm-0 { padding-top: 0 !important; }
  .pr-sm-0, .px-sm-0 { padding-right: 0 !important; }
  .pb-sm-0, .py-sm-0 { padding-bottom: 0 !important; }
  .pl-sm-0, .px-sm-0 { padding-left: 0 !important; }
  .p-sm-1 { padding: 0.25rem !important; }
  .pt-sm-1, .py-sm-1 { padding-top: 0.25rem !important; }
  .pr-sm-1, .px-sm-1 { padding-right: 0.25rem !important; }
  .pb-sm-1, .py-sm-1 { padding-bottom: 0.25rem !important; }
  .pl-sm-1, .px-sm-1 { padding-left: 0.25rem !important; }
  .p-sm-2 { padding: 0.5rem !important; }
  .pt-sm-2, .py-sm-2 { padding-top: 0.5rem !important; }
  .pr-sm-2, .px-sm-2 { padding-right: 0.5rem !important; }
  .pb-sm-2, .py-sm-2 { padding-bottom: 0.5rem !important; }
  .pl-sm-2, .px-sm-2 { padding-left: 0.5rem !important; }
  .p-sm-3 { padding: 1rem !important; }
  .pt-sm-3, .py-sm-3 { padding-top: 1rem !important; }
  .pr-sm-3, .px-sm-3 { padding-right: 1rem !important; }
  .pb-sm-3, .py-sm-3 { padding-bottom: 1rem !important; }
  .pl-sm-3, .px-sm-3 { padding-left: 1rem !important; }
  .p-sm-4 { padding: 1.5rem !important; }
  .pt-sm-4, .py-sm-4 { padding-top: 1.5rem !important; }
  .pr-sm-4, .px-sm-4 { padding-right: 1.5rem !important; }
  .pb-sm-4, .py-sm-4 { padding-bottom: 1.5rem !important; }
  .pl-sm-4, .px-sm-4 { padding-left: 1.5rem !important; }
  .p-sm-5 { padding: 3rem !important; }
  .pt-sm-5, .py-sm-5 { padding-top: 3rem !important; }
  .pr-sm-5, .px-sm-5 { padding-right: 3rem !important; }
  .pb-sm-5, .py-sm-5 { padding-bottom: 3rem !important; }
  .pl-sm-5, .px-sm-5 { padding-left: 3rem !important; }
  .m-sm-auto { margin: auto !important; }
  .mt-sm-auto, .my-sm-auto { margin-top: auto !important; }
  .mr-sm-auto, .mx-sm-auto { margin-right: auto !important; }
  .mb-sm-auto, .my-sm-auto { margin-bottom: auto !important; }
  .ml-sm-auto, .mx-sm-auto { margin-left: auto !important; } }
@media (min-width: 768px) { .m-md-0 { margin: 0 !important; }
  .mt-md-0, .my-md-0 { margin-top: 0 !important; }
  .mr-md-0, .mx-md-0 { margin-right: 0 !important; }
  .mb-md-0, .my-md-0 { margin-bottom: 0 !important; }
  .ml-md-0, .mx-md-0 { margin-left: 0 !important; }
  .m-md-1 { margin: 0.25rem !important; }
  .mt-md-1, .my-md-1 { margin-top: 0.25rem !important; }
  .mr-md-1, .mx-md-1 { margin-right: 0.25rem !important; }
  .mb-md-1, .my-md-1 { margin-bottom: 0.25rem !important; }
  .ml-md-1, .mx-md-1 { margin-left: 0.25rem !important; }
  .m-md-2 { margin: 0.5rem !important; }
  .mt-md-2, .my-md-2 { margin-top: 0.5rem !important; }
  .mr-md-2, .mx-md-2 { margin-right: 0.5rem !important; }
  .mb-md-2, .my-md-2 { margin-bottom: 0.5rem !important; }
  .ml-md-2, .mx-md-2 { margin-left: 0.5rem !important; }
  .m-md-3 { margin: 1rem !important; }
  .mt-md-3, .my-md-3 { margin-top: 1rem !important; }
  .mr-md-3, .mx-md-3 { margin-right: 1rem !important; }
  .mb-md-3, .my-md-3 { margin-bottom: 1rem !important; }
  .ml-md-3, .mx-md-3 { margin-left: 1rem !important; }
  .m-md-4 { margin: 1.5rem !important; }
  .mt-md-4, .my-md-4 { margin-top: 1.5rem !important; }
  .mr-md-4, .mx-md-4 { margin-right: 1.5rem !important; }
  .mb-md-4, .my-md-4 { margin-bottom: 1.5rem !important; }
  .ml-md-4, .mx-md-4 { margin-left: 1.5rem !important; }
  .m-md-5 { margin: 3rem !important; }
  .mt-md-5, .my-md-5 { margin-top: 3rem !important; }
  .mr-md-5, .mx-md-5 { margin-right: 3rem !important; }
  .mb-md-5, .my-md-5 { margin-bottom: 3rem !important; }
  .ml-md-5, .mx-md-5 { margin-left: 3rem !important; }
  .p-md-0 { padding: 0 !important; }
  .pt-md-0, .py-md-0 { padding-top: 0 !important; }
  .pr-md-0, .px-md-0 { padding-right: 0 !important; }
  .pb-md-0, .py-md-0 { padding-bottom: 0 !important; }
  .pl-md-0, .px-md-0 { padding-left: 0 !important; }
  .p-md-1 { padding: 0.25rem !important; }
  .pt-md-1, .py-md-1 { padding-top: 0.25rem !important; }
  .pr-md-1, .px-md-1 { padding-right: 0.25rem !important; }
  .pb-md-1, .py-md-1 { padding-bottom: 0.25rem !important; }
  .pl-md-1, .px-md-1 { padding-left: 0.25rem !important; }
  .p-md-2 { padding: 0.5rem !important; }
  .pt-md-2, .py-md-2 { padding-top: 0.5rem !important; }
  .pr-md-2, .px-md-2 { padding-right: 0.5rem !important; }
  .pb-md-2, .py-md-2 { padding-bottom: 0.5rem !important; }
  .pl-md-2, .px-md-2 { padding-left: 0.5rem !important; }
  .p-md-3 { padding: 1rem !important; }
  .pt-md-3, .py-md-3 { padding-top: 1rem !important; }
  .pr-md-3, .px-md-3 { padding-right: 1rem !important; }
  .pb-md-3, .py-md-3 { padding-bottom: 1rem !important; }
  .pl-md-3, .px-md-3 { padding-left: 1rem !important; }
  .p-md-4 { padding: 1.5rem !important; }
  .pt-md-4, .py-md-4 { padding-top: 1.5rem !important; }
  .pr-md-4, .px-md-4 { padding-right: 1.5rem !important; }
  .pb-md-4, .py-md-4 { padding-bottom: 1.5rem !important; }
  .pl-md-4, .px-md-4 { padding-left: 1.5rem !important; }
  .p-md-5 { padding: 3rem !important; }
  .pt-md-5, .py-md-5 { padding-top: 3rem !important; }
  .pr-md-5, .px-md-5 { padding-right: 3rem !important; }
  .pb-md-5, .py-md-5 { padding-bottom: 3rem !important; }
  .pl-md-5, .px-md-5 { padding-left: 3rem !important; }
  .m-md-auto { margin: auto !important; }
  .mt-md-auto, .my-md-auto { margin-top: auto !important; }
  .mr-md-auto, .mx-md-auto { margin-right: auto !important; }
  .mb-md-auto, .my-md-auto { margin-bottom: auto !important; }
  .ml-md-auto, .mx-md-auto { margin-left: auto !important; } }
@media (min-width: 992px) { .m-lg-0 { margin: 0 !important; }
  .mt-lg-0, .my-lg-0 { margin-top: 0 !important; }
  .mr-lg-0, .mx-lg-0 { margin-right: 0 !important; }
  .mb-lg-0, .my-lg-0 { margin-bottom: 0 !important; }
  .ml-lg-0, .mx-lg-0 { margin-left: 0 !important; }
  .m-lg-1 { margin: 0.25rem !important; }
  .mt-lg-1, .my-lg-1 { margin-top: 0.25rem !important; }
  .mr-lg-1, .mx-lg-1 { margin-right: 0.25rem !important; }
  .mb-lg-1, .my-lg-1 { margin-bottom: 0.25rem !important; }
  .ml-lg-1, .mx-lg-1 { margin-left: 0.25rem !important; }
  .m-lg-2 { margin: 0.5rem !important; }
  .mt-lg-2, .my-lg-2 { margin-top: 0.5rem !important; }
  .mr-lg-2, .mx-lg-2 { margin-right: 0.5rem !important; }
  .mb-lg-2, .my-lg-2 { margin-bottom: 0.5rem !important; }
  .ml-lg-2, .mx-lg-2 { margin-left: 0.5rem !important; }
  .m-lg-3 { margin: 1rem !important; }
  .mt-lg-3, .my-lg-3 { margin-top: 1rem !important; }
  .mr-lg-3, .mx-lg-3 { margin-right: 1rem !important; }
  .mb-lg-3, .my-lg-3 { margin-bottom: 1rem !important; }
  .ml-lg-3, .mx-lg-3 { margin-left: 1rem !important; }
  .m-lg-4 { margin: 1.5rem !important; }
  .mt-lg-4, .my-lg-4 { margin-top: 1.5rem !important; }
  .mr-lg-4, .mx-lg-4 { margin-right: 1.5rem !important; }
  .mb-lg-4, .my-lg-4 { margin-bottom: 1.5rem !important; }
  .ml-lg-4, .mx-lg-4 { margin-left: 1.5rem !important; }
  .m-lg-5 { margin: 3rem !important; }
  .mt-lg-5, .my-lg-5 { margin-top: 3rem !important; }
  .mr-lg-5, .mx-lg-5 { margin-right: 3rem !important; }
  .mb-lg-5, .my-lg-5 { margin-bottom: 3rem !important; }
  .ml-lg-5, .mx-lg-5 { margin-left: 3rem !important; }
  .p-lg-0 { padding: 0 !important; }
  .pt-lg-0, .py-lg-0 { padding-top: 0 !important; }
  .pr-lg-0, .px-lg-0 { padding-right: 0 !important; }
  .pb-lg-0, .py-lg-0 { padding-bottom: 0 !important; }
  .pl-lg-0, .px-lg-0 { padding-left: 0 !important; }
  .p-lg-1 { padding: 0.25rem !important; }
  .pt-lg-1, .py-lg-1 { padding-top: 0.25rem !important; }
  .pr-lg-1, .px-lg-1 { padding-right: 0.25rem !important; }
  .pb-lg-1, .py-lg-1 { padding-bottom: 0.25rem !important; }
  .pl-lg-1, .px-lg-1 { padding-left: 0.25rem !important; }
  .p-lg-2 { padding: 0.5rem !important; }
  .pt-lg-2, .py-lg-2 { padding-top: 0.5rem !important; }
  .pr-lg-2, .px-lg-2 { padding-right: 0.5rem !important; }
  .pb-lg-2, .py-lg-2 { padding-bottom: 0.5rem !important; }
  .pl-lg-2, .px-lg-2 { padding-left: 0.5rem !important; }
  .p-lg-3 { padding: 1rem !important; }
  .pt-lg-3, .py-lg-3 { padding-top: 1rem !important; }
  .pr-lg-3, .px-lg-3 { padding-right: 1rem !important; }
  .pb-lg-3, .py-lg-3 { padding-bottom: 1rem !important; }
  .pl-lg-3, .px-lg-3 { padding-left: 1rem !important; }
  .p-lg-4 { padding: 1.5rem !important; }
  .pt-lg-4, .py-lg-4 { padding-top: 1.5rem !important; }
  .pr-lg-4, .px-lg-4 { padding-right: 1.5rem !important; }
  .pb-lg-4, .py-lg-4 { padding-bottom: 1.5rem !important; }
  .pl-lg-4, .px-lg-4 { padding-left: 1.5rem !important; }
  .p-lg-5 { padding: 3rem !important; }
  .pt-lg-5, .py-lg-5 { padding-top: 3rem !important; }
  .pr-lg-5, .px-lg-5 { padding-right: 3rem !important; }
  .pb-lg-5, .py-lg-5 { padding-bottom: 3rem !important; }
  .pl-lg-5, .px-lg-5 { padding-left: 3rem !important; }
  .m-lg-auto { margin: auto !important; }
  .mt-lg-auto, .my-lg-auto { margin-top: auto !important; }
  .mr-lg-auto, .mx-lg-auto { margin-right: auto !important; }
  .mb-lg-auto, .my-lg-auto { margin-bottom: auto !important; }
  .ml-lg-auto, .mx-lg-auto { margin-left: auto !important; } }
@media (min-width: 1200px) { .m-xl-0 { margin: 0 !important; }
  .mt-xl-0, .my-xl-0 { margin-top: 0 !important; }
  .mr-xl-0, .mx-xl-0 { margin-right: 0 !important; }
  .mb-xl-0, .my-xl-0 { margin-bottom: 0 !important; }
  .ml-xl-0, .mx-xl-0 { margin-left: 0 !important; }
  .m-xl-1 { margin: 0.25rem !important; }
  .mt-xl-1, .my-xl-1 { margin-top: 0.25rem !important; }
  .mr-xl-1, .mx-xl-1 { margin-right: 0.25rem !important; }
  .mb-xl-1, .my-xl-1 { margin-bottom: 0.25rem !important; }
  .ml-xl-1, .mx-xl-1 { margin-left: 0.25rem !important; }
  .m-xl-2 { margin: 0.5rem !important; }
  .mt-xl-2, .my-xl-2 { margin-top: 0.5rem !important; }
  .mr-xl-2, .mx-xl-2 { margin-right: 0.5rem !important; }
  .mb-xl-2, .my-xl-2 { margin-bottom: 0.5rem !important; }
  .ml-xl-2, .mx-xl-2 { margin-left: 0.5rem !important; }
  .m-xl-3 { margin: 1rem !important; }
  .mt-xl-3, .my-xl-3 { margin-top: 1rem !important; }
  .mr-xl-3, .mx-xl-3 { margin-right: 1rem !important; }
  .mb-xl-3, .my-xl-3 { margin-bottom: 1rem !important; }
  .ml-xl-3, .mx-xl-3 { margin-left: 1rem !important; }
  .m-xl-4 { margin: 1.5rem !important; }
  .mt-xl-4, .my-xl-4 { margin-top: 1.5rem !important; }
  .mr-xl-4, .mx-xl-4 { margin-right: 1.5rem !important; }
  .mb-xl-4, .my-xl-4 { margin-bottom: 1.5rem !important; }
  .ml-xl-4, .mx-xl-4 { margin-left: 1.5rem !important; }
  .m-xl-5 { margin: 3rem !important; }
  .mt-xl-5, .my-xl-5 { margin-top: 3rem !important; }
  .mr-xl-5, .mx-xl-5 { margin-right: 3rem !important; }
  .mb-xl-5, .my-xl-5 { margin-bottom: 3rem !important; }
  .ml-xl-5, .mx-xl-5 { margin-left: 3rem !important; }
  .p-xl-0 { padding: 0 !important; }
  .pt-xl-0, .py-xl-0 { padding-top: 0 !important; }
  .pr-xl-0, .px-xl-0 { padding-right: 0 !important; }
  .pb-xl-0, .py-xl-0 { padding-bottom: 0 !important; }
  .pl-xl-0, .px-xl-0 { padding-left: 0 !important; }
  .p-xl-1 { padding: 0.25rem !important; }
  .pt-xl-1, .py-xl-1 { padding-top: 0.25rem !important; }
  .pr-xl-1, .px-xl-1 { padding-right: 0.25rem !important; }
  .pb-xl-1, .py-xl-1 { padding-bottom: 0.25rem !important; }
  .pl-xl-1, .px-xl-1 { padding-left: 0.25rem !important; }
  .p-xl-2 { padding: 0.5rem !important; }
  .pt-xl-2, .py-xl-2 { padding-top: 0.5rem !important; }
  .pr-xl-2, .px-xl-2 { padding-right: 0.5rem !important; }
  .pb-xl-2, .py-xl-2 { padding-bottom: 0.5rem !important; }
  .pl-xl-2, .px-xl-2 { padding-left: 0.5rem !important; }
  .p-xl-3 { padding: 1rem !important; }
  .pt-xl-3, .py-xl-3 { padding-top: 1rem !important; }
  .pr-xl-3, .px-xl-3 { padding-right: 1rem !important; }
  .pb-xl-3, .py-xl-3 { padding-bottom: 1rem !important; }
  .pl-xl-3, .px-xl-3 { padding-left: 1rem !important; }
  .p-xl-4 { padding: 1.5rem !important; }
  .pt-xl-4, .py-xl-4 { padding-top: 1.5rem !important; }
  .pr-xl-4, .px-xl-4 { padding-right: 1.5rem !important; }
  .pb-xl-4, .py-xl-4 { padding-bottom: 1.5rem !important; }
  .pl-xl-4, .px-xl-4 { padding-left: 1.5rem !important; }
  .p-xl-5 { padding: 3rem !important; }
  .pt-xl-5, .py-xl-5 { padding-top: 3rem !important; }
  .pr-xl-5, .px-xl-5 { padding-right: 3rem !important; }
  .pb-xl-5, .py-xl-5 { padding-bottom: 3rem !important; }
  .pl-xl-5, .px-xl-5 { padding-left: 3rem !important; }
  .m-xl-auto { margin: auto !important; }
  .mt-xl-auto, .my-xl-auto { margin-top: auto !important; }
  .mr-xl-auto, .mx-xl-auto { margin-right: auto !important; }
  .mb-xl-auto, .my-xl-auto { margin-bottom: auto !important; }
  .ml-xl-auto, .mx-xl-auto { margin-left: auto !important; } }
.text-justify { text-align: justify !important; }

.text-nowrap { white-space: nowrap !important; }

.text-truncate { overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }

.text-left { text-align: left !important; }

.text-right { text-align: right !important; }

.text-center { text-align: center !important; }

@media (min-width: 576px) { .text-sm-left { text-align: left !important; }
  .text-sm-right { text-align: right !important; }
  .text-sm-center { text-align: center !important; } }
@media (min-width: 768px) { .text-md-left { text-align: left !important; }
  .text-md-right { text-align: right !important; }
  .text-md-center { text-align: center !important; } }
@media (min-width: 992px) { .text-lg-left { text-align: left !important; }
  .text-lg-right { text-align: right !important; }
  .text-lg-center { text-align: center !important; } }
@media (min-width: 1200px) { .text-xl-left { text-align: left !important; }
  .text-xl-right { text-align: right !important; }
  .text-xl-center { text-align: center !important; } }
.text-lowercase { text-transform: lowercase !important; }

.text-uppercase { text-transform: uppercase !important; }

.text-capitalize { text-transform: capitalize !important; }

.font-weight-light { font-weight: 300 !important; }

.font-weight-normal { font-weight: 400 !important; }

.font-weight-bold { font-weight: 700 !important; }

.font-italic { font-style: italic !important; }

.text-white { color: #fff !important; }

.text-primary { color: #ff1b00 !important; }

a.text-primary:hover, a.text-primary:focus { color: #cc1600 !important; }

.text-secondary { color: #fec200 !important; }

a.text-secondary:hover, a.text-secondary:focus { color: #cb9b00 !important; }

.text-success { color: #34e16d !important; }

a.text-success:hover, a.text-success:focus { color: #1dc554 !important; }

.text-info { color: #00bbce !important; }

a.text-info:hover, a.text-info:focus { color: #008d9b !important; }

.text-warning { color: #fec200 !important; }

a.text-warning:hover, a.text-warning:focus { color: #cb9b00 !important; }

.text-danger { color: #ff1b00 !important; }

a.text-danger:hover, a.text-danger:focus { color: #cc1600 !important; }

.text-light { color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus { color: #dae0e5 !important; }

.text-dark { color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus { color: #1d2124 !important; }

.text-muted { color: #868e96 !important; }

.text-hide { font: 0/0 a; color: transparent; text-shadow: none; background-color: transparent; border: 0; }

.visible { visibility: visible !important; }

.invisible { visibility: hidden !important; }

@media print { *, *::before, *::after { text-shadow: none !important; box-shadow: none !important; }
  a:not(.btn) { text-decoration: underline; }
  abbr[title]::after { content: " (" attr(title) ")"; }
  pre { white-space: pre-wrap !important; }
  pre, blockquote { border: 1px solid #999; page-break-inside: avoid; }
  thead { display: table-header-group; }
  tr, img { page-break-inside: avoid; }
  p, h2, h3 { orphans: 3; widows: 3; }
  h2, h3 { page-break-after: avoid; }
  @page { size: a3; }
  body { min-width: 992px !important; }
  .container { min-width: 992px !important; }
  .navbar { display: none; }
  .badge { border: 1px solid #000; }
  .table { border-collapse: collapse !important; }
  .table td, .table th { background-color: #fff !important; }
  .table-bordered th, .table-bordered td { border: 1px solid #ddd !important; } }
/*!
 * Utilities
 */
.align-baseline { vertical-align: baseline !important; }

.align-top { vertical-align: top !important; }

.align-middle { vertical-align: middle !important; }

.align-bottom { vertical-align: bottom !important; }

.align-text-bottom { vertical-align: text-bottom !important; }

.align-text-top { vertical-align: text-top !important; }

.bg-primary { background-color: #ff1b00 !important; }

a.bg-primary:hover, a.bg-primary:focus, button.bg-primary:hover, button.bg-primary:focus { background-color: #cc1600 !important; }

.bg-secondary { background-color: #fec200 !important; }

a.bg-secondary:hover, a.bg-secondary:focus, button.bg-secondary:hover, button.bg-secondary:focus { background-color: #cb9b00 !important; }

.bg-success { background-color: #34e16d !important; }

a.bg-success:hover, a.bg-success:focus, button.bg-success:hover, button.bg-success:focus { background-color: #1dc554 !important; }

.bg-info { background-color: #00bbce !important; }

a.bg-info:hover, a.bg-info:focus, button.bg-info:hover, button.bg-info:focus { background-color: #008d9b !important; }

.bg-warning { background-color: #fec200 !important; }

a.bg-warning:hover, a.bg-warning:focus, button.bg-warning:hover, button.bg-warning:focus { background-color: #cb9b00 !important; }

.bg-danger { background-color: #ff1b00 !important; }

a.bg-danger:hover, a.bg-danger:focus, button.bg-danger:hover, button.bg-danger:focus { background-color: #cc1600 !important; }

.bg-light { background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus, button.bg-light:hover, button.bg-light:focus { background-color: #dae0e5 !important; }

.bg-dark { background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus, button.bg-dark:hover, button.bg-dark:focus { background-color: #1d2124 !important; }

.bg-white { background-color: #fff !important; }

.bg-transparent { background-color: transparent !important; }

.border { border: 1px solid #dee2e6 !important; }

.border-top { border-top: 1px solid #dee2e6 !important; }

.border-right { border-right: 1px solid #dee2e6 !important; }

.border-bottom { border-bottom: 1px solid #dee2e6 !important; }

.border-left { border-left: 1px solid #dee2e6 !important; }

.border-0 { border: 0 !important; }

.border-top-0 { border-top: 0 !important; }

.border-right-0 { border-right: 0 !important; }

.border-bottom-0 { border-bottom: 0 !important; }

.border-left-0 { border-left: 0 !important; }

.border-primary { border-color: #ff1b00 !important; }

.border-secondary { border-color: #fec200 !important; }

.border-success { border-color: #34e16d !important; }

.border-info { border-color: #00bbce !important; }

.border-warning { border-color: #fec200 !important; }

.border-danger { border-color: #ff1b00 !important; }

.border-light { border-color: #f8f9fa !important; }

.border-dark { border-color: #343a40 !important; }

.border-white { border-color: #fff !important; }

.rounded { border-radius: 0 !important; }

.rounded-top { border-top-left-radius: 0 !important; border-top-right-radius: 0 !important; }

.rounded-right { border-top-right-radius: 0 !important; border-bottom-right-radius: 0 !important; }

.rounded-bottom { border-bottom-right-radius: 0 !important; border-bottom-left-radius: 0 !important; }

.rounded-left { border-top-left-radius: 0 !important; border-bottom-left-radius: 0 !important; }

.rounded-circle, .elements-promotion-introduction figure img { border-radius: 50% !important; }

.rounded-0 { border-radius: 0 !important; }

.clearfix::after { display: block; clear: both; content: ""; }

.d-none { display: none !important; }

.d-inline { display: inline !important; }

.d-inline-block { display: inline-block !important; }

.d-block { display: block !important; }

.d-table { display: table !important; }

.d-table-row { display: table-row !important; }

.d-table-cell { display: table-cell !important; }

.d-flex { display: flex !important; }

.d-inline-flex { display: inline-flex !important; }

@media (min-width: 576px) { .d-sm-none { display: none !important; }
  .d-sm-inline { display: inline !important; }
  .d-sm-inline-block { display: inline-block !important; }
  .d-sm-block { display: block !important; }
  .d-sm-table { display: table !important; }
  .d-sm-table-row { display: table-row !important; }
  .d-sm-table-cell { display: table-cell !important; }
  .d-sm-flex { display: flex !important; }
  .d-sm-inline-flex { display: inline-flex !important; } }
@media (min-width: 768px) { .d-md-none { display: none !important; }
  .d-md-inline { display: inline !important; }
  .d-md-inline-block { display: inline-block !important; }
  .d-md-block { display: block !important; }
  .d-md-table { display: table !important; }
  .d-md-table-row { display: table-row !important; }
  .d-md-table-cell { display: table-cell !important; }
  .d-md-flex { display: flex !important; }
  .d-md-inline-flex { display: inline-flex !important; } }
@media (min-width: 992px) { .d-lg-none { display: none !important; }
  .d-lg-inline { display: inline !important; }
  .d-lg-inline-block { display: inline-block !important; }
  .d-lg-block { display: block !important; }
  .d-lg-table { display: table !important; }
  .d-lg-table-row { display: table-row !important; }
  .d-lg-table-cell { display: table-cell !important; }
  .d-lg-flex { display: flex !important; }
  .d-lg-inline-flex { display: inline-flex !important; } }
@media (min-width: 1200px) { .d-xl-none { display: none !important; }
  .d-xl-inline { display: inline !important; }
  .d-xl-inline-block { display: inline-block !important; }
  .d-xl-block { display: block !important; }
  .d-xl-table { display: table !important; }
  .d-xl-table-row { display: table-row !important; }
  .d-xl-table-cell { display: table-cell !important; }
  .d-xl-flex { display: flex !important; }
  .d-xl-inline-flex { display: inline-flex !important; } }
@media print { .d-print-none { display: none !important; }
  .d-print-inline { display: inline !important; }
  .d-print-inline-block { display: inline-block !important; }
  .d-print-block { display: block !important; }
  .d-print-table { display: table !important; }
  .d-print-table-row { display: table-row !important; }
  .d-print-table-cell { display: table-cell !important; }
  .d-print-flex { display: flex !important; }
  .d-print-inline-flex { display: inline-flex !important; } }
.embed-responsive { position: relative; display: block; width: 100%; padding: 0; overflow: hidden; }
.embed-responsive::before { display: block; content: ""; }
.embed-responsive .embed-responsive-item, .embed-responsive iframe, .embed-responsive embed, .embed-responsive object, .embed-responsive video { position: absolute; top: 0; bottom: 0; left: 0; width: 100%; height: 100%; border: 0; }

.embed-responsive-21by9::before { padding-top: 42.8571428571%; }

.embed-responsive-16by9::before { padding-top: 56.25%; }

.embed-responsive-4by3::before { padding-top: 75%; }

.embed-responsive-1by1::before { padding-top: 100%; }

.flex-row { flex-direction: row !important; }

.flex-column { flex-direction: column !important; }

.flex-row-reverse { flex-direction: row-reverse !important; }

.flex-column-reverse { flex-direction: column-reverse !important; }

.flex-wrap { flex-wrap: wrap !important; }

.flex-nowrap { flex-wrap: nowrap !important; }

.flex-wrap-reverse { flex-wrap: wrap-reverse !important; }

.justify-content-start { justify-content: flex-start !important; }

.justify-content-end { justify-content: flex-end !important; }

.justify-content-center { justify-content: center !important; }

.justify-content-between { justify-content: space-between !important; }

.justify-content-around { justify-content: space-around !important; }

.align-items-start { align-items: flex-start !important; }

.align-items-end { align-items: flex-end !important; }

.align-items-center { align-items: center !important; }

.align-items-baseline { align-items: baseline !important; }

.align-items-stretch { align-items: stretch !important; }

.align-content-start { align-content: flex-start !important; }

.align-content-end { align-content: flex-end !important; }

.align-content-center { align-content: center !important; }

.align-content-between { align-content: space-between !important; }

.align-content-around { align-content: space-around !important; }

.align-content-stretch { align-content: stretch !important; }

.align-self-auto { align-self: auto !important; }

.align-self-start { align-self: flex-start !important; }

.align-self-end { align-self: flex-end !important; }

.align-self-center { align-self: center !important; }

.align-self-baseline { align-self: baseline !important; }

.align-self-stretch { align-self: stretch !important; }

@media (min-width: 576px) { .flex-sm-row { flex-direction: row !important; }
  .flex-sm-column { flex-direction: column !important; }
  .flex-sm-row-reverse { flex-direction: row-reverse !important; }
  .flex-sm-column-reverse { flex-direction: column-reverse !important; }
  .flex-sm-wrap { flex-wrap: wrap !important; }
  .flex-sm-nowrap { flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse { flex-wrap: wrap-reverse !important; }
  .justify-content-sm-start { justify-content: flex-start !important; }
  .justify-content-sm-end { justify-content: flex-end !important; }
  .justify-content-sm-center { justify-content: center !important; }
  .justify-content-sm-between { justify-content: space-between !important; }
  .justify-content-sm-around { justify-content: space-around !important; }
  .align-items-sm-start { align-items: flex-start !important; }
  .align-items-sm-end { align-items: flex-end !important; }
  .align-items-sm-center { align-items: center !important; }
  .align-items-sm-baseline { align-items: baseline !important; }
  .align-items-sm-stretch { align-items: stretch !important; }
  .align-content-sm-start { align-content: flex-start !important; }
  .align-content-sm-end { align-content: flex-end !important; }
  .align-content-sm-center { align-content: center !important; }
  .align-content-sm-between { align-content: space-between !important; }
  .align-content-sm-around { align-content: space-around !important; }
  .align-content-sm-stretch { align-content: stretch !important; }
  .align-self-sm-auto { align-self: auto !important; }
  .align-self-sm-start { align-self: flex-start !important; }
  .align-self-sm-end { align-self: flex-end !important; }
  .align-self-sm-center { align-self: center !important; }
  .align-self-sm-baseline { align-self: baseline !important; }
  .align-self-sm-stretch { align-self: stretch !important; } }
@media (min-width: 768px) { .flex-md-row { flex-direction: row !important; }
  .flex-md-column { flex-direction: column !important; }
  .flex-md-row-reverse { flex-direction: row-reverse !important; }
  .flex-md-column-reverse { flex-direction: column-reverse !important; }
  .flex-md-wrap { flex-wrap: wrap !important; }
  .flex-md-nowrap { flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse { flex-wrap: wrap-reverse !important; }
  .justify-content-md-start { justify-content: flex-start !important; }
  .justify-content-md-end { justify-content: flex-end !important; }
  .justify-content-md-center { justify-content: center !important; }
  .justify-content-md-between { justify-content: space-between !important; }
  .justify-content-md-around { justify-content: space-around !important; }
  .align-items-md-start { align-items: flex-start !important; }
  .align-items-md-end { align-items: flex-end !important; }
  .align-items-md-center { align-items: center !important; }
  .align-items-md-baseline { align-items: baseline !important; }
  .align-items-md-stretch { align-items: stretch !important; }
  .align-content-md-start { align-content: flex-start !important; }
  .align-content-md-end { align-content: flex-end !important; }
  .align-content-md-center { align-content: center !important; }
  .align-content-md-between { align-content: space-between !important; }
  .align-content-md-around { align-content: space-around !important; }
  .align-content-md-stretch { align-content: stretch !important; }
  .align-self-md-auto { align-self: auto !important; }
  .align-self-md-start { align-self: flex-start !important; }
  .align-self-md-end { align-self: flex-end !important; }
  .align-self-md-center { align-self: center !important; }
  .align-self-md-baseline { align-self: baseline !important; }
  .align-self-md-stretch { align-self: stretch !important; } }
@media (min-width: 992px) { .flex-lg-row { flex-direction: row !important; }
  .flex-lg-column { flex-direction: column !important; }
  .flex-lg-row-reverse { flex-direction: row-reverse !important; }
  .flex-lg-column-reverse { flex-direction: column-reverse !important; }
  .flex-lg-wrap { flex-wrap: wrap !important; }
  .flex-lg-nowrap { flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse { flex-wrap: wrap-reverse !important; }
  .justify-content-lg-start { justify-content: flex-start !important; }
  .justify-content-lg-end { justify-content: flex-end !important; }
  .justify-content-lg-center { justify-content: center !important; }
  .justify-content-lg-between { justify-content: space-between !important; }
  .justify-content-lg-around { justify-content: space-around !important; }
  .align-items-lg-start { align-items: flex-start !important; }
  .align-items-lg-end { align-items: flex-end !important; }
  .align-items-lg-center { align-items: center !important; }
  .align-items-lg-baseline { align-items: baseline !important; }
  .align-items-lg-stretch { align-items: stretch !important; }
  .align-content-lg-start { align-content: flex-start !important; }
  .align-content-lg-end { align-content: flex-end !important; }
  .align-content-lg-center { align-content: center !important; }
  .align-content-lg-between { align-content: space-between !important; }
  .align-content-lg-around { align-content: space-around !important; }
  .align-content-lg-stretch { align-content: stretch !important; }
  .align-self-lg-auto { align-self: auto !important; }
  .align-self-lg-start { align-self: flex-start !important; }
  .align-self-lg-end { align-self: flex-end !important; }
  .align-self-lg-center { align-self: center !important; }
  .align-self-lg-baseline { align-self: baseline !important; }
  .align-self-lg-stretch { align-self: stretch !important; } }
@media (min-width: 1200px) { .flex-xl-row { flex-direction: row !important; }
  .flex-xl-column { flex-direction: column !important; }
  .flex-xl-row-reverse { flex-direction: row-reverse !important; }
  .flex-xl-column-reverse { flex-direction: column-reverse !important; }
  .flex-xl-wrap { flex-wrap: wrap !important; }
  .flex-xl-nowrap { flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse { flex-wrap: wrap-reverse !important; }
  .justify-content-xl-start { justify-content: flex-start !important; }
  .justify-content-xl-end { justify-content: flex-end !important; }
  .justify-content-xl-center { justify-content: center !important; }
  .justify-content-xl-between { justify-content: space-between !important; }
  .justify-content-xl-around { justify-content: space-around !important; }
  .align-items-xl-start { align-items: flex-start !important; }
  .align-items-xl-end { align-items: flex-end !important; }
  .align-items-xl-center { align-items: center !important; }
  .align-items-xl-baseline { align-items: baseline !important; }
  .align-items-xl-stretch { align-items: stretch !important; }
  .align-content-xl-start { align-content: flex-start !important; }
  .align-content-xl-end { align-content: flex-end !important; }
  .align-content-xl-center { align-content: center !important; }
  .align-content-xl-between { align-content: space-between !important; }
  .align-content-xl-around { align-content: space-around !important; }
  .align-content-xl-stretch { align-content: stretch !important; }
  .align-self-xl-auto { align-self: auto !important; }
  .align-self-xl-start { align-self: flex-start !important; }
  .align-self-xl-end { align-self: flex-end !important; }
  .align-self-xl-center { align-self: center !important; }
  .align-self-xl-baseline { align-self: baseline !important; }
  .align-self-xl-stretch { align-self: stretch !important; } }
.float-left { float: left !important; }

.float-right { float: right !important; }

.float-none { float: none !important; }

@media (min-width: 576px) { .float-sm-left { float: left !important; }
  .float-sm-right { float: right !important; }
  .float-sm-none { float: none !important; } }
@media (min-width: 768px) { .float-md-left { float: left !important; }
  .float-md-right { float: right !important; }
  .float-md-none { float: none !important; } }
@media (min-width: 992px) { .float-lg-left { float: left !important; }
  .float-lg-right { float: right !important; }
  .float-lg-none { float: none !important; } }
@media (min-width: 1200px) { .float-xl-left { float: left !important; }
  .float-xl-right { float: right !important; }
  .float-xl-none { float: none !important; } }
.position-static { position: static !important; }

.position-relative { position: relative !important; }

.position-absolute { position: absolute !important; }

.position-fixed { position: fixed !important; }

.position-sticky { position: sticky !important; }

.fixed-top { position: fixed; top: 0; right: 0; left: 0; z-index: 1030; }

.fixed-bottom { position: fixed; right: 0; bottom: 0; left: 0; z-index: 1030; }

@supports (position: sticky) { .sticky-top { position: sticky; top: 0; z-index: 1020; } }

.sr-only { position: absolute; width: 1px; height: 1px; padding: 0; overflow: hidden; clip: rect(0, 0, 0, 0); white-space: nowrap; clip-path: inset(50%); border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus { position: static; width: auto; height: auto; overflow: visible; clip: auto; white-space: normal; clip-path: none; }

.w-25 { width: 25% !important; }

.w-50 { width: 50% !important; }

.w-75 { width: 75% !important; }

.w-100 { width: 100% !important; }

.h-25 { height: 25% !important; }

.h-50 { height: 50% !important; }

.h-75 { height: 75% !important; }

.h-100 { height: 100% !important; }

.mw-100 { max-width: 100% !important; }

.mh-100 { max-height: 100% !important; }

.m-0 { margin: 0 !important; }

.mt-0, .my-0 { margin-top: 0 !important; }

.mr-0, .mx-0 { margin-right: 0 !important; }

.mb-0, .my-0 { margin-bottom: 0 !important; }

.ml-0, .mx-0 { margin-left: 0 !important; }

.m-1 { margin: 0.25rem !important; }

.mt-1, .my-1 { margin-top: 0.25rem !important; }

.mr-1, .mx-1 { margin-right: 0.25rem !important; }

.mb-1, .my-1 { margin-bottom: 0.25rem !important; }

.ml-1, .mx-1 { margin-left: 0.25rem !important; }

.m-2 { margin: 0.5rem !important; }

.mt-2, .my-2 { margin-top: 0.5rem !important; }

.mr-2, .mx-2 { margin-right: 0.5rem !important; }

.mb-2, .my-2 { margin-bottom: 0.5rem !important; }

.ml-2, .mx-2 { margin-left: 0.5rem !important; }

.m-3 { margin: 1rem !important; }

.mt-3, .my-3 { margin-top: 1rem !important; }

.mr-3, .mx-3 { margin-right: 1rem !important; }

.mb-3, .my-3 { margin-bottom: 1rem !important; }

.ml-3, .mx-3 { margin-left: 1rem !important; }

.m-4 { margin: 1.5rem !important; }

.mt-4, .my-4 { margin-top: 1.5rem !important; }

.mr-4, .mx-4 { margin-right: 1.5rem !important; }

.mb-4, .my-4 { margin-bottom: 1.5rem !important; }

.ml-4, .mx-4 { margin-left: 1.5rem !important; }

.m-5 { margin: 3rem !important; }

.mt-5, .my-5 { margin-top: 3rem !important; }

.mr-5, .mx-5 { margin-right: 3rem !important; }

.mb-5, .my-5 { margin-bottom: 3rem !important; }

.ml-5, .mx-5 { margin-left: 3rem !important; }

.p-0 { padding: 0 !important; }

.pt-0, .py-0 { padding-top: 0 !important; }

.pr-0, .px-0 { padding-right: 0 !important; }

.pb-0, .py-0 { padding-bottom: 0 !important; }

.pl-0, .px-0 { padding-left: 0 !important; }

.p-1 { padding: 0.25rem !important; }

.pt-1, .py-1 { padding-top: 0.25rem !important; }

.pr-1, .px-1 { padding-right: 0.25rem !important; }

.pb-1, .py-1 { padding-bottom: 0.25rem !important; }

.pl-1, .px-1 { padding-left: 0.25rem !important; }

.p-2 { padding: 0.5rem !important; }

.pt-2, .py-2 { padding-top: 0.5rem !important; }

.pr-2, .px-2 { padding-right: 0.5rem !important; }

.pb-2, .py-2 { padding-bottom: 0.5rem !important; }

.pl-2, .px-2 { padding-left: 0.5rem !important; }

.p-3 { padding: 1rem !important; }

.pt-3, .py-3 { padding-top: 1rem !important; }

.pr-3, .px-3 { padding-right: 1rem !important; }

.pb-3, .py-3 { padding-bottom: 1rem !important; }

.pl-3, .px-3 { padding-left: 1rem !important; }

.p-4 { padding: 1.5rem !important; }

.pt-4, .py-4 { padding-top: 1.5rem !important; }

.pr-4, .px-4 { padding-right: 1.5rem !important; }

.pb-4, .py-4 { padding-bottom: 1.5rem !important; }

.pl-4, .px-4 { padding-left: 1.5rem !important; }

.p-5 { padding: 3rem !important; }

.pt-5, .py-5 { padding-top: 3rem !important; }

.pr-5, .px-5 { padding-right: 3rem !important; }

.pb-5, .py-5 { padding-bottom: 3rem !important; }

.pl-5, .px-5 { padding-left: 3rem !important; }

.m-auto { margin: auto !important; }

.mt-auto, .my-auto { margin-top: auto !important; }

.mr-auto, .mx-auto { margin-right: auto !important; }

.mb-auto, .my-auto { margin-bottom: auto !important; }

.ml-auto, .mx-auto { margin-left: auto !important; }

@media (min-width: 576px) { .m-sm-0 { margin: 0 !important; }
  .mt-sm-0, .my-sm-0 { margin-top: 0 !important; }
  .mr-sm-0, .mx-sm-0 { margin-right: 0 !important; }
  .mb-sm-0, .my-sm-0 { margin-bottom: 0 !important; }
  .ml-sm-0, .mx-sm-0 { margin-left: 0 !important; }
  .m-sm-1 { margin: 0.25rem !important; }
  .mt-sm-1, .my-sm-1 { margin-top: 0.25rem !important; }
  .mr-sm-1, .mx-sm-1 { margin-right: 0.25rem !important; }
  .mb-sm-1, .my-sm-1 { margin-bottom: 0.25rem !important; }
  .ml-sm-1, .mx-sm-1 { margin-left: 0.25rem !important; }
  .m-sm-2 { margin: 0.5rem !important; }
  .mt-sm-2, .my-sm-2 { margin-top: 0.5rem !important; }
  .mr-sm-2, .mx-sm-2 { margin-right: 0.5rem !important; }
  .mb-sm-2, .my-sm-2 { margin-bottom: 0.5rem !important; }
  .ml-sm-2, .mx-sm-2 { margin-left: 0.5rem !important; }
  .m-sm-3 { margin: 1rem !important; }
  .mt-sm-3, .my-sm-3 { margin-top: 1rem !important; }
  .mr-sm-3, .mx-sm-3 { margin-right: 1rem !important; }
  .mb-sm-3, .my-sm-3 { margin-bottom: 1rem !important; }
  .ml-sm-3, .mx-sm-3 { margin-left: 1rem !important; }
  .m-sm-4 { margin: 1.5rem !important; }
  .mt-sm-4, .my-sm-4 { margin-top: 1.5rem !important; }
  .mr-sm-4, .mx-sm-4 { margin-right: 1.5rem !important; }
  .mb-sm-4, .my-sm-4 { margin-bottom: 1.5rem !important; }
  .ml-sm-4, .mx-sm-4 { margin-left: 1.5rem !important; }
  .m-sm-5 { margin: 3rem !important; }
  .mt-sm-5, .my-sm-5 { margin-top: 3rem !important; }
  .mr-sm-5, .mx-sm-5 { margin-right: 3rem !important; }
  .mb-sm-5, .my-sm-5 { margin-bottom: 3rem !important; }
  .ml-sm-5, .mx-sm-5 { margin-left: 3rem !important; }
  .p-sm-0 { padding: 0 !important; }
  .pt-sm-0, .py-sm-0 { padding-top: 0 !important; }
  .pr-sm-0, .px-sm-0 { padding-right: 0 !important; }
  .pb-sm-0, .py-sm-0 { padding-bottom: 0 !important; }
  .pl-sm-0, .px-sm-0 { padding-left: 0 !important; }
  .p-sm-1 { padding: 0.25rem !important; }
  .pt-sm-1, .py-sm-1 { padding-top: 0.25rem !important; }
  .pr-sm-1, .px-sm-1 { padding-right: 0.25rem !important; }
  .pb-sm-1, .py-sm-1 { padding-bottom: 0.25rem !important; }
  .pl-sm-1, .px-sm-1 { padding-left: 0.25rem !important; }
  .p-sm-2 { padding: 0.5rem !important; }
  .pt-sm-2, .py-sm-2 { padding-top: 0.5rem !important; }
  .pr-sm-2, .px-sm-2 { padding-right: 0.5rem !important; }
  .pb-sm-2, .py-sm-2 { padding-bottom: 0.5rem !important; }
  .pl-sm-2, .px-sm-2 { padding-left: 0.5rem !important; }
  .p-sm-3 { padding: 1rem !important; }
  .pt-sm-3, .py-sm-3 { padding-top: 1rem !important; }
  .pr-sm-3, .px-sm-3 { padding-right: 1rem !important; }
  .pb-sm-3, .py-sm-3 { padding-bottom: 1rem !important; }
  .pl-sm-3, .px-sm-3 { padding-left: 1rem !important; }
  .p-sm-4 { padding: 1.5rem !important; }
  .pt-sm-4, .py-sm-4 { padding-top: 1.5rem !important; }
  .pr-sm-4, .px-sm-4 { padding-right: 1.5rem !important; }
  .pb-sm-4, .py-sm-4 { padding-bottom: 1.5rem !important; }
  .pl-sm-4, .px-sm-4 { padding-left: 1.5rem !important; }
  .p-sm-5 { padding: 3rem !important; }
  .pt-sm-5, .py-sm-5 { padding-top: 3rem !important; }
  .pr-sm-5, .px-sm-5 { padding-right: 3rem !important; }
  .pb-sm-5, .py-sm-5 { padding-bottom: 3rem !important; }
  .pl-sm-5, .px-sm-5 { padding-left: 3rem !important; }
  .m-sm-auto { margin: auto !important; }
  .mt-sm-auto, .my-sm-auto { margin-top: auto !important; }
  .mr-sm-auto, .mx-sm-auto { margin-right: auto !important; }
  .mb-sm-auto, .my-sm-auto { margin-bottom: auto !important; }
  .ml-sm-auto, .mx-sm-auto { margin-left: auto !important; } }
@media (min-width: 768px) { .m-md-0 { margin: 0 !important; }
  .mt-md-0, .my-md-0 { margin-top: 0 !important; }
  .mr-md-0, .mx-md-0 { margin-right: 0 !important; }
  .mb-md-0, .my-md-0 { margin-bottom: 0 !important; }
  .ml-md-0, .mx-md-0 { margin-left: 0 !important; }
  .m-md-1 { margin: 0.25rem !important; }
  .mt-md-1, .my-md-1 { margin-top: 0.25rem !important; }
  .mr-md-1, .mx-md-1 { margin-right: 0.25rem !important; }
  .mb-md-1, .my-md-1 { margin-bottom: 0.25rem !important; }
  .ml-md-1, .mx-md-1 { margin-left: 0.25rem !important; }
  .m-md-2 { margin: 0.5rem !important; }
  .mt-md-2, .my-md-2 { margin-top: 0.5rem !important; }
  .mr-md-2, .mx-md-2 { margin-right: 0.5rem !important; }
  .mb-md-2, .my-md-2 { margin-bottom: 0.5rem !important; }
  .ml-md-2, .mx-md-2 { margin-left: 0.5rem !important; }
  .m-md-3 { margin: 1rem !important; }
  .mt-md-3, .my-md-3 { margin-top: 1rem !important; }
  .mr-md-3, .mx-md-3 { margin-right: 1rem !important; }
  .mb-md-3, .my-md-3 { margin-bottom: 1rem !important; }
  .ml-md-3, .mx-md-3 { margin-left: 1rem !important; }
  .m-md-4 { margin: 1.5rem !important; }
  .mt-md-4, .my-md-4 { margin-top: 1.5rem !important; }
  .mr-md-4, .mx-md-4 { margin-right: 1.5rem !important; }
  .mb-md-4, .my-md-4 { margin-bottom: 1.5rem !important; }
  .ml-md-4, .mx-md-4 { margin-left: 1.5rem !important; }
  .m-md-5 { margin: 3rem !important; }
  .mt-md-5, .my-md-5 { margin-top: 3rem !important; }
  .mr-md-5, .mx-md-5 { margin-right: 3rem !important; }
  .mb-md-5, .my-md-5 { margin-bottom: 3rem !important; }
  .ml-md-5, .mx-md-5 { margin-left: 3rem !important; }
  .p-md-0 { padding: 0 !important; }
  .pt-md-0, .py-md-0 { padding-top: 0 !important; }
  .pr-md-0, .px-md-0 { padding-right: 0 !important; }
  .pb-md-0, .py-md-0 { padding-bottom: 0 !important; }
  .pl-md-0, .px-md-0 { padding-left: 0 !important; }
  .p-md-1 { padding: 0.25rem !important; }
  .pt-md-1, .py-md-1 { padding-top: 0.25rem !important; }
  .pr-md-1, .px-md-1 { padding-right: 0.25rem !important; }
  .pb-md-1, .py-md-1 { padding-bottom: 0.25rem !important; }
  .pl-md-1, .px-md-1 { padding-left: 0.25rem !important; }
  .p-md-2 { padding: 0.5rem !important; }
  .pt-md-2, .py-md-2 { padding-top: 0.5rem !important; }
  .pr-md-2, .px-md-2 { padding-right: 0.5rem !important; }
  .pb-md-2, .py-md-2 { padding-bottom: 0.5rem !important; }
  .pl-md-2, .px-md-2 { padding-left: 0.5rem !important; }
  .p-md-3 { padding: 1rem !important; }
  .pt-md-3, .py-md-3 { padding-top: 1rem !important; }
  .pr-md-3, .px-md-3 { padding-right: 1rem !important; }
  .pb-md-3, .py-md-3 { padding-bottom: 1rem !important; }
  .pl-md-3, .px-md-3 { padding-left: 1rem !important; }
  .p-md-4 { padding: 1.5rem !important; }
  .pt-md-4, .py-md-4 { padding-top: 1.5rem !important; }
  .pr-md-4, .px-md-4 { padding-right: 1.5rem !important; }
  .pb-md-4, .py-md-4 { padding-bottom: 1.5rem !important; }
  .pl-md-4, .px-md-4 { padding-left: 1.5rem !important; }
  .p-md-5 { padding: 3rem !important; }
  .pt-md-5, .py-md-5 { padding-top: 3rem !important; }
  .pr-md-5, .px-md-5 { padding-right: 3rem !important; }
  .pb-md-5, .py-md-5 { padding-bottom: 3rem !important; }
  .pl-md-5, .px-md-5 { padding-left: 3rem !important; }
  .m-md-auto { margin: auto !important; }
  .mt-md-auto, .my-md-auto { margin-top: auto !important; }
  .mr-md-auto, .mx-md-auto { margin-right: auto !important; }
  .mb-md-auto, .my-md-auto { margin-bottom: auto !important; }
  .ml-md-auto, .mx-md-auto { margin-left: auto !important; } }
@media (min-width: 992px) { .m-lg-0 { margin: 0 !important; }
  .mt-lg-0, .my-lg-0 { margin-top: 0 !important; }
  .mr-lg-0, .mx-lg-0 { margin-right: 0 !important; }
  .mb-lg-0, .my-lg-0 { margin-bottom: 0 !important; }
  .ml-lg-0, .mx-lg-0 { margin-left: 0 !important; }
  .m-lg-1 { margin: 0.25rem !important; }
  .mt-lg-1, .my-lg-1 { margin-top: 0.25rem !important; }
  .mr-lg-1, .mx-lg-1 { margin-right: 0.25rem !important; }
  .mb-lg-1, .my-lg-1 { margin-bottom: 0.25rem !important; }
  .ml-lg-1, .mx-lg-1 { margin-left: 0.25rem !important; }
  .m-lg-2 { margin: 0.5rem !important; }
  .mt-lg-2, .my-lg-2 { margin-top: 0.5rem !important; }
  .mr-lg-2, .mx-lg-2 { margin-right: 0.5rem !important; }
  .mb-lg-2, .my-lg-2 { margin-bottom: 0.5rem !important; }
  .ml-lg-2, .mx-lg-2 { margin-left: 0.5rem !important; }
  .m-lg-3 { margin: 1rem !important; }
  .mt-lg-3, .my-lg-3 { margin-top: 1rem !important; }
  .mr-lg-3, .mx-lg-3 { margin-right: 1rem !important; }
  .mb-lg-3, .my-lg-3 { margin-bottom: 1rem !important; }
  .ml-lg-3, .mx-lg-3 { margin-left: 1rem !important; }
  .m-lg-4 { margin: 1.5rem !important; }
  .mt-lg-4, .my-lg-4 { margin-top: 1.5rem !important; }
  .mr-lg-4, .mx-lg-4 { margin-right: 1.5rem !important; }
  .mb-lg-4, .my-lg-4 { margin-bottom: 1.5rem !important; }
  .ml-lg-4, .mx-lg-4 { margin-left: 1.5rem !important; }
  .m-lg-5 { margin: 3rem !important; }
  .mt-lg-5, .my-lg-5 { margin-top: 3rem !important; }
  .mr-lg-5, .mx-lg-5 { margin-right: 3rem !important; }
  .mb-lg-5, .my-lg-5 { margin-bottom: 3rem !important; }
  .ml-lg-5, .mx-lg-5 { margin-left: 3rem !important; }
  .p-lg-0 { padding: 0 !important; }
  .pt-lg-0, .py-lg-0 { padding-top: 0 !important; }
  .pr-lg-0, .px-lg-0 { padding-right: 0 !important; }
  .pb-lg-0, .py-lg-0 { padding-bottom: 0 !important; }
  .pl-lg-0, .px-lg-0 { padding-left: 0 !important; }
  .p-lg-1 { padding: 0.25rem !important; }
  .pt-lg-1, .py-lg-1 { padding-top: 0.25rem !important; }
  .pr-lg-1, .px-lg-1 { padding-right: 0.25rem !important; }
  .pb-lg-1, .py-lg-1 { padding-bottom: 0.25rem !important; }
  .pl-lg-1, .px-lg-1 { padding-left: 0.25rem !important; }
  .p-lg-2 { padding: 0.5rem !important; }
  .pt-lg-2, .py-lg-2 { padding-top: 0.5rem !important; }
  .pr-lg-2, .px-lg-2 { padding-right: 0.5rem !important; }
  .pb-lg-2, .py-lg-2 { padding-bottom: 0.5rem !important; }
  .pl-lg-2, .px-lg-2 { padding-left: 0.5rem !important; }
  .p-lg-3 { padding: 1rem !important; }
  .pt-lg-3, .py-lg-3 { padding-top: 1rem !important; }
  .pr-lg-3, .px-lg-3 { padding-right: 1rem !important; }
  .pb-lg-3, .py-lg-3 { padding-bottom: 1rem !important; }
  .pl-lg-3, .px-lg-3 { padding-left: 1rem !important; }
  .p-lg-4 { padding: 1.5rem !important; }
  .pt-lg-4, .py-lg-4 { padding-top: 1.5rem !important; }
  .pr-lg-4, .px-lg-4 { padding-right: 1.5rem !important; }
  .pb-lg-4, .py-lg-4 { padding-bottom: 1.5rem !important; }
  .pl-lg-4, .px-lg-4 { padding-left: 1.5rem !important; }
  .p-lg-5 { padding: 3rem !important; }
  .pt-lg-5, .py-lg-5 { padding-top: 3rem !important; }
  .pr-lg-5, .px-lg-5 { padding-right: 3rem !important; }
  .pb-lg-5, .py-lg-5 { padding-bottom: 3rem !important; }
  .pl-lg-5, .px-lg-5 { padding-left: 3rem !important; }
  .m-lg-auto { margin: auto !important; }
  .mt-lg-auto, .my-lg-auto { margin-top: auto !important; }
  .mr-lg-auto, .mx-lg-auto { margin-right: auto !important; }
  .mb-lg-auto, .my-lg-auto { margin-bottom: auto !important; }
  .ml-lg-auto, .mx-lg-auto { margin-left: auto !important; } }
@media (min-width: 1200px) { .m-xl-0 { margin: 0 !important; }
  .mt-xl-0, .my-xl-0 { margin-top: 0 !important; }
  .mr-xl-0, .mx-xl-0 { margin-right: 0 !important; }
  .mb-xl-0, .my-xl-0 { margin-bottom: 0 !important; }
  .ml-xl-0, .mx-xl-0 { margin-left: 0 !important; }
  .m-xl-1 { margin: 0.25rem !important; }
  .mt-xl-1, .my-xl-1 { margin-top: 0.25rem !important; }
  .mr-xl-1, .mx-xl-1 { margin-right: 0.25rem !important; }
  .mb-xl-1, .my-xl-1 { margin-bottom: 0.25rem !important; }
  .ml-xl-1, .mx-xl-1 { margin-left: 0.25rem !important; }
  .m-xl-2 { margin: 0.5rem !important; }
  .mt-xl-2, .my-xl-2 { margin-top: 0.5rem !important; }
  .mr-xl-2, .mx-xl-2 { margin-right: 0.5rem !important; }
  .mb-xl-2, .my-xl-2 { margin-bottom: 0.5rem !important; }
  .ml-xl-2, .mx-xl-2 { margin-left: 0.5rem !important; }
  .m-xl-3 { margin: 1rem !important; }
  .mt-xl-3, .my-xl-3 { margin-top: 1rem !important; }
  .mr-xl-3, .mx-xl-3 { margin-right: 1rem !important; }
  .mb-xl-3, .my-xl-3 { margin-bottom: 1rem !important; }
  .ml-xl-3, .mx-xl-3 { margin-left: 1rem !important; }
  .m-xl-4 { margin: 1.5rem !important; }
  .mt-xl-4, .my-xl-4 { margin-top: 1.5rem !important; }
  .mr-xl-4, .mx-xl-4 { margin-right: 1.5rem !important; }
  .mb-xl-4, .my-xl-4 { margin-bottom: 1.5rem !important; }
  .ml-xl-4, .mx-xl-4 { margin-left: 1.5rem !important; }
  .m-xl-5 { margin: 3rem !important; }
  .mt-xl-5, .my-xl-5 { margin-top: 3rem !important; }
  .mr-xl-5, .mx-xl-5 { margin-right: 3rem !important; }
  .mb-xl-5, .my-xl-5 { margin-bottom: 3rem !important; }
  .ml-xl-5, .mx-xl-5 { margin-left: 3rem !important; }
  .p-xl-0 { padding: 0 !important; }
  .pt-xl-0, .py-xl-0 { padding-top: 0 !important; }
  .pr-xl-0, .px-xl-0 { padding-right: 0 !important; }
  .pb-xl-0, .py-xl-0 { padding-bottom: 0 !important; }
  .pl-xl-0, .px-xl-0 { padding-left: 0 !important; }
  .p-xl-1 { padding: 0.25rem !important; }
  .pt-xl-1, .py-xl-1 { padding-top: 0.25rem !important; }
  .pr-xl-1, .px-xl-1 { padding-right: 0.25rem !important; }
  .pb-xl-1, .py-xl-1 { padding-bottom: 0.25rem !important; }
  .pl-xl-1, .px-xl-1 { padding-left: 0.25rem !important; }
  .p-xl-2 { padding: 0.5rem !important; }
  .pt-xl-2, .py-xl-2 { padding-top: 0.5rem !important; }
  .pr-xl-2, .px-xl-2 { padding-right: 0.5rem !important; }
  .pb-xl-2, .py-xl-2 { padding-bottom: 0.5rem !important; }
  .pl-xl-2, .px-xl-2 { padding-left: 0.5rem !important; }
  .p-xl-3 { padding: 1rem !important; }
  .pt-xl-3, .py-xl-3 { padding-top: 1rem !important; }
  .pr-xl-3, .px-xl-3 { padding-right: 1rem !important; }
  .pb-xl-3, .py-xl-3 { padding-bottom: 1rem !important; }
  .pl-xl-3, .px-xl-3 { padding-left: 1rem !important; }
  .p-xl-4 { padding: 1.5rem !important; }
  .pt-xl-4, .py-xl-4 { padding-top: 1.5rem !important; }
  .pr-xl-4, .px-xl-4 { padding-right: 1.5rem !important; }
  .pb-xl-4, .py-xl-4 { padding-bottom: 1.5rem !important; }
  .pl-xl-4, .px-xl-4 { padding-left: 1.5rem !important; }
  .p-xl-5 { padding: 3rem !important; }
  .pt-xl-5, .py-xl-5 { padding-top: 3rem !important; }
  .pr-xl-5, .px-xl-5 { padding-right: 3rem !important; }
  .pb-xl-5, .py-xl-5 { padding-bottom: 3rem !important; }
  .pl-xl-5, .px-xl-5 { padding-left: 3rem !important; }
  .m-xl-auto { margin: auto !important; }
  .mt-xl-auto, .my-xl-auto { margin-top: auto !important; }
  .mr-xl-auto, .mx-xl-auto { margin-right: auto !important; }
  .mb-xl-auto, .my-xl-auto { margin-bottom: auto !important; }
  .ml-xl-auto, .mx-xl-auto { margin-left: auto !important; } }
.text-justify { text-align: justify !important; }

.text-nowrap { white-space: nowrap !important; }

.text-truncate { overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }

.text-left { text-align: left !important; }

.text-right { text-align: right !important; }

.text-center { text-align: center !important; }

@media (min-width: 576px) { .text-sm-left { text-align: left !important; }
  .text-sm-right { text-align: right !important; }
  .text-sm-center { text-align: center !important; } }
@media (min-width: 768px) { .text-md-left { text-align: left !important; }
  .text-md-right { text-align: right !important; }
  .text-md-center { text-align: center !important; } }
@media (min-width: 992px) { .text-lg-left { text-align: left !important; }
  .text-lg-right { text-align: right !important; }
  .text-lg-center { text-align: center !important; } }
@media (min-width: 1200px) { .text-xl-left { text-align: left !important; }
  .text-xl-right { text-align: right !important; }
  .text-xl-center { text-align: center !important; } }
.text-lowercase { text-transform: lowercase !important; }

.text-uppercase { text-transform: uppercase !important; }

.text-capitalize { text-transform: capitalize !important; }

.font-weight-light { font-weight: 300 !important; }

.font-weight-normal { font-weight: 400 !important; }

.font-weight-bold { font-weight: 700 !important; }

.font-italic { font-style: italic !important; }

.text-white { color: #fff !important; }

.text-primary { color: #ff1b00 !important; }

a.text-primary:hover, a.text-primary:focus { color: #cc1600 !important; }

.text-secondary { color: #fec200 !important; }

a.text-secondary:hover, a.text-secondary:focus { color: #cb9b00 !important; }

.text-success { color: #34e16d !important; }

a.text-success:hover, a.text-success:focus { color: #1dc554 !important; }

.text-info { color: #00bbce !important; }

a.text-info:hover, a.text-info:focus { color: #008d9b !important; }

.text-warning { color: #fec200 !important; }

a.text-warning:hover, a.text-warning:focus { color: #cb9b00 !important; }

.text-danger { color: #ff1b00 !important; }

a.text-danger:hover, a.text-danger:focus { color: #cc1600 !important; }

.text-light { color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus { color: #dae0e5 !important; }

.text-dark { color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus { color: #1d2124 !important; }

.text-muted { color: #868e96 !important; }

.text-hide { font: 0/0 a; color: transparent; text-shadow: none; background-color: transparent; border: 0; }

.visible { visibility: visible !important; }

.invisible { visibility: hidden !important; }

.drive-dev { display: none; }

.link-primary:link, .link-primary:visited { color: #ff1b00; }
.link-primary:hover, .link-primary:active { color: #b31300; }

.btn-link.link-primary { color: #ff1b00; }
.btn-link.link-primary:hover { color: #b31300; }

.link-secondary:link, .link-secondary:visited { color: #fec200; }
.link-secondary:hover, .link-secondary:active { color: #b28800; }

.btn-link.link-secondary { color: #fec200; }
.btn-link.link-secondary:hover { color: #b28800; }

.link-success:link, .link-success:visited { color: #34e16d; }
.link-success:hover, .link-success:active { color: #1aaf4b; }

.btn-link.link-success { color: #34e16d; }
.btn-link.link-success:hover { color: #1aaf4b; }

.link-info:link, .link-info:visited { color: #00bbce; }
.link-info:hover, .link-info:active { color: #007682; }

.btn-link.link-info { color: #00bbce; }
.btn-link.link-info:hover { color: #007682; }

.link-warning:link, .link-warning:visited { color: #fec200; }
.link-warning:hover, .link-warning:active { color: #b28800; }

.btn-link.link-warning { color: #fec200; }
.btn-link.link-warning:hover { color: #b28800; }

.link-danger:link, .link-danger:visited { color: #ff1b00; }
.link-danger:hover, .link-danger:active { color: #b31300; }

.btn-link.link-danger { color: #ff1b00; }
.btn-link.link-danger:hover { color: #b31300; }

.link-light:link, .link-light:visited { color: #f8f9fa; }
.link-light:hover, .link-light:active { color: #cbd3da; }

.btn-link.link-light { color: #f8f9fa; }
.btn-link.link-light:hover { color: #cbd3da; }

.link-dark:link, .link-dark:visited { color: #343a40; }
.link-dark:hover, .link-dark:active { color: #121416; }

.btn-link.link-dark { color: #343a40; }
.btn-link.link-dark:hover { color: #121416; }

/* Elements / Collection / Slider */
.elements-collection-slider .swiper-container { padding-bottom: 30px; }
.elements-collection-slider .swiper-pagination-bullet-active { background-color: #420e00; }

.tpl-image-slideshow img, .tpl-image-slideshow-pagination-below img { max-width: 100%; }
.tpl-image-slideshow .swiper-slide, .tpl-image-slideshow-pagination-below .swiper-slide { max-height: 15em; display: flex; justify-content: center; align-items: center; }
.tpl-image-slideshow .swiper-pagination, .tpl-image-slideshow-pagination-below .swiper-pagination { position: relative; bottom: 0; }

.tpl-image-slideshow-pagination-below + .swiper-pagination { position: static; }
.tpl-image-slideshow-pagination-below + .swiper-pagination .swiper-pagination-bullet { margin: 0 0.25rem; }

/* Elements / Promotion / Introduction */
.elements-promotion-introduction { flex-direction: row-reverse; }
.elements-promotion-introduction figure { margin-bottom: 15px; }
.elements-promotion-introduction figure img { width: 100%; max-width: 200px; }
.elements-promotion-introduction .text { font-size: 1.3em; align-self: center; }
.elements-promotion-introduction dl > dt, .elements-promotion-introduction dl > dd { padding-left: 30px; position: relative; }
.elements-promotion-introduction dl > dt:before { display: inline-block; font: normal normal normal 14px/1 FontAwesome; font-size: inherit; text-rendering: auto; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; content: ""; color: #ff1b00; left: 0; top: 5px; position: absolute; }
@media screen and (min-width: 576px) { .elements-promotion-introduction { display: flex; flex-wrap: wrap; margin-right: -15px; margin-left: -15px; }
  .elements-promotion-introduction figure { margin-bottom: 0; position: relative; width: 100%; min-height: 1px; padding-right: 15px; padding-left: 15px; flex: 0 0 33.3333333333%; max-width: 33.3333333333%; }
  .elements-promotion-introduction .text { position: relative; width: 100%; min-height: 1px; padding-right: 15px; padding-left: 15px; flex: 0 0 66.6666666667%; max-width: 66.6666666667%; } }
@media screen and (min-width: 768px) { .elements-promotion-introduction { display: flex; flex-wrap: wrap; margin-right: -15px; margin-left: -15px; }
  .elements-promotion-introduction figure { position: relative; width: 100%; min-height: 1px; padding-right: 15px; padding-left: 15px; flex: 0 0 25%; max-width: 25%; }
  .elements-promotion-introduction .text { position: relative; width: 100%; min-height: 1px; padding-right: 15px; padding-left: 15px; flex: 0 0 75%; max-width: 75%; } }

/* Elements / Content / Item */
.elements-content-item figure img { max-width: 100%; }

/* Elements / Content / Team */
.elements-content-team .card { display: inline-block; position: relative; margin-bottom: 30px; }
.elements-content-team .card img { width: 100%; height: auto; }
.elements-content-team .team-grid { justify-content: center; }
.elements-content-team .team-slider { text-align: center; }
.elements-content-team .team-slider .swiper-wrapper { justify-content: center; }
.elements-content-team .team-slider .swiper-wrapper .card { margin: 10px; }

/* Elements / Form / Contact */
/* Elements / Form / Login */
.elements-form-login { flex-basis: 400px; margin: 0 15px; }
@media screen and (min-width: 576px) { .elements-form-login { margin: 70px 30px; } }
@media screen and (min-width: 768px) { .elements-form-login { margin: 100px 50px; } }

/* Elements / Form / Sign Up */
.elements-form-signup { flex-basis: 400px; margin: 0 15px; }
@media screen and (min-width: 576px) { .elements-form-signup { margin: 70px 30px; } }
@media screen and (min-width: 768px) { .elements-form-signup { margin: 100px 50px; } }

/* Elements / Header / Image */
.elements-header-image { background: transparent url("../img/header-xs.jpg") no-repeat center center; background-size: cover; height: 30vh; }
@media screen and (min-width: 576px) { .elements-header-image { background-image: url("../img/header-sm.jpg"); } }
@media screen and (min-width: 768px) { .elements-header-image { background-image: url("../img/header-md.jpg"); } }
@media screen and (min-width: 992px) { .elements-header-image { background-image: url("../img/header-lg.jpg"); } }
@media screen and (min-width: 1200px) { .elements-header-image { background-image: url("../img/header-xl.jpg"); } }
.elements-header-image .overlay { background-color: rgba(255, 27, 0, 0.5); display: flex; align-items: center; height: 100%; color: white; }

/* Elements / Header / Map */
.elements-header-map { height: 30vh; position: relative; }
.elements-header-map .overlay { background-color: rgba(255, 255, 255, 0.5); height: 100%; width: 100%; position: absolute; display: flex; align-items: center; z-index: 9; }
.elements-header-map #map { height: 100%; }

/* Elements / Header / Slider */
.elements-header-slider .swiper-container .swiper-slide { background-size: cover; background-position: center; display: flex; flex-direction: column; justify-content: center; }

/* Elements / Header / Video */
.elements-header-video { position: relative; overflow: hidden; }
.elements-header-video img { width: 100%; }
.elements-header-video video { width: 100%; position: absolute; top: 50%; margin-top: calc(-100vw * 9/16 / 2); }
.elements-header-video .video-overlay { position: absolute; top: 0; width: 100%; height: 100%; display: flex; align-items: center; z-index: 9; background-color: rgba(255, 255, 255, 0.7); }

/* Elements / Media / Figure */
.elements-media-figure { text-align: center; }
.elements-media-figure img { margin-bottom: 0; }
.elements-media-figure figcaption { margin-top: 0.5em; }
@media screen and (min-width: 576px) { .elements-media-figure figcaption { position: absolute; left: 0; width: 100%; } }

/* Elements / Navigation / Language */
.elements-navigation-language.tpl-1, .elements-navigation-language.tpl-2 { white-space: nowrap; }
.elements-navigation-language.tpl-1 > a.active, .elements-navigation-language.tpl-2 > a.active { color: inherit; pointer-events: none; opacity: 0.3; }
.elements-navigation-language.tpl-1 > a + a, .elements-navigation-language.tpl-2 > a + a { margin-left: 0.2em; padding-left: 0.5em; border-left: 1px solid #dee2e6; }

.elements-navigation-language.tpl-navbar .nav-link { line-height: 1; }
.elements-navigation-language.tpl-navbar .nav-link:not(:first-child) { border-left: 1px solid #ffffff; }
.elements-navigation-language.tpl-navbar .nav-link.active { font-weight: bold; }

/* Elements / Navigation / Navbar */
/* Elements / Promotion / Banner */
.elements-promotion-banner { background-color: rgba(255, 27, 0, 0.05); padding: 40px; position: relative; }
.elements-promotion-banner .cta-box { margin-top: 30px; padding-top: 30px; border-top: 1px solid rgba(255, 27, 0, 0.1); }
.elements-promotion-banner .cta-box small { font-size: 1em; font-weight: bold; text-transform: uppercase; letter-spacing: 0.1em; }
.elements-promotion-banner .cta-box strong { margin-top: 0; font-size: calc(12vw - 13px); white-space: nowrap; display: block; margin-bottom: 0.2em; }
@media screen and (min-width: 576px) { .elements-promotion-banner .cta-box strong { font-size: 3.25em; } }
@media (min-width: 768px) { .elements-promotion-banner { display: flex; flex-wrap: wrap; margin-right: -15px; margin-left: -15px; align-items: center; }
  .elements-promotion-banner .pitch-box p { padding-right: 30px; }
  .elements-promotion-banner .pitch-box p:last-of-type { margin-bottom: 0; }
  .elements-promotion-banner .cta-box { margin-top: 0; padding-top: 0; border-top: none; }
  .elements-promotion-banner .cta-box small { font-size: 0.8em; }
  .elements-promotion-banner .cta-box strong { font-size: 1.8em; margin-bottom: 0.5em; } }
@media screen and (min-width: 992px) { .elements-promotion-banner .pitch-box { position: relative; width: 100%; min-height: 1px; padding-right: 15px; padding-left: 15px; flex: 0 0 66.6666666667%; max-width: 66.6666666667%; }
  .elements-promotion-banner .cta-box { position: relative; width: 100%; min-height: 1px; padding-right: 15px; padding-left: 15px; flex: 0 0 33.3333333333%; max-width: 33.3333333333%; }
  .elements-promotion-banner .cta-box strong { font-size: 1.98em; } }
@media screen and (min-width: 1200px) { .elements-promotion-banner .cta-box strong { font-size: 2.42em; } }

/* Elements / Promotion / Features */
.elements-promotion-features ul li:before { display: inline-block; font: normal normal normal 14px/1 FontAwesome; font-size: inherit; text-rendering: auto; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; content: ""; margin-right: 0.5em; }
.elements-promotion-features .cta-box strong { font-size: 2em; white-space: nowrap; display: block; }

/* Elements / Promotion / Header */
.elements-promotion-header .overlay { position: relative; display: flex; align-items: center; z-index: 2; color: white; }
.elements-promotion-header .elements-navigation-menu { z-index: 1001; }
.elements-promotion-header.tpl-1 { background: transparent no-repeat center center; background-size: cover; min-height: 30vh; }
.elements-promotion-header.tpl-2 { position: relative; overflow: hidden; }
.elements-promotion-header.tpl-2 video { width: 100%; position: absolute; top: 50%; margin-top: calc(-100vw * 9/16 / 2); z-index: 0; }
.elements-promotion-header.tpl-3 .swiper-slide { background-size: cover; background-position: center; display: flex; flex-direction: column; justify-content: center; height: 30vh; }
.elements-promotion-header.tpl-4 { height: 30vh; position: relative; }
.elements-promotion-header.tpl-4 #map { height: 100%; }
.elements-promotion-header.d-flex.flex-column .overlay { flex-grow: 1; }

.package .flex-xl-nowrap img { height: calc(6.1rem); }
@media (min-width: 768px) and (max-width: 991.98px) { .package > .col-12 + .col-12 { padding-left: calc(25% + 15px); } }

.package__name { margin-bottom: 0.25rem; white-space: nowrap; }

.package__tagline { font-size: 2.3rem; line-height: 1; }
.package__tagline strong { font-weight: 900; }
@media (min-width: 768px) { .package__tagline { max-width: 11rem; } }

.package--translucent img { opacity: 0.4; }
.package--translucent .package__name, .package--translucent .package__tagline { opacity: 0.5; -webkit-text-stroke: 1px rgba(0, 0, 0, 0.8); color: #212529; }

/*!
 * Variables
 */
/* Mixins */
/* Animations ($type = bounce, etc.) */
@keyframes bounce { 0% { transform: scale(0); }
  90% { transform: scale(1.3); }
  100% { transform: scale(1); } }
/* Icons (Font-Awesome, etc.) */
/* Grid */
.section-multi-promo { position: relative; }
.section-multi-promo .swiper-slide { background-repeat: no-repeat; background-position: center; background-size: cover; }
@media (min-width: 768px) { .section-multi-promo .multi-promo-pagination__list { min-height: 301px; }
  .section-multi-promo .swiper-wrapper { position: absolute; top: 0; bottom: 0; left: 0; right: 0; z-index: -1; } }
.section-multi-promo .elements-promotion-testimonial { color: #000000; }
@media (max-width: 991.98px) { .section-multi-promo .container { max-width: none; } }

.promo-bullet { color: #ffffff; display: block; position: relative; cursor: pointer; }
.promo-bullet::before { content: ""; position: absolute; width: 5px; height: 100%; top: 0; left: -15px; }

.promo-bullet-active { color: #ff1b00; }
.promo-bullet-active::before { background-color: #ff1b00; }

@media (min-width: 768px) { .px-md-4 .promo-bullet::before { left: -1.5rem; } }

.multi-promo-pagination { pointer-events: none; }
.multi-promo-pagination .col { pointer-events: all; }

/* Elements / Promotion / Testimonial */
.elements-promotion-testimonial { text-align: center; }
.elements-promotion-testimonial img { width: 150px; border-radius: 50%; margin: 2rem auto; }
.elements-promotion-testimonial blockquote::after { display: block; clear: both; content: ""; }
.elements-promotion-testimonial blockquote:before, .elements-promotion-testimonial blockquote:after { display: none; }
.elements-promotion-testimonial blockquote p { display: inline-block; font-style: italic; max-width: 600px; position: relative; font-family: "Montserrat", "Helvetica Neue", "Helvetica", sans-serif; text-align: center; }
.elements-promotion-testimonial blockquote footer small { display: block; }
.elements-promotion-testimonial blockquote footer[class^="stars"]:after { color: #fec200; }
.elements-promotion-testimonial blockquote footer.stars:after { display: block; letter-spacing: 0.2em; margin: 0.5em 0; }
.elements-promotion-testimonial blockquote footer.star-1:after { display: inline-block; font: normal normal normal 14px/1 FontAwesome; font-size: inherit; text-rendering: auto; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; content: "" "" "" "" ""; }
.elements-promotion-testimonial blockquote footer.star-2:after { display: inline-block; font: normal normal normal 14px/1 FontAwesome; font-size: inherit; text-rendering: auto; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; content: "" "" "" "" ""; }
.elements-promotion-testimonial blockquote footer.star-3:after { display: inline-block; font: normal normal normal 14px/1 FontAwesome; font-size: inherit; text-rendering: auto; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; content: "" "" "" "" ""; }
.elements-promotion-testimonial blockquote footer.star-4:after { display: inline-block; font: normal normal normal 14px/1 FontAwesome; font-size: inherit; text-rendering: auto; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; content: "" "" "" "" ""; }
.elements-promotion-testimonial blockquote footer.star-5:after { display: inline-block; font: normal normal normal 14px/1 FontAwesome; font-size: inherit; text-rendering: auto; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; content: "" "" "" "" ""; }

/* Elements / Widget / Social */
.elements-widget-social > a { display: inline-block; font-size: 1.2em; }
.elements-widget-social > a + a { margin-left: 0.2em; }

address.navbar { background-color: #000; }
address.navbar .container { align-items: stretch; }
@media (min-width: 576px) and (max-width: 991.98px) { address.navbar .container { padding-left: 0; padding-right: 0; } }
address.navbar ul:last-of-type { font-weight: 800; }
@media (min-width: 576px) { address.navbar ul:last-of-type a { padding-top: 0; padding-bottom: 0; } }
@media (min-width: 768px) { address.navbar ul:last-of-type { font-size: 1.5rem; } }

#header-hours { position: relative; text-shadow: 0 1px #000; font-size: 1.5rem; }
#header-hours sup { top: -1em; font-size: 50%; }
@media (min-width: 576px) { #header-hours { margin-top: -0.5rem; margin-bottom: -0.5rem; } }

@media (max-width: 575.98px) { .header-hours-container { margin-left: -15px; margin-right: -15px; padding: 0.5rem 15px; }
  .header-hours-triangle { display: none; } }
@media (min-width: 576px) { .header-hours-container { padding: 0.5rem; width: 100%; }
  .header-hours-container .status strong { display: inline-block; }
  .header-hours-container time { display: inline-block; }
  .header-hours-triangle { position: absolute; top: 100%; left: 0; height: 1rem; width: 100%; z-index: 50; } }
@media (min-width: 992px) { .header-hours-container { padding: 0.5rem 1rem; } }
.bg-gradient-primary { background: #ff1b00 linear-gradient(180deg, #800e00, #ff1b00) repeat-x !important; }

.bg-gradient-secondary { background: #fec200 linear-gradient(180deg, #7f6100, #fec200) repeat-x !important; }

.bg-gradient-success { background: #34e16d linear-gradient(180deg, #1a7137, #34e16d) repeat-x !important; }

.bg-gradient-info { background: #00bbce linear-gradient(180deg, #005e67, #00bbce) repeat-x !important; }

.bg-gradient-warning { background: #fec200 linear-gradient(180deg, #7f6100, #fec200) repeat-x !important; }

.bg-gradient-danger { background: #ff1b00 linear-gradient(180deg, #800e00, #ff1b00) repeat-x !important; }

.bg-gradient-light { background: #f8f9fa linear-gradient(180deg, #7c7d7d, #f8f9fa) repeat-x !important; }

.bg-gradient-dark { background: #343a40 linear-gradient(180deg, #1a1d20, #343a40) repeat-x !important; }

header nav .navbar-nav .nav-link { padding-left: 1.25rem; padding-right: 1.25rem; }
@media (min-width: 576px) and (max-width: 991.98px) { header nav.horizontal .navbar-nav { display: flex; flex-direction: row; flex-wrap: wrap; }
  header nav.horizontal .navbar-nav .nav-item { flex: 1 1 25%; } }

.navbar .dropdown-menu { background-color: rgba(0, 0, 0, 0.5); }
.navbar button.dropdown-toggle { color: #fff; }
.navbar button.dropdown-toggle:focus { box-shadow: none; }
.navbar .dropdown-item { color: #fff; font-weight: bold; }
.navbar .dropdown-item:hover, .navbar .dropdown-item:focus { color: #00bbce; background-color: transparent; }
.navbar .dropdown-item:active { background-color: #00bbce; color: #212529; }
.navbar .btn-group { flex-wrap: wrap; }

.js header .navbar { top: 0; left: 0; transition: background-color 0.5s ease-out, top 0.4s ease-out, opacity 0.4s ease-out; opacity: 0; position: relative; }
.js header .navbar.can-stick { opacity: 1; }
.js header .navbar .navbar-brand img { transition: max-height 0.4s ease-out; }
.js header .navbar.js-is-sticky { position: fixed; background-color: #000000 !important; transition: background-color 0.5s ease-in, top 0.4s ease-in; top: 0; }
.js header .navbar.js-is-sticky .navbar-brand img { max-height: 50px; transition: max-height 0.4s ease-out; }

.elements-promotion-header .elements-navigation-language .nav-link { font-weight: bold; }
.elements-promotion-header .elements-navigation-language .nav-link.active { font-weight: normal; }
@media (max-width: 767.98px) { .elements-promotion-header .elements-navigation-language { flex-direction: row; } }

.single-background-image { background-repeat: no-repeat; background-position: center; background-size: cover; }

.background-image-overlay { position: relative; }
.background-image-overlay::before { content: ""; display: block; width: 100%; height: 100%; position: absolute; left: 0; top: 0; z-index: 0; background-color: rgba(0, 0, 0, 0.6); }

.navbar-dark .elements-navigation-language a.nav-link.active { color: #ffffff; opacity: 0.6; }

body { background-image: url("/assets/theme/img/graphics/wood-background.jpg"); }

body > header:not(#header-home) { position: relative; }
body > header:not(#header-home)::before { content: ""; display: block; width: 100%; height: 100%; position: absolute; left: 0; top: 0; z-index: 1; background-color: rgba(0, 0, 0, 0.4); }

body > footer { background-color: #181818; }

footer figure.logo-container { width: 150px; text-align: center; }
@media screen and (min-width: 576px) { footer figure.logo-container { margin: 0 auto; } }
footer figure.logo-container img { margin-bottom: 10px; }
footer a:link, footer a:visited { color: #ffffff; }
footer a:hover, footer a:active { color: rgba(255, 255, 255, 0.75); }
@media (max-width: 575.98px) { footer .elements-navigation-language { margin-left: -15px; margin-right: -15px; }
  footer .navbar-expand-sm { margin-left: -15px; padding-left: 0; padding-right: 0; }
  footer #company-info { padding-left: calc(30px + 0.5rem); padding-right: calc(30px + 0.5rem); } }
footer .elements-widget-social { line-height: 1; }
footer .elements-widget-social a { padding-left: 0.5rem; padding-right: 0.5rem; }
footer .elements-widget-social a + a { margin-left: 0; padding-right: 1.2rem; }

.swiper-pagination-bullet.swiper-pagination-bullet-active { background-color: #2a0f00; }

#company-info .list-inline-item { border-color: #868e96 !important; }
#company-info .list-inline-item a { padding-left: 10px; }

.white-popup-block { background-color: #fff; }

#bowling-package-promo { margin-bottom: 5rem; padding-bottom: 5rem; border-bottom: 1px solid rgba(255, 27, 0, 0.5); }

#instafeed img { width: 100%; }

#leagues { margin-bottom: 5rem; padding-bottom: 5rem; }
#leagues dd { font-weight: 300; }

#strikersList { overflow: hidden; max-height: 100%; transition: max-height 4s ease-out; }

#strikersList[aria-expanded='false'] { transition: max-height 4s ease-in; max-height: 12rem; }
#strikersList[aria-expanded='false'] .opacity-70 { opacity: 0.7; }
#strikersList[aria-expanded='false'] .opacity-50 { opacity: 0.5; }
#strikersList[aria-expanded='false'] .opacity-20 { opacity: 0.2; }

.page-navigation .h1-icon { max-height: 48px; }
@media (min-width: 768px) { .page-navigation .col-md { max-width: 146.767px; } }
.page-navigation a { color: #000000; font-weight: bold; text-decoration: none; }
.page-navigation a:link, .page-navigation a:visited { background-color: #ffffff; color: #00bbce; }
.page-navigation a:link .icon-hover, .page-navigation a:visited .icon-hover { display: none; }
.page-navigation a:link .icon-normal, .page-navigation a:visited .icon-normal { display: block; }
.page-navigation a:hover, .page-navigation a:active { background-color: #00bbce; color: #fff; }
.page-navigation a:hover .icon-hover, .page-navigation a:active .icon-hover { display: block; }
.page-navigation a:hover .icon-normal, .page-navigation a:active .icon-normal { display: none; }

.h1--translucent .h1-icon { opacity: 0.4; }
.h1--translucent .h1-text { opacity: 0.5; -webkit-text-stroke: 1px rgba(0, 0, 0, 0.8); }

.groups-category { margin-bottom: 5rem; padding-bottom: 5rem; }

[role="tabpanel"] { font-weight: 500; }

#header-contact { height: 50vh; min-height: 380px; }
#header-contact #map { width: 100%; height: 100%; top: 0; left: 0; z-index: 0; transition: opacity 0.3s ease-out; }
#header-contact .initial-state { opacity: 0; }

div.mfp-iframe-scaler { padding-top: 60%; }

#perGameAmount { line-height: 1; font-weight: 900; font-size: 4.5rem; vertical-align: middle; }

.elements-collection-timeline time { display: flex; align-items: center; justify-content: center; background-color: #ffffff; width: 5.5rem; height: 5.5rem; border-radius: 50%; min-width: 5.5rem; }
.elements-collection-timeline .d-flex + .entry { margin-top: -1.25rem; padding-left: 6.5rem; }

.cursive { font-family: "Kalam", cursive; }

.text-underline { text-decoration: underline; }

.display-5 { font-size: 3rem; }

a.white-link:link, a.white-link:visited { color: #ffffff; }
a.white-link:hover, a.white-link:active { color: #d9d9d9; }

a.dark-link:link, a.dark-link:visited { color: #212529; }
a.dark-link:hover, a.dark-link:active { color: black; }

a.text-muted:hover, a.text-muted:active { color: #60686f; }

.light-heading { display: inline-block; padding-bottom: 0.5rem; margin-bottom: 0.5rem; border-bottom: 1px solid #ffffff; font-weight: 300; }

.bg-black-translucent { background-color: rgba(0, 0, 0, 0.5); }

.bg-white-translucent { background-color: rgba(255, 255, 255, 0.5); }

.section-border-top { border-top: 3px solid#ff1b00; }

.section-border-bottom { border-bottom: 3px solid#ff1b00; }

.section-border-y { border-top: 3px solid#ff1b00; border-bottom: 3px solid#ff1b00; }

.btn-margin-inserter { word-spacing: 1rem; }
.btn-margin-inserter .btn { word-spacing: normal; }

.mw-25 { max-width: 25%; }

.mw-50 { max-width: 50%; }

.custom-select-white { border: 1px solid #ffffff; color: #ffffff; font-weight: bold; background: transparent url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 2'%3E%3Cpath fill='%23ffffff' d='M 2,2 0,0 h 4 z'/%3E%3C/svg%3E") no-repeat right 0.75rem center; background-size: 8px 10px; }
.custom-select-white option { color: #212529; }

.bg-black-super-transparent { background-color: rgba(0, 0, 0, 0.1); }

.bg-black { background-color: #000 !important; }

.iphone-switch { appearance: none; border-radius: 10px; width: 3rem; height: 1.5rem; position: relative; background-color: #000000; transition: background-color 0.2s linear 0.1s; }
.iphone-switch::before { content: ""; display: block; border-radius: 50%; background-color: #ffffff; width: calc(1.5rem - 4px); height: calc(1.5rem - 4px); position: absolute; top: 2px; left: 2px; transition: left 0.2s linear; }
.iphone-switch:checked { background-color: #00bbce; }
.iphone-switch:checked::before { left: 1.5rem; }

.col-image-fluid-height { position: relative; overflow: hidden; }
.col-image-fluid-height img { position: absolute; max-width: calc(100% - 30px); }

a.video-container .fa { position: absolute; top: 0; right: 0; bottom: 0; left: 0; display: flex; align-items: center; justify-content: center; }
a.video-container .fa::before { -webkit-text-stroke: 2px #ff1b00; -webkit-text-fill-color: rgba(255, 27, 0, 0.5); transition: font-size 0.3s ease-out; }
a.video-container:hover .fa::before, a.video-container:active .fa::before { font-size: 120%; transition: font-size 0.3s ease-in; }

.pricing-table { border: 5px solid #420e00; }
.pricing-table th, .pricing-table td { border: none; }
.pricing-table thead th { border-bottom: 2px solid #420e00; }
.pricing-table .game-day { position: relative; border-right: 2px solid #420e00; max-width: 1rem; white-space: nowrap; }
.pricing-table .game-day div { font-weight: bold; position: absolute; -ms-writing-mode: tb-lr; writing-mode: tb-lr; text-orientation: sideways; writing-mode: sideways-lr; -webkit-writing-mode: vertical-lr; }
@media (max-width: 991.98px) { .pricing-table .game-day { padding: 0; }
  .pricing-table .game-day div { padding-top: 0.75rem; padding-bottom: 0.75rem; } }
.pricing-table tbody tr:first-child th { border-bottom: 2px solid #420e00; }
.pricing-table .pricing-table-days-section { border-top: 2px solid #420e00; }

.min-width-6-rem { min-width: 6rem; }

.swiper-container .swiper-slide { height: auto; }

.btn-group-breaking-space { letter-spacing: 0.25rem; }
.btn-group-breaking-space .btn { letter-spacing: normal; }

form .btn.disabled { border-color: #868e96; color: #868e96; }

@media (max-width: 767.98px) { .display-5 { font-size: 2.5rem; } }

input[type=date] { background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 2'%3E%3Cpath fill='%23343a40' d='M 2,2 0,0 h 4 z'/%3E%3C/svg%3E") no-repeat right 0.75rem center; background-size: 8px 10px; }
input[type=date]::-webkit-inner-spin-button { appearance: none; }
input[type=date]::-webkit-calendar-picker-indicator { opacity: 0; }


