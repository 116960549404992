/*
 * Elements / Media / Figure
 */

.elements-media-figure

	text-align: center

	img
		margin-bottom: 0

	figcaption

		margin-top: .5em


	@media screen and (min-width: #{map_get($grid-breakpoints, sm)})

		figcaption
			position: absolute
			left: 0
			width: 100%

	@media screen and (min-width: #{map_get($grid-breakpoints, md)})


	@media screen and (min-width: #{map_get($grid-breakpoints, lg)})


	@media screen and (min-width: #{map_get($grid-breakpoints, xl)})
