/*
 * Elements / Promotion / Introduction
 */

.elements-promotion-introduction

	flex-direction: row-reverse

	figure

		margin-bottom: 15px

		img
			width: 100%
			max-width: 200px
			@extend .rounded-circle

	.text
		font-size: 1.3em
		align-self: center

	dl

		& > dt,
		& > dd
			padding-left: 30px
			position: relative

		& > dt:before
			+icon-fa($fa-var-check)
			color: theme-color('primary')
			left: 0
			top: 5px
			position: absolute



	@media screen and (min-width: #{map_get($grid-breakpoints, sm)})

		+fake-row()

		figure
			margin-bottom: 0
			+fake-col(4)

		.text
			+fake-col(8)

	@media screen and (min-width: #{map_get($grid-breakpoints, md)})

		+fake-row()

		figure
			+fake-col(3)

		.text
			+fake-col(9)
