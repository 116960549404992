/*
 * Main (used through the whole project)
 */

//==================================================
//                    BOOTSTRAP
//==================================================

// Tools
@import 'components/functions'
@import 'components/variables'
@import 'components/mixins'
@import 'components/print'
@import 'components/reboot'

// Markup
@import 'components/type'
@import 'components/images'
@import 'components/code'
@import 'components/tables'
@import 'components/forms'
@import 'components/buttons'
@import 'components/transitions'
@import 'components/dropdown'
@import 'components/button-group'
@import 'components/input-group'
@import 'components/custom-forms'
@import 'components/nav'
@import 'components/navbar'
@import 'components/card'
@import 'components/breadcrumb'
@import 'components/pagination'
@import 'components/badge'
@import 'components/jumbotron'
@import 'components/alert'
@import 'components/progress'
@import 'components/media'
@import 'components/list-group'
@import 'components/close'
@import 'components/modal'
@import 'components/tooltip'
@import 'components/popover'
@import 'components/carousel'

// Compilation
@import '../../node_modules/bootstrap/scss/bootstrap'

// Extra
@import 'components/utilities'


//==================================================
//                    ELEMENTS
//==================================================

// Ad

// Collection
@import "elements/collection/slider"

// Content
@import "elements/promotion/introduction"
@import "elements/content/item"
@import "elements/content/team"

// Form
@import "elements/form/contact"
@import "elements/form/login"
@import "elements/form/signup"

// Header
@import "elements/header/image"
@import "elements/header/map"
@import "elements/header/slider"
@import "elements/header/video"

// Media
@import "elements/media/figure"

// Navigation
@import "elements/navigation/language"
@import "elements/navigation/navbar"

// Promotion
@import "elements/promotion/banner"
@import "elements/promotion/features"
@import "elements/promotion/header"
@import "elements/promotion/package"
@import "elements/promotion/section_multi_promo"
@import "elements/promotion/testimonial"

// Widget
@import "elements/widget/social"

//==================================================
//        NAVBAR WITH HOURS AND CONTACT INFO
//==================================================

address.navbar
	background-color: $black
	.container
		align-items: stretch
		+media-breakpoint-between('sm', 'md')
			padding-left: 0
			padding-right: 0

	//phone number
	ul:last-of-type
		font-weight: 800
		+media-breakpoint-up('sm')
			a
				padding-top: 0
				padding-bottom: 0
		+media-breakpoint-up('md')
			font-size: $h4-font-size

#header-hours
	position: relative
	text-shadow: 0 1px #000
	font-size: $h4-font-size
	sup
		top: -1em
		font-size: 50%

	//When there is room for the bar to expand,
		stretch this box
	+media-breakpoint-up('sm')
		margin-top: -$nav-link-padding-y
		margin-bottom: -$nav-link-padding-y

+media-breakpoint-only('xs')
	.header-hours-container
		margin-left: -#{$grid-gutter-width / 2}
		margin-right: -#{$grid-gutter-width / 2}
		padding: $nav-link-padding-y #{$grid-gutter-width / 2}

	//Hide triangle
	.header-hours-triangle
		display: none

+media-breakpoint-up('sm')
	.header-hours-container
		padding: $nav-link-padding-y
		width: 100%
		.status
			strong
				display: inline-block
		time
			display: inline-block

	//Triangle
	.header-hours-triangle
		position: absolute
		top: 100%
		left: 0
		height: 1rem
		width: 100%
		z-index: 50

+media-breakpoint-up('lg')
	.header-hours-container
		padding: $nav-link-padding-y $nav-link-padding-x

//==================================================
//                 GRADIENT CLASSES
//==================================================

//First, customize bootstrap mixin
@mixin bg-gradient-variant($parent, $color)
	#{$parent}
		background: $color linear-gradient(180deg, mix($black, $color, 50%), $color) repeat-x !important

//Then use it
@each $color, $value in $theme-colors
	@include bg-gradient-variant(".bg-gradient-#{$color}", $value)

//==================================================
//                    CUSTOM
//==================================================

//Add more padding to header navbar links
header nav
	.navbar-nav .nav-link
		padding-left: $spacer * 1.25
		padding-right: $spacer * 1.25

	//Menu looked weird at sm, so I applied some extra stuff
		Unfortunately, this breaks dropdowns, so now it's in a class
	&.horizontal
		.navbar-nav
			+media-breakpoint-between('sm','md')
				display: flex
				flex-direction: row
				flex-wrap: wrap

				.nav-item
					//25% width; fill space
					flex: 1 1 25%

.navbar

	.dropdown-menu
		background-color: rgba(0,0,0,0.5)

	button.dropdown-toggle
		color: #fff

		&:focus
			box-shadow: none
			//color: theme-color('info')

	.dropdown-item
		color: #fff
		font-weight: bold

		&:hover, &:focus
			color: theme-color('info')
			background-color: transparent

		&:active
			background-color: theme-color('info')
			color: $body-color

	.btn-group
		flex-wrap: wrap

// Couldn't make a real sticky navbar, so now we have this. Use in conjunction with main.js
.js header .navbar
	top: 0
	left: 0
	transition: background-color 0.5s ease-out, top 0.4s ease-out, opacity 0.4s ease-out
	opacity: 0
	position: relative

	//Navbar is loaded. Show it
	&.can-stick
		opacity: 1

	//This is going to shrink
	.navbar-brand img
		transition: max-height 0.4s ease-out

	&.js-is-sticky
		position: fixed
		background-color: #000000 !important
		transition: background-color 0.5s ease-in, top 0.4s ease-in
		top: 0

		.navbar-brand img
			max-height: 50px
			transition: max-height 0.4s ease-out


//Reverse active and not-active styles in header language selector
.elements-promotion-header .elements-navigation-language
	.nav-link
		font-weight: bold
		&.active
			font-weight: normal

	+media-breakpoint-down('sm')
		flex-direction: row


.single-background-image
	background-repeat: no-repeat
	background-position: center
	background-size: cover

//Add this to an element with a big background image to make text in front of it more legible
.background-image-overlay
	position: relative

	&::before
		content: ''
		display: block
		width: 100%
		height: 100%
		position: absolute
		left: 0
		top: 0
		z-index: 0
		background-color: rgba(0,0,0,0.6)

.navbar-dark .elements-navigation-language a.nav-link.active
	color: #ffffff
	opacity: 0.6

body
	background-image: url("/assets/theme/img/graphics/wood-background.jpg")

body > header:not(#header-home)
	position: relative
	&::before
		content: ''
		display: block
		width: 100%
		height: 100%
		position: absolute
		left: 0
		top: 0
		z-index: 1
		background-color: rgba(0,0,0,0.4)

body > footer
	background-color: #181818

footer

	figure.logo-container
		width: 150px
		text-align: center

		@media screen and (min-width: #{map_get($grid-breakpoints, sm)})
			margin: 0 auto

		img
			margin-bottom: 10px

	a:link, a:visited
		color: #ffffff

	a:hover, a:active
		color: rgba(255, 255, 255, 0.75)

	+media-breakpoint-only('xs')
		.elements-navigation-language
			margin-left: -#{$grid-gutter-width / 2}
			margin-right: -#{$grid-gutter-width / 2}
		.navbar-expand-sm
			margin-left: -#{$grid-gutter-width / 2}
			padding-left: 0
			padding-right: 0
		#company-info
			padding-left: calc(#{$grid-gutter-width} + 0.5rem)
			padding-right: calc(#{$grid-gutter-width} + 0.5rem)

	.elements-widget-social
		line-height: 1

		a
			padding-left: $nav-link-padding-x / 2
			padding-right: $nav-link-padding-x / 2

		a + a
			margin-left: 0
			padding-right: $nav-link-padding-x + 0.2rem

.swiper-pagination-bullet.swiper-pagination-bullet-active
	background-color: #2a0f00

#company-info
	.list-inline-item

		//override bs color
		border-color: $text-muted !important

		a
			padding-left: 10px

.white-popup-block
	background-color: #fff

#bowling-package-promo
	margin-bottom: 5rem
	padding-bottom: 5rem
	border-bottom: 1px solid transparentize(theme-color('primary'), 0.5)

#instafeed img
	width: 100%

//#header-bowling
	height: 80vh

//#header-groups
	min-height: 80vh
	.overlay
		padding-top: 80px
		min-height: 100%
		padding-bottom: map_get($spacers, 5)
		.container
			height: 100%
			//.row
				height: calc(100% - #{map_get($spacers, 5) * 2})

	+media-breakpoint-up('lg')
		min-height: 60vh

		.overlay
			min-height: 60vh
			align-items: flex-end

	+media-breakpoint-up('xl')
		min-height: 90vh

		.overlay
			min-height: 90vh

#leagues
	margin-bottom: 5rem
	padding-bottom: 5rem
	dd
		font-weight: 300

#strikersList
	overflow: hidden
	max-height: 100%
	transition: max-height 4s ease-out

#strikersList[aria-expanded='false']
	transition: max-height 4s ease-in
	max-height: 4 * (($line-height-base * 2) * $font-size-base)

	.opacity-70
		opacity: 0.7
	.opacity-50
		opacity: 0.5
	.opacity-20
		opacity: 0.2



.page-navigation

	//fake uniform heights
	.h1-icon
		max-height: 48px

	.col-md
		//fake uniform widths
		+media-breakpoint-up('md')
			max-width: 146.767px

	a
		color: #000000
		font-weight: bold
		text-decoration: none

	a:link, a:visited
		background-color: #ffffff
		color: theme-color('info')
		//transition: background-color 1s ease-out
		.icon-hover
			display: none
			//max-height: 0
		.icon-normal
			display: block
			//max-height: 20rem

	a:hover, a:active
		background-color: theme-color('info')
		color: #fff
		//transition: background-color 1s ease-in
		.icon-hover
			display: block
		.icon-normal
			display: none


//Make headings blend into background
.h1--translucent
	//Icons are black black, so they need less opacity than...
	.h1-icon
		opacity: 0.4
	//...the font, which is a lighter shade of black in Bootstrap by default
	.h1-text
		opacity: 0.5
		-webkit-text-stroke: 1px rgba(0,0,0,0.8)

.groups-category
	margin-bottom: 5rem
	padding-bottom: 5rem

[role="tabpanel"]
	font-weight: 500

#header-contact
	height: 50vh
	min-height: 380px

	#map
		width: 100%
		height: 100%
		top: 0
		left: 0
		z-index: 0
		transition: opacity 0.3s ease-out

	.initial-state
		opacity: 0

div.mfp-iframe-scaler
	padding-top: 60%

#perGameAmount
	line-height: 1
	font-weight: 900
	font-size: $display3-size
	vertical-align: middle

.elements-collection-timeline

	time
		display: flex
		align-items: center
		justify-content: center
		background-color: #ffffff
		width: $font-size-base * 5.5
		height: $font-size-base * 5.5
		border-radius: 50%

		//Fix circle on mobile
		min-width: $font-size-base * 5.5

	.d-flex + .entry
		margin-top: -1.25rem
		padding-left: ($font-size-base * 5.5) + 1rem

//==================================================
//                   UTILITIES
//==================================================

.cursive
	font-family: $kalam

.text-underline
	text-decoration: underline

.display-5
	font-size: 3rem

a.white-link
	&:link, &:visited
		color: #ffffff
	&:hover, &:active
		color: darken(#ffffff, 15%)

a.dark-link
	&:link, &:visited
		color: $body-color
	&:hover, &:active
		color: darken($body-color, 15%)

a.text-muted
	&:hover, &:active
		color: darken($text-muted, 15%)

.light-heading
	//make bottom border same width as text
	display: inline-block

	//border bottom
	padding-bottom: $headings-margin-bottom
	margin-bottom: $headings-margin-bottom
	border-bottom: 1px solid #ffffff

	font-weight: 300

.bg-black-translucent
	background-color: rgba(0,0,0,0.5)

.bg-white-translucent
	background-color: rgba(255,255,255,0.5)

.section-border-top
	border-top: 3px solid +theme-color('primary')

.section-border-bottom
	border-bottom: 3px solid +theme-color('primary')

.section-border-y
	border-top: 3px solid +theme-color('primary')
	border-bottom: 3px solid +theme-color('primary')

//When you have a bunch of buttons in an element, it's hard to know when there will be a line break, and when to put in
	margins between them.
	Add this to the element containing the buttons to add magic smart margins
.btn-margin-inserter
	word-spacing: 1rem
	.btn
		word-spacing: normal

.mw-25
	max-width: 25%
.mw-50
	max-width: 50%

//.custom-select-primary
	border: 1px solid theme-color('primary')
	color: theme-color('primary')
	font-weight: bold
	background: transparent $caret-down-primary no-repeat right 0.75rem center
	background-size: 8px 10px

.custom-select-white
	border: 1px solid #ffffff
	color: #ffffff
	font-weight: bold
	background: transparent $caret-down-white no-repeat right 0.75rem center
	background-size: 8px 10px

	option
		color: $body-color

.bg-black-super-transparent
	background-color: rgba(0,0,0,0.1)

.bg-black
	background-color: #000 !important

.iphone-switch
	appearance: none
	border-radius: 10px
	width: $line-height-base * $font-size-base * 2
	height: $line-height-base * $font-size-base
	position: relative
	background-color: #000000
	transition: background-color 0.2s linear 0.1s
	//box-shadow: inset 1px 1px 1px rgba(0,0,0,0.5)

	&::before
		content: ''
		display: block
		border-radius: 50%
		background-color: #ffffff
		width: calc(#{$line-height-base * $font-size-base} - 4px)
		height: calc(#{$line-height-base * $font-size-base} - 4px)
		position: absolute
		top: 2px
		left: 2px
		transition: left 0.2s linear
		//box-shadow: 1px 1px 1px rgba(0,0,0,0.5)

	&:checked
		background-color: theme-color('info')
		&::before
			left: $line-height-base * $font-size-base

.col-image-fluid-height
	position: relative
	overflow: hidden

	img
		position: absolute
		max-width: calc(100% - #{$grid-gutter-width})


//link with preview image, which opens a video in a popup
a.video-container
	.fa
		position: absolute
		top: 0
		right: 0
		bottom: 0
		left: 0
		display: flex
		align-items: center
		justify-content: center

	.fa::before
		-webkit-text-stroke: 2px theme-color('primary')
		-webkit-text-fill-color: transparentize(theme-color('primary'), 0.5)
		transition: font-size 0.3s ease-out

	&:hover, &:active
		.fa::before
			font-size: 120%
			transition: font-size 0.3s ease-in

.pricing-table
	border: 5px solid $maroon

	th, td
		border: none

	thead th
			border-bottom: 2px solid $maroon

	.game-day
		position: relative
		border-right: 2px solid $maroon
		max-width: $font-size-base
		white-space: nowrap

		div
			//transform-origin: bottom right
			//transform: rotate(180deg)
			font-weight: bold

			//Regular flow screws with styles below
			position: absolute

			//Turns text sideways in IE
			-ms-writing-mode: tb-lr
			//Turns text sideways in Edge
			writing-mode: tb-lr

			//Turns text sideways in other browsers
			text-orientation: sideways
			writing-mode: sideways-lr

			//CHROME
			-webkit-writing-mode: vertical-lr

		+media-breakpoint-down('lg')
			//vertical-align: middle
			//div
				transform: rotate(-90deg) translateY(#{$table-cell-padding})

		//Fix for Chrome on xs screens
		+media-breakpoint-down('md')
			padding: 0

			div
				padding-top: $table-cell-padding
				padding-bottom: $table-cell-padding

	tbody

		tr:first-child th
			border-bottom: 2px solid $maroon

	.pricing-table-days-section
		border-top: 2px solid $maroon

		//& + tr td
			border-top: 2px solid $table-border-color

//IE 11 fix
.min-width-6-rem
	min-width: 6rem

//Make all swiper slides the same height
.swiper-container .swiper-slide
	height: auto

//Give buttons some space
.btn-group-breaking-space

	//This is the space between the buttons. It behaves like a text space
	letter-spacing: 0.25rem

	.btn
		//This resets the spacing inside the button itself
		letter-spacing: normal

//Make a disabled button more obvious
form .btn.disabled
	border-color: $text-muted
	color: $text-muted

.display-5
	+media-breakpoint-down('sm')
		font-size: 2.5rem

//Style datepicker

input[type=date]
	background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 2'%3E%3Cpath fill='%23343a40' d='M 2,2 0,0 h 4 z'/%3E%3C/svg%3E") no-repeat right 0.75rem center
	background-size: 8px 10px

	&::-webkit-inner-spin-button
		appearance: none

	&::-webkit-calendar-picker-indicator
		opacity: 0

