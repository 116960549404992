/*
 * Elements / Widget / Social
 */

.elements-widget-social

	> a

		display: inline-block
		font-size: 1.2em

		+ a

			margin-left: .2em
