/*
 * Icons (Font-Awesome, etc.)
 */


//==================================================
//                    MIXINS
//==================================================

// Font Awesome
@import "../../font-awesome/mixins"
@import "../../font-awesome/variables"
=icon-fa($fa-var: $fa-var-beer)
	+fa-icon
	content: $fa-var


//==================================================
//                    OPTIONS
//==================================================
