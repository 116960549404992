/*
 * Animations ($type = bounce, etc.)
 */


//==================================================
//                    MIXINS
//==================================================

=animate($type: bounce, $time: 150ms)
	animation: $type $time ease-in


//==================================================
//                    OPTIONS
//==================================================

@keyframes bounce
	0%
		transform: scale(0)
	90%
		transform: scale(1.3)
	100%
		transform: scale(1)
