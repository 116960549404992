/*
 * Elements / Collection / Slider
 */

.elements-collection-slider

	.swiper-container

		padding-bottom: 30px

	.swiper-pagination-bullet-active
		background-color: #420e00

.tpl-image-slideshow, .tpl-image-slideshow-pagination-below
	img
		max-width: 100%
	.swiper-slide
		max-height: 15em
		display: flex
		justify-content: center
		align-items: center

	.swiper-pagination
		position: relative
		bottom: 0

//When pagination element is outside and after swiper-wrapper
.tpl-image-slideshow-pagination-below + .swiper-pagination
	//Undo default
	position: static

	.swiper-pagination-bullet
		margin: 0 0.25rem
