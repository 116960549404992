/*
 * Reboot
 */

*

	-webkit-font-smoothing: antialiased

	&::selection
		background-color: lighten(black, 85%)

	&:focus
		outline: none !important


html,
body
	height: 100%
	width: 100%


body > section

	margin: 40px 0

	@media screen and (min-width: #{map_get($grid-breakpoints, sm)})
		margin: 50px 0

	@media screen and (min-width: #{map_get($grid-breakpoints, md)})
		margin: 70px 0

	@media screen and (min-width: #{map_get($grid-breakpoints, lg)})
		margin: 90px 0

	@media screen and (min-width: #{map_get($grid-breakpoints, xl)})
		margin: 110px 0


// Quotes
blockquote:before
	content: open-quote

blockquote:after
	content: close-quote

blockquote, q
	//'“' '”' '‘' '’'
	quotes: '\201c' '\201D' '\2018' '\2019'

html:lang(fr)
	blockquote, q
		//'« ' ' »' '‘' '’'
		quotes: '\00ab\A0' '\A0\00bb' '\2018' '\2019'

html:lang(es),
html:lang(pt),
html:lang(no)
	blockquote, q
		//'«' '»' '‘' '’'
		quotes: '\00ab' '\00bb' '\2018' '\2019'

html:lang(de),
html:lang(da),
html:lang(bg),
html:lang(hr)
	blockquote, q
		//'»' '«' '‘' '’'
		quotes: '\00bb' '\00ab' '\2018' '\2019'

html:lang(ru),
html:lang(uk),
html:lang(be)
	blockquote, q
		//'«' '»' '„' '”'
		quotes: '\00ab' '\00bb' '\201E' '\201D'

html:lang(pl),
html:lang(hu)
	blockquote, q
		//'„' '”' '‘' '’'
		quotes: '\201E' '\201D' '\2018' '\2019'

html:lang(fi),
html:lang(sv),
html:lang(be)
	blockquote, q
		//'”' '”' '’' '’'
		quotes: '\201D' '\201D' '\2019' '\2019'

html:lang(it)
	blockquote, q
		//'»' '«' '“' '”'
		quotes: '\00bb' '\00ab' '\201C' '\201D'
